.infobox {
    position: relative;
    padding: 10px 10px 10px 40px;
    overflow: hidden;
    border: 1px solid $blue;
    border-radius: 5px;

    > *:last-child {
        margin-bottom: 0;
    }

    &-primary {
        border-color: $blue-lighter;
        background-color: $blue-lighter;
        color: $white;

        > * {
            position: relative;
            z-index: 1;
        }

        &, .area & {
            a:not(.btn) {
                color: $white;

                &:hover {
                    color: $blue;
                }

                &:after {
                    background-color: $white;
                }
            }

            ul {
                li {
                    padding-left: 15px;
                    &:before {
                        top: 3px;
                        border-left-color: $white;
                    }
                }
            }
        }
    }

    .icon {
        display: block;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 25px;
        filter: $filter-orange;
    }
}