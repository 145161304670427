.area--blockquote {
    position: relative;

    .icon {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        width: 19px;
        height: 15px;
        transform: translateY(-50%);
        filter: $filter-orange;
        z-index: 2;
    }

    blockquote {
        padding: 15px 0 15px 50px;
        font-weight: $font-weight-light;
        font-style: italic;
        font-size: 25px;
        line-height: 1.4;

        @include media-breakpoint-down(md) {
            font-size: 20px;
        }

        &:before, &:after {
            display: block;
            position: absolute;
            content: '';
        }

        &:before {
            left: 8px;
            top: 0;
            bottom: 0;
            border-left: 3px solid $orange;
        }

        &:after {
            top: 50%;
            left: 0;
            width: 19px;
            height: 35px;
            transform: translateY(-50%);
            background: $white;
        }

        p {
            margin-bottom: 1.2em;
        }

        cite {
            display: block;
            font-size: 12px;
            line-height: 1.16667;

            &:before {
                display: inline;
                content: '~ ';
            }
        }
    }

    &.highlight,
    .highlight & {
        blockquote {
            &:after {
                background-color: $blue;
            }
        }
    }
}