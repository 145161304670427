.area--interactive-map {
    position: relative;
    @include fullWidthViewport;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1920px;

    .interactive-map {
        @include media-breakpoint-up(outer) {
            padding: 80px 0;
        }

        @include media-breakpoint-between(xxl, outer) {
            padding: calc(12.3vw - 171.7px) 0;
        }

        @include media-breakpoint-between(xl, xxl) {
            padding: calc(18vw - 160px) 0;
        }

        @include media-breakpoint-between(md, xl) {
            display: flex;
            flex-wrap: nowrap;
            padding: 0 20px 0 60px;
        }

        &--nav {
            position: relative;
            list-style-type: none;
            margin: 0;
            padding: 15px 15px 15px 0;
            pointer-events: none;
            z-index: 5;

            @include media-breakpoint-up(outer) {
                column-gap: 800px;
            }

            @include media-breakpoint-up(xl) {
                column-count: 2;
                column-gap: calc(40vw - 10px);
                padding: 25px;
            }

            @include media-breakpoint-down(md) {
                padding: 15px;
            }
        }

            &--nav-item {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                align-content: center;
                margin: 0;
                padding-left: 20px;
                height: 120px;
                text-align: left;
                pointer-events: auto;
                cursor: pointer;
                transition: transform .3s, color .3s, background-color .3s;

                @include media-breakpoint-up(xxl) {
                    height: 180px;
                }

                @include media-breakpoint-down(md) {
                    position: relative;
                    margin-bottom: 15px;
                    padding: 20px 0 20px 20px;
                    height: auto;
                    border: 1px solid $blue-light;
                }

                &:last-child {
                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                    }
                }

                &.is-active {
                    @include media-breakpoint-up(md) {
                        background: $blue;
                        color: $white;
                        transform: translateX(-25px);
                    }

                    .rhombus {
                        @include media-breakpoint-up(md) {
                            color: $white;
                            filter: $filter-white;
                        }
                    }
                }

                &:before {
                    display: none;
                }

                &:nth-child(-n+3) {
                    @include media-breakpoint-up(xl) {
                        padding: 0 30px 0 0;
                        text-align: right;
                    }

                    &.is-active {
                        @include media-breakpoint-up(xl) {
                            transform: translateX(25px);
                        }
                    }

                    .rhombus {
                        @include media-breakpoint-up(xl) {
                            order: 2;
                        }
                    }

                    .description {
                        @include media-breakpoint-up(xl) {
                            order: 1;
                        }
                    }
                }

                .rhombus {
                    width: 130px;
                    flex: 0 0 auto;
                    font-size: 12px;
                    transition: color .3s, filter .3s;

                    @include media-breakpoint-up(xxl) {
                        width: 150px;
                        font-size: 13px;
                    }

                    @include media-breakpoint-between(xl, xxl) {
                        display: none;
                    }

                    @include media-breakpoint-between(md, lg) {
                        display: none;
                    }

                    @include media-breakpoint-down(md) {
                        width: 100px;
                        font-size: 10px;
                    }
                }

                .description {
                    padding: 0 20px;

                    strong {
                        display: block;
                        margin-bottom: 5px;
                        font-weight: $font-weight-bold;
                        line-height: 1.15;

                        @include media-breakpoint-up(xxl) {
                            margin-bottom: 10px;
                            font-size: 20px;
                        }
                    }

                    p {
                        margin: 0;
                        font-size: 15px;
                        line-height: 1.25;
                        font-weight: $font-weight-light;

                        @include media-breakpoint-up(xxl) {
                            line-height: 1.64;
                            font-size: 17px;
                        }

                        @include media-breakpoint-down(md) {
                            line-height: 1.66667;
                        }
                    }
                }

                a:not(.btn) {
                    display: none;

                    @include media-breakpoint-down(md) {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
    }

        .svg-map {
            display: flex;
            align-content: center;
            align-items: center;
            position: relative;
            flex: 0 0 calc(50% + 30px);
            margin: 0;
            padding: 80px;
            @include fluid-property('padding-left', map-get($grid-breakpoints, xxl), map-get($grid-breakpoints, outer), 40px, 80px);
            @include fluid-property('padding-right', map-get($grid-breakpoints, xxl), map-get($grid-breakpoints, outer), 40px, 80px);
            width: calc(50% + 30px);
            background: $blue-superlight;
            border-radius: 5px;
            text-align: center;
            z-index: 1;

            @include media-breakpoint-up(outer) {
                width: 810px;
            }

            @include media-breakpoint-up(xl) {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: 0 auto;
                width: 40vw;
                transform: translate(-50%, -50%);
            }

            @include media-breakpoint-down(md) {
                padding: 40px 15px;
                width: 100%;
            }

            &--inner {
                position: relative;
                width: 100%;
            }

            svg {
                display: inline-block;
                width: 100%;
                height: auto;
                overflow: visible;

                @include media-breakpoint-down(md) {

                }

                *[class*="lang-"] {
                    display: none;

                    html[lang="de"] & {
                        &.lang-de {
                            display: block;
                        }
                    }

                    html[lang="en"] & {
                        &.lang-en {
                            display: block;
                        }
                    }

                    html[lang="it"] & {
                        &.lang-it {
                            display: block;
                        }
                    }
                }

                .svg-map--world {
                    display: none;
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity .3s, visibility .3s;
                    pointer-events: none;

                    @include media-breakpoint-up(md) {
                        display: block;
                    }

                    &.is-active {
                        @include media-breakpoint-up(md) {
                            opacity: 1;
                            visibility: visible;
                            pointer-events: auto;
                        }
                    }
                }
            }

            &--pano-links {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                    pointer-events: none;
                }

                > [class*="pano-links--"] {
                    opacity: 0;
                    visibility: hidden;
                    transition: opacity .3s, visibility .3s;

                    &.is-visible {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                a {
                    left: 0;
                    top: 0;
                    width: to-em(40px, 30px);
                    height: to-em(40px, 30px);
                    font-size: 30px;
                    background: $orange;
                    border-radius: 100%;
                    transform: translate(-50%, -50%);
                    transform-origin: 0 0;
                    pointer-events: auto;
                    z-index: 2;

                    @include media-breakpoint-between(ml,lg) {
                        font-size: 24px;
                    }

                    &, &:not(.btn)[target="_blank"] {
                        display: block;
                        position: absolute;
                        opacity: 1;
                        transition: background-color .3s, transform .3s, opacity .3s, visibility .3s;
                    }

                    &:hover {
                        background: $blue;
                        transform: scale(1.1) translate(-50%, -50%);
                    }

                    &:after {
                        display: none;
                    }

                    .icon {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        filter: $filter-white;
                    }

                    &.pano-link {
                        &--relax {
                            &--1 {
                                left: 39.4%;
                                top: 25.1%;
                            }

                            &--2 {
                                left: 25.1%;
                                top: 58%;
                            }
                        }

                        &--family {
                            &--1 {
                                left: 73.5%;
                                top: 54.8%;
                            }

                            &--2 {
                                left: 62.5%;
                                top: 67%;
                            }

                            &--3 {
                                left: 85%;
                                top: 60%;
                            }

                            &--4 {
                                left: 67.4%;
                                top: 77.5%;
                            }
                        }

                        &--sauna {
                            &--1 {
                                left: 49.1%;
                                top: 14.5%;
                            }

                            &--2 {
                                left: 39.4%;
                                top: 29%;
                            }

                            &--3 {
                                left: 59.2%;
                                top: 32%;
                            }

                            &--4 {
                                left: 47.4%;
                                top: 51.5%;
                            }

                            &--5 {
                                left: 46.8%;
                                top: 71%;
                            }
                        }

                        &--ladies {
                            &--1 {
                                left: 36.5%;
                                top: 78%;
                            }
                        }

                        &--sports {
                            &--1 {
                                left: 10%;
                                top: 56%;
                            }

                            &--2 {
                                left: 12.8%;
                                top: 66%;
                            }
                        }

                        &--beauty {
                            &--1 {
                                left: 35%;
                                top: 86%;
                            }
                        }
                    }
                }
            }
        }
}

.interactive-map--overlay {
    display: none;

    @include media-breakpoint-up(md) {

        &,
        .main-content & {
            display: block;
        }

        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, .8);
        overflow: hidden;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility .3s;
        pointer-events: none;
        z-index: 999;
    }

    &.is-active {
        @include media-breakpoint-up(md) {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }

    &--inner {
        position: absolute;
        left: 50%;
        top: 50%;
        height: calc(100% - 120px);
        width: 90%;
        transform: translate(-50%, -50%);
        background: $black;

        @include media-breakpoint-between(md, lg) {
            height: calc(100% - 60px);
        }
    }

        &--close {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            background: $orange;
            border: none;
            border-radius: 100%;
            transform: translate(50%, -50%);
            cursor: pointer;
            z-index: 5;
            transition: background-color .3s, transform .3s;

            &:hover {
                background: $blue;
                transform: translate(50%, -50%) scale(1.1);
            }

            &:before, &:after {
                position: absolute;
                display: block;
                content: '';
                left: 50%;
                top: 50%;
                width: 25px;
                height: 3px;
                background: $white;
            }

            &:before {
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(45deg);
            }
        }

        &--container {
            position: relative;
            width: 100%;
            height: 100%;

            &:before {
                display: block;
                content: '';
                z-index: 1;
                position: absolute;
                left: 50%;
                top: 50%;
                width: 100px;
                height: 100px;
                transform: translate(-50%, -50%);
                transform-origin: 0 0;
                border: 10px solid rgba($white, .5);
                border-top-color: transparent;
                border-radius: 100%;
                animation: spin 1s linear infinite;
            }

            > * {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
            }

            iframe {
                border: none;
            }
        }
}

@keyframes spin {
    from { transform: rotate(0deg) translate(-50%, -50%) }
    to { transform: rotate(360deg) translate(-50%, -50%) }
}