.partner-box {
    position: relative;
    padding-left: 50px;
    height: 100%;

    @include media-breakpoint-down(lg) {
        padding: 10px 0 0;
    }

    &:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 60px;
        width: 100px;
        height: 100px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: contain;

        @include media-breakpoint-down(lg) {
            top: 0;
            left: 30px;
            width: 60px;
            height: 60px;
        }
    }

    &.service {
        &:before {
            background-image: svg-load('../layout/svg/logo-service-partner.svg', fill='');
        }
    }

    &.premium {
        &:before {
            background-image: svg-load('../layout/svg/logo-premium-partner.svg', fill='');
        }
    }

    &--inner {
        padding: 60px 60px 60px 90px;
        height: 100%;
        background: $blue-superlight;

        @include media-breakpoint-down(lg) {
            padding: 70px 30px 30px;
        }
    }

        &--content {
            position: relative;
            z-index: 1;
        }

            h5, .h5 {
                margin-bottom: 10px;
            }

            p:not(.h5) {
                font-weight: $font-weight-light;

                @include media-breakpoint-down(md) {
                    font-size: 15px;
                }
            }

            .btn {
                position: relative;
                display: block;
                margin: 25px 0 0 0;
                width: 100%;
                background: $blue-superlight;
                text-align: center;

                &:hover {
                    background: $blue;
                }
            }
}