.form-container,
form.formbuilder {
    fieldset {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    label {
        display: block;
        margin-bottom: 5px;
        width: 100%;
        line-height: 1.2;

        &.required {
            &:after {
                content:"*";
            }
        }
    }

    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    select,
    textarea {
        padding: $formfield-padding;
        width: 100%;
        color: $formfield-color;
        background: $formfield-bg;
        border: $formfield-border-width solid $formfield-border-color;
        border-radius: $formfield-border-radius;
        line-height: 1.5;

        &:focus {
            color: $formfield-focus-color;
            background: $formfield-focus-bg;
            outline: 0;
            box-shadow: 0 0 0 0.25rem $formfield-focus-border-color;
        }

        &[readonly]:not(.flatpickr-input), &[disabled] {
            color: rgba($formfield-color, .5);
            background-color: rgba($black, 0.1);
            border-color: rgba($formfield-border-color, .3);
        }
    }

    input[type=email],
    input[type=text],
    input[type=date],
    input[type=password],
    select {
        height: 44px;
    }

    textarea {
        min-height: 120px;
    }

    .checkbox,
    .radio {
        position: relative;
        padding-left: 20px;

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            left: 0;
            top: 4px;
            cursor: pointer;
        }
    }

    .cols {
        grid-column-gap: $form-gutter-width;
        grid-row-gap: $form-gutter-width;
        margin-bottom: $form-gutter-width;
    }
}

.form-group {
    margin-bottom: $form-gutter-width;

    &.datepicker {
        position: relative;

        &:after {
            display: block;
            content: '';
            position: absolute;
            right: 8px;
            bottom: 9px;
            width: 25px;
            height: 25px;
            @include updateIcon('calendar', '');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            filter: $filter-blue;
        }
    }

    .cols & {
        margin-bottom: 0;
    }
}

