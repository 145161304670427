h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
    margin-top: 0;
    margin-bottom: 0;
    word-break: break-all;

    @include hyphens-auto(true);

    a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            color: inherit;
            text-decoration: none;
        }
    }

    small {
        display: block;
        margin: 0;
        font-size: .8em;
        line-height: 1.4;
        font-weight: $font-weight-light;
        hyphens: none;
        word-break: normal;

        @include media-breakpoint-down(md) {
            font-size: $font-size-base;
        }

        .icon {
            filter: $filter-blue;
            transform: translateY(-.1em);
        }
    }
}

h1, h2, h3,
.h1, .h2, .h3 {

    &.super {
        line-height: 1.1875;
    }

    small {
        @include fluid-property('line-height', $viewport-width-min, $viewport-width-max, 1.625, 1.4);
        @include fluid-type($viewport-width-min, $viewport-width-max, 16px, 25px);
    }
}


h1, .h1 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h1-min-font-size, $h1-font-size);
    line-height: 1.083333;
    font-weight: $font-weight-light;

    &.super {
        @include fluid-type($viewport-width-min, $viewport-width-max, $h1-min-font-size, 80px);
    }
}


h2, .h2 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h2-min-font-size, $h2-font-size);
    line-height: 1.1;

    &.super {
        @include fluid-type($viewport-width-min, $viewport-width-max, $h2-min-font-size, 80px);
    }
}


h3, .h3 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h3-min-font-size, $h3-font-size);
    line-height: 1.16666;
    font-weight: $font-weight-bold;

    &.super {
        @include fluid-type($viewport-width-min, $viewport-width-max, 30px, 80px);
    }

    @include media-breakpoint-down(md) {
        font-weight: $font-weight-bolder;
    }
}


h4, .h4 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h4-min-font-size, $h4-font-size);
    line-height: 1.17308;
    font-weight: $font-weight-bolder;
}


h5, .h5 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h5-min-font-size, $h5-font-size);
    line-height: 1.173;
    font-weight: $font-weight-bold;
}


h6, .h6 {
    @include fluid-type($viewport-width-min, $viewport-width-max, $h6-min-font-size, $h6-font-size);
    font-weight: $font-weight-bold;
}


hr {
    display: block;
    position: relative;
    color: $blue;
    margin-top: to-rem(30px);
    margin-bottom: to-rem(30px);
    height: 1px;
    background: $blue;
    opacity: .2;
    border: none;

    &:before {
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 15px;
        height: 10px;
        transform: translate(-50%, -50%);
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.12985 10.8018L15.1515 6.12936C15.2569 6.0604 15.2569 5.90522 15.1515 5.83626L8.12985 1.12074C8.07309 1.08626 8.00822 1.08626 7.95958 1.12074L0.897413 5.83626C0.792008 5.90522 0.792008 6.0604 0.897413 6.12936L7.95958 10.8018C8.01633 10.8363 8.0812 10.8363 8.12985 10.8018Z' stroke='%23004563' fill='%23fff'/%3E%3C/svg%3E%0A");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }

    @include media-breakpoint-up(lg) {
        margin-top: to-rem(60px);
        margin-bottom: to-rem(60px);
    }
}

p {
    margin-bottom: $paragraph-margin-bottom;
}

.lead {
    @include fluid-type($viewport-width-min, $viewport-width-max, $lead-min-font-size, $lead-font-size);
    margin-bottom: $paragraph-margin-bottom * 1.2;
}

// text alignment
.text {
    &-left {
        text-align: left !important;
    }

    &-right {
        text-align: right !important;
    }

    &-center {
        text-align: center !important;
    }

    &-justify {
        text-align: justify !important;
    }
}