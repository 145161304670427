.area {
    @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 30px, 60px);
    @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 30px, 60px);

    // individuelle Abstände
    &.margin {
        &-top--none {
            margin-top: 0;
        }

        &-top--tiny {
            margin-top: 15px;
        }

        &-top--small {
            margin-top: 30px;
        }

        &-top--medium {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 30px, 60px);
        }

        &-top--large {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 50px, 100px);
        }

        &-top--xlarge {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 80px, 160px);
        }

        &-bottom--none {
            margin-bottom: 0;
        }

        &-bottom--tiny {
            margin-bottom: 15px;
        }

        &-bottom--small {
            margin-bottom: 30px;
        }

        &-bottom--medium {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 30px, 60px);
        }

        &-bottom--large {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 50px, 100px);
        }

        &-bottom--xlarge {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 80px, 160px);
        }
    }

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }

    &.is-narrow {
        margin-left: auto;
        margin-right: auto;
        max-width: to-em(800px);
    }

    &.full-width {
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);
    }

    &--thumbnail {
        img, video {
            display: block;
            width: 100%;
            height: auto;
        }

        &--copyright {
            &:before {
                display: inline;
                content: '©'
            }
        }
    }

    a:not(.btn) {
        display: inline-block;
        position: relative;
        color: $blue;
        font-weight: $font-weight-bold;
        transition: color .3s;

        &:after {
            display: block;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: $blue;
            transition: height .3s;
            z-index: -1;
        }

        &:hover {
            color: $white;

            &:after {
                height: 100%;
            }
        }

        &[target="_blank"] {
            display: inline;
            position: static;
            font-weight: $font-weight-light;
            text-decoration: underline;
            transition: opacity .3s;

            &:hover {
                color: $blue;
                opacity: .5;
            }

            &:after {
                display: none;
            }
        }
    }

    ul {
        list-style-type: none;
        margin: 0 0 1em;
        padding: 0;

        li {
            position: relative;
            margin-bottom: 6px;
            padding-left: 22px;

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                top: 9px;
                border-left: 8px solid $blue;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
            }

            > ul {
                margin: 5px 0 0;
            }
        }
    }

    &.highlight, .highlight {
        a:not(.btn) {
            color: $white;

            &:after {
                background-color: $white;
            }

            &:hover {
                color: $blue;
            }

            &[target="_blank"] {
                &:hover {
                    color: $white;
                    opacity: .6;
                }
            }
        }

        ul {
            li {
                &:before {
                    border-left-color: $white;
                }
            }
        }
    }

    // Fade-In für Inhaltselemente
    &[class*="has-fade-in--"] {

    }

    $animation-duration: .8s;
    $animation-easing: cubic-bezier(0.33, 1, 0.68, 1);

    &.fade-in.has-fade-in {
        &--left {
            animation: fade-in-left $animation-duration $animation-easing;
        }

        &--top {
            animation: fade-in-top $animation-duration $animation-easing;
        }

        &--right {
            animation: fade-in-right $animation-duration $animation-easing;
        }

        &--bottom {
            animation: fade-in-bottom $animation-duration $animation-easing;
        }
    }

    /** fade in for full width elements **/
    &.full-width,
    &.area--textmedia:not(.is-narrow),
    &.area--textmedia-special,
    &.area--teaser.is-slider:not(.is-narrow),
    &.area--interactive-map:not(.is-narrow) {
        &.fade-in.has-fade-in {
            &--left {
                animation: fade-in-left-fw $animation-duration $animation-easing;
            }

            &--top {
                animation: fade-in-top-fw $animation-duration $animation-easing;
            }

            &--right {
                animation: fade-in-right-fw $animation-duration $animation-easing;
            }

            &--bottom {
                animation: fade-in-bottom-fw $animation-duration $animation-easing;
            }
        }
    }

    /** special fade in for area media with text **/
    /*&.area--media.has-text .area--media--text {
        &.fade-in.has-fade-in {
            &--bottom {
                animation: fade-in-bottom-special $animation-duration $animation-easing;
            }
        }
    }*/
}