.page-header {
    position: relative;
    $header-top--height: 100px;
    $header-top--height--responsive: 80px;

    padding-top: $header-top--height;

    @include media-breakpoint-down(md) {
        padding-top: $header-top--height--responsive;
    }

    .frontpage & {
        padding-top: 0;

        @include media-breakpoint-down(md) {
            margin-bottom: 70px;
        }
    }

    &--top {
        display: flex;

        @include media-breakpoint-down(xl) {
            padding-right: 60px;
        }

        &, .header-top--fixed .frontpage & {
            flex-wrap: nowrap;
            position: fixed;
            left: 0;
            top: 0;
            padding: 0 0 0 50px;
            width: 100%;
            height: $header-top--height;
            background: $white;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
            z-index: 999;

            align-items: center;
            justify-items: stretch;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                padding: 0 25px;
                height: $header-top--height--responsive;
                justify-content: center;
            }
        }

        html:not(.header-top--fixed) .frontpage & {
            @include media-breakpoint-up(xl) {
                position: absolute;
                left: auto;
                top: 30px;
                padding: 0 0 140px;
                right: 30px;
                bottom: 30px;
                width: 370px;
                height: auto;
                background: rgba($white, .9);
                backdrop-filter: blur(20px);
                box-shadow: none;

                flex-wrap: wrap;
                align-items: stretch;
                align-content: stretch;
                justify-items: normal;
                justify-content: normal;
            }

            @include media-breakpoint-between(md, xl) {
                top: 60px;
                left: 60px;
                right: 60px;
                padding: 0 30px;
                width: auto;
            }

            @include media-breakpoint-down(md) {
                left: 15px;
                top: 15px;
                right: 15px;
                height: 90px;
                width: auto;
            }
        }

        .frontpage & {
            @include media-breakpoint-down(xl) {
                transition: all .3s;
                width: auto;
            }
        }

        html.header-top--fixed .frontpage & {
            transform: translateY(-100%);
            left: 0;
            top: 0;
            right: 0;

            @include media-breakpoint-down(xl) {
                transform: none;
                width: auto;
            }

            @include media-breakpoint-between(md, xl) {
                padding: 0 60px 0 50px;
            }
        }

        .landingpage & {
            justify-content: center;
        }

        &.is-visible {
            html.header-top--fixed .frontpage & {
                @include media-breakpoint-up(xl) {
                    transition: transform .3s;
                    transform: translateY(0);
                }
            }
        }

        .main-nav--toggle {
            @include media-breakpoint-down(md) {
                position: absolute;
                right: 25px;
            }
        }
    }

        &--logo {
            flex: 0 0 auto;
            width: 148px;

            @include media-breakpoint-down(md) {
                width: 108px;
            }

            html:not(.header-top--fixed) .frontpage & {
                @include media-breakpoint-up(xl) {
                    align-self: flex-start;
                    flex: 0 0 100%;
                    width: 100%;
                    padding: 65px 0;
                    background: $white;

                    .logo {
                        margin: 0 auto;
                    }
                }
            }
        }

        &--buttons {
            display: flex;
            position: relative;

            @include media-breakpoint-down(xxxl) {
                display: none;
            }

            html:not(.header-top--fixed) .frontpage & {
                position: absolute;
                bottom: 40px;
                right: 40px;
            }

            .frontpage &,
            html:not(.header-top--fixed) .frontpage & {
                @include media-breakpoint-between(md, xxxl) {
                    display: flex;
                }

                @include media-breakpoint-between(md, xl) {
                    position: relative;
                    bottom: auto;
                    right: auto;
                }
            }

            html.header-top--fixed .frontpage & {
                @include media-breakpoint-between(xl, xxxl) {
                    display: none;
                }
            }

            &--popup {
                position: absolute;
                left: 1px;
                top: 100%;
                padding: 60px 60px 50px;
                width: 100%;
                max-height: calc(100vh - 100px);
                background: $blue;
                color: $white;
                opacity: 0;
                visibility: hidden;
                overflow: visible;
                overflow-y: auto;
                transition: opacity .3s, visibility .3s;

                @include media-breakpoint-up(xl) {
                }

                @include media-breakpoint-down(lg) {
                    padding: 30px;
                }

                @include media-breakpoint-down(md) {
                    left: 0;
                    height: calc(100vh - 150px);
                }

                html:not(.header-top--fixed) .frontpage & {
                    left: 0;

                    @include media-breakpoint-up(xl) {
                        top: auto;
                        bottom: 100px;
                        max-height: calc(100vh - 170px);
                        min-height: 530px;

                        @media (min-height: 800px) {
                            min-height: 635px;
                        }
                    }

                    @include media-breakpoint-between(md, xl) {
                        max-height: calc(100vh - 160px);
                    }

                    @include media-breakpoint-down(md) {
                        height: calc(100vh - 190px);
                        min-height: 380px;
                    }
                }

                .area {
                    margin: 0 0 20px 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            input[name^="page-header--button"] {
                display: none;

                &:checked ~ {
                    a, label, button {
                        &, html:not(.header-top--fixed) .frontpage & {
                            background-color: $blue;
                            color: $white;
                        }

                        .icon {
                            filter: $filter-white;

                            html:not(.header-top--fixed) .frontpage & {
                                filter: $filter-orange;
                            }
                        }
                    }

                    .page-header--buttons--popup {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            &--close {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                width: 60px;
                height: 60px;
                cursor: pointer;
                opacity: .4;
                transition: opacity .3s;

                &:hover {
                    opacity: 1;
                }

                &:before, &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 15px;
                    height: 1px;
                    background: $white;
                }

                &:before {
                    transform: translateX(-50%) rotateZ(-45deg);
                }

                &:after {
                    transform: translateX(-50%) rotateZ(45deg);
                }
            }

                &--content {
                    position: relative;
                    z-index: 1;

                    .area--text {
                        table {
                            margin-bottom: 1em;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            thead {
                                tr th,
                                tr:first-child th,
                                tr:not(:first-child) th {
                                    padding: 0 0 .5em;
                                    @include fluid-type($viewport-width-min, $viewport-width-max, $h5-min-font-size, $h5-font-size);
                                    line-height: 1.173;
                                    font-weight: $font-weight-bold;
                                    text-transform: none;
                                }
                            }

                            th, td {
                                padding: 0 5px;
                                border: none;

                                &:first-child {
                                    padding-left: 0;
                                }

                                &:last-child {
                                    padding-right: 0;
                                }
                            }
                        }
                    }

                    .area--media {
                        width: auto;
                        margin-left: -60px;
                        margin-right: -60px;
                        margin-bottom: 40px;

                        @include media-breakpoint-down(lg) {
                            margin-left: -30px;
                            margin-right: -30px;
                        }

                        &:first-child {
                            @include media-breakpoint-down(lg) {
                                margin-top: 30px;
                            }
                        }
                    }

                    .infobox {
                        margin-bottom: 30px;

                        &:not(.infobox-primary) {
                            border-color: rgba($blue-superlight, .2);

                            a:not(.btn) {
                                color: $white;

                                &:hover {
                                    color: $blue;
                                }

                                &:after {
                                    background-color: $white;
                                }
                            }
                        }
                    }

                    .btn {
                        display: block;
                        margin-bottom: 20px;
                        width: 100%;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        &:after {
                            opacity: .2;
                        }
                    }
                }
        }

            &--button {
                > a, > button, > label {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-content: center;
                    height: 100px;
                    width: 110px;
                    border-left: 1px solid $blue-light;
                    text-align: center;
                    cursor: pointer;
                    transition: color .3s, background-color .3s;

                    @media (min-width: 768px) and (max-width: 800px) {
                        width: 100px;
                    }

                    html.header-top--fixed .frontpage & {
                        transition: none;
                    }

                    html.header-top--fixed .frontpage .is-visible & {
                        transition: color .3s, background-color .3s;
                    }

                    html:not(.header-top--fixed) .frontpage & {
                        @include media-breakpoint-up(xl) {
                            flex: 0 0 auto;
                            background: $orange;
                            color: $white;
                            border-left-color: $orange-darker;
                        }
                    }

                    &:hover {
                        &, html:not(.header-top--fixed) .frontpage & {
                            background-color: $blue;
                            color: $white;
                        }

                        .icon {
                            filter: $filter-white;

                            html:not(.header-top--fixed) .frontpage & {
                                @include media-breakpoint-up(xl) {
                                    filter: $filter-orange;
                                }
                            }
                        }
                    }

                    .icon {
                        position: relative;
                        margin-bottom: 10px;
                        font-size: 25px;
                        filter: $filter-orange;
                        transition: filter .3s;
                        z-index: 1;

                        html:not(.header-top--fixed) .frontpage & {
                            @include media-breakpoint-up(xl) {
                                filter: $filter-white;
                            }
                        }
                    }

                    span {
                        position: relative;
                        display: block;
                        width: 100%;
                        flex: 0 0 100%;
                        font-size: 12px;
                        line-height: 1;
                        font-weight: $font-weight-bold;
                        z-index: 1;
                    }

                    // Spezial Bubbles Hover-Effekt
                    $bubbles: ();
                    $move: ();

                    @for $i from 0 through 10 {
                        $size: (random(70) + 50) / 100 * 1em;
                        $start-x: (random(120) - 10) * 1%;
                        $start-y: (random(70) + 80) * 1%;
                        $end-x: $start-x + (random(20) - 10);
                        $end-y: (random(260) - 200) * 1%;
                        $bubbles: append($bubbles, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) #{$start-x} #{$start-y} / #{$size} #{$size}, comma);
                        $move: append($move, $end-x $end-y, comma);
                    }

                    @keyframes bubbles {
                        100% {
                            background-position: $move;
                            opacity: 0;
                        }
                    }
                    
                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        pointer-events: none;
                        opacity: .3;
                        background: none no-repeat transparent;
                    }

                    &:hover {
                        &:before {
                            animation: bubbles 1s forwards ease-out;
                            background: $bubbles;
                            background-repeat: no-repeat;
                        }
                    }
                }

                &:first-child {
                    > a, > button, > label {
                        html:not(.header-top--fixed) .frontpage & {
                            border: none;
                        }

                        html.header-top--fixed .frontpage & {
                            @include media-breakpoint-between(md, xl) {
                                border: none;
                            }
                        }
                    }
                }
            }

    > .lang-nav {
        @include media-breakpoint-down(xl) {
            display: none;
        }
    }

    &--hint {
        position: absolute;
        right: 50%;
        top: 200px;
        width: 80px;
        height: 160px;
        transform: translateX($container-max-width--narrow / 2);
        background: $blue;
        color: $white;
        font-size: 12px;
        font-weight: $font-weight-boldest;
        line-height: 1.16667;
        text-align: center;
        overflow: hidden;
        z-index: 1;

        @include media-breakpoint-down(xxl) {
            transform: none;
            right: 120px;
        }

        @include media-breakpoint-down(md) {
            top: 125px;
            right: 15px;
            width: 60px;
            height: 120px;
        }

        > * {
            display: flex;
            width: 100%;
            height: 50%;
            align-content: center;
            align-items: center;
            justify-content: center;
        }

        > span {
            text-transform: uppercase;

            @include media-breakpoint-down(md) {
                font-size: 9px;
                line-height: 1.22222;
            }
        }

        &--icon {
            display: flex;
            flex-wrap: wrap;
            background: $orange;

            .icon {
                display: block;
                margin-bottom: 5px;
                width: 30px;
                height: 30px;
                filter: $filter-white;

                @include media-breakpoint-down(md) {
                    width: 23px;
                    height: 23px;
                }
            }

            span {
                display: block;
                width: 100%;
            }
        }
    }

    &--slogan {
        position: absolute;
        left: 50%;
        bottom: 30px;
        width: 182px;
        transform: translateX(-50%);
        z-index: 2;

        @include media-breakpoint-down(md) {
            bottom: 15px;
            width: 128px;
        }

        .frontpage & {
            bottom: 60px;

            @include media-breakpoint-up(xl) {
                left: 60px;
                transform: translateX(0);
            }

            @include media-breakpoint-down(md) {
                bottom: 15px;
            }
        }

        &:before, &:after {
            display: block;
            content: '';
            position: absolute;
            top: 50%;
            width: calc(50vw - 91px);
            transform: translateY(-1px);
            border-bottom: 1px solid rgba($white, .3);

            @include media-breakpoint-down(md) {
                width: calc(50vw - 64px);
            }
        }

        &:before {
            right: 100%;

            .frontpage & {
                @include media-breakpoint-up(xl) {
                    width: 60px;
                }
            }
        }

        &:after {
            left: 100%;

            .frontpage & {
                @include media-breakpoint-up(xl) {
                    width: calc(100vw - 60px - 182px);
                }
            }
        }

        .rhombus {
            color: $white;
            filter: $filter-white;

            @include media-breakpoint-down(md) {
                font-size: 11px;
            }
        }
    }

    &--meta {
        display: flex;
        position: absolute;
        @include fluid-property('left', map-get($grid-breakpoints, xl), map-get($grid-breakpoints, xxxl), 200px, 300px);
        bottom: 45px;
        z-index: 2;

        @include media-breakpoint-between(md, xl) {
            left: 0;
            right: 0;
            justify-content: center;
        }

        @include media-breakpoint-down(md) {
            left: 15px;
            right: 15px;
            bottom: 15px;
            justify-content: center;
        }

        a {
            display: block;
            margin-right: 60px;
            text-decoration: none;
            color: $white;
            font-size: 17px;
            line-height: 1.64;
            transition: opacity .3s;

            @include media-breakpoint-between(md, xl) {
                margin-right: 250px;
            }

            @include media-breakpoint-down(md) {
                margin-right: 230px;
            }

            &:last-child {
                margin-right: 0;
            }

            &:hover {
                opacity: .65;
            }

            .icon {
                margin-right: 10px;
                filter: $filter-white;
                transform: translateY(-1px);
                font-size: 20px;

                @include media-breakpoint-down(md) {

                }
            }

            span {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }
    }

    > .page-header--buttons {
        .frontpage &,
        html.header-top--fixed .frontpage &,
        html:not(.header-top--fixed) .frontpage & {
            display: none;

            @include media-breakpoint-down(md) {
                display: flex;
                z-index: 5;
                transition: transform .3s;
            }
        }

        .frontpage &,
        html:not(.header-top--fixed) .frontpage & {
            @include media-breakpoint-down(md) {
                bottom: 0;
                right: 0;
                left: 0;
                transform: translateY(100%);
            }
        }

        html.header-top--fixed .frontpage & {
            position: fixed;
            transform: translateY(0);
        }

        .page-header--button {
            @include media-breakpoint-down(md) {
                flex: 0 0 25%;

                > a, > label, > button {
                    height: 70px;
                    width: 100%;
                    color: $white;
                    background: $orange;
                    border-left-color: $orange-darker;

                    .icon {
                        width: 20px;
                        height: 20px;
                        filter: $filter-white;
                    }

                    span {
                        font-size: 11px;
                    }
                }
            }
        }

        .page-header--buttons--popup {
            @include media-breakpoint-down(md) {
                top: auto;
                bottom: 100%;
            }
        }
    }
}

// Loading Animation(en) für Header - nur wenn JS aktiviert
html.js {
    // auf Startseite alles animieren
    .frontpage {
        .page-header {
            // loading spinner
            &:before {
                display: block;
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                width: 100px;
                height: 100px;
                border: 10px solid $blue;
                border-top-color: transparent;
                border-radius: 100%;
                transform-origin: 0 0;
                transition: opacity .3s;
                opacity: 1;
                pointer-events: none;
                animation: spin 1s linear infinite;
            }

            &--top {
                opacity: 0;
                transition: opacity .5s, transform .5s;
                transition-delay: .5s;

                @include media-breakpoint-up(xl) {
                    transform: translateX(30%);
                }
            }

            .lang-nav {
                opacity: 0;
                transform: translateX(-30%);
                transition: opacity .5s, transform .5s;
                transition-delay: .25s;
            }

            .header-media {
                opacity: 0;
                transition: opacity .5s;

                &--content {
                    transition-delay: 1s;
                }
            }

            &--slogan {
                @include media-breakpoint-down(xl) {
                    transform: translate(-50%, -50%);
                }
            }

            &--meta {
                opacity: 0;
                transition: opacity .5s;
                transition-delay: .75s;
            }

            &.loaded {
                &:before {
                    opacity: 0;
                }

                .page-header--top {
                    opacity: 1;

                    @include media-breakpoint-up(xl) {
                        transform: none;
                    }
                }

                .lang-nav {
                    opacity: 1;
                    transform: none;
                }

                .header-media {
                    opacity: 1;
                }

                .page-header--slogan {
                    @include media-breakpoint-up(xl) {
                        transform: translate(0, 0);
                    }
                }

                .page-header--meta {
                    opacity: 1;
                }
            }
        }
    }

    // Raute und Header Text auf allen Seiten animieren
    .page-header {
        .header-media--content {
            opacity: 0;
            transition: opacity .5s;
        }

        &--slogan {
            opacity: 0;
            transform: translate(-50%, -50%);
            transition: opacity .5s, transform .5s;
            transition-delay: .75s;
        }

        &.loaded {
            .header-media--content {
                opacity: 1;
            }
            .page-header--slogan {
                opacity: 1;
                transform: translate(-50%, 0);
            }
        }
    }

    // auf Folgeseite Text nicht verzögert animieren
    .subpage {
        .page-header {
            .header-media--content {
                transition-delay: 0s;
            }
        }
    }
}