.pagination {
    @extend .button-nav;
    margin: 0;

    &.is-fixed {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 15px 10px 5px;
        background: $white;
        box-shadow: 0 0 30px rgba(0, 0, 0, .15);
        z-index: 998;
    }

    ul {
        li {
            a:not(.btn) {
                min-width: 38px;
            }
        }
    }
}