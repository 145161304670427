.area--tabs {
    .tab-nav,
    .tab-content {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    // accordion fallback für kleine endgeräte
    .area--accordion {
        display: none;

        @include media-breakpoint-down(sm) {
            display: block;
        }
    }
}