.accommodation-box {
    margin: 60px auto;
    width: 100%;
    max-width: to-em(800px);

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
    }

    &.no-cols {
        @include media-breakpoint-up(md) {
            grid-template-columns: 100%;
        }
    }

    &--address {
        position: relative;
        padding: 5px 0;
        height: 100%;
        text-align: center;

        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
        }

        &:before,
        &:after {
            display: block;
            position: absolute;
            content: '';
            left: 50%;
            width: 20px;
            height: 11px;
            transform: translateX(-50%);
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.12985 10.8018L15.1515 6.12936C15.2569 6.0604 15.2569 5.90522 15.1515 5.83626L8.12985 1.12074C8.07309 1.08626 8.00822 1.08626 7.95958 1.12074L0.897413 5.83626C0.792008 5.90522 0.792008 6.0604 0.897413 6.12936L7.95958 10.8018C8.01633 10.8363 8.0812 10.8363 8.12985 10.8018Z' stroke='rgba(0,69,99,.2)' fill='%23fff'/%3E%3C/svg%3E%0A");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }

        &--links {
            flex: 0 0 auto;
            text-align: left;
        }
    }

        address {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin: 0;
            padding: 30px;
            width: 100%;
            height: 100%;
            border-top: 1px solid rgba($blue, .2);
            border-bottom: 1px solid rgba($blue, .2);

            > * {
            }

            p {
                flex: 0 0 100%;
                margin-bottom: to-em(16px);
                font-weight: $font-weight-light;
            }

            b, strong {
                display: block;
                margin-bottom: to-em(12px);

                &:last-child {
                    margin-bottom: 0;
                }

                .icon {
                    margin-right: 10px;
                    filter: $filter-blue;
                    font-size: 20px;
                }
            }

            a {
                text-decoration: underline;
            }
        }

    &--price {
        display: flex;
        justify-content: stretch;
        align-items: center;
        align-content: center;
        padding: 30px;
        background: $blue-superlight;

        &--wrap {
            position: relative;
            z-index: 1;
            width: 100%;
        }

            &--inner {
                width: 100%;
                text-align: center;

                > * {
                    vertical-align: bottom;
                }

                small {
                    display: inline-block;
                    font-size: 15px;
                    line-height: 1.2;
                }

                b, strong {
                    display: inline-block;
                    font-size: 26px;
                    line-height: 1;
                    font-weight: $font-weight-bolder;
                }

                span {
                    display: block;
                    font-size: 12px;
                    line-height: 1.33333;
                    font-style: italic;
                }
            }

            .btn {
                margin-top: 30px;
                padding-left: 10px;
                padding-right: 10px;
                width: 100%;
                text-align: center;
                white-space: nowrap;

                @include media-breakpoint-down(md) {
                    margin-top: 10px;
                }

                &:only-child {
                    margin-top: 0;
                }
            }
    }
}

.accommodation--highlights {
    max-width: to-em(800px);
    margin-left: auto;
    margin-right: auto;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        column-count: 2;

        @include media-breakpoint-down(md) {
            column-count: initial;
        }

        li {
            position: relative;
            padding: 0 0 0 30px;

            &:before {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                top: 5px;
                width: 15px;
                height: 15px;
                @include setIcon('check', '');
                filter: $filter-blue;
            }
        }
    }
}