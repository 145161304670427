@charset "UTF-8";
/*** IMPORT CONFIG ***/
/** Icon path is only for Pimcore Icon Utility
$icon-path: /static/images/icons/
*/
/*** IMPORT VENDOR ***/
/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease; }
  #baguetteBox-overlay.visible {
    opacity: 1; }
  #baguetteBox-overlay .full-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center; }
    #baguetteBox-overlay .full-image figure {
      display: inline;
      margin: 0;
      height: 100%; }
    #baguetteBox-overlay .full-image img {
      display: inline-block;
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      vertical-align: middle;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }
    #baguetteBox-overlay .full-image figcaption {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      line-height: 1.8;
      white-space: normal;
      color: #ccc;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.6);
      font-family: sans-serif; }
    #baguetteBox-overlay .full-image:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease; }
  #baguetteBox-slider.bounce-from-right {
    animation: bounceFromRight .4s ease-out; }
  #baguetteBox-slider.bounce-from-left {
    animation: bounceFromLeft .4s ease-out; }

@keyframes bounceFromRight {
  0% {
    margin-left: 0; }
  50% {
    margin-left: -30px; }
  100% {
    margin-left: 0; } }

@keyframes bounceFromLeft {
  0% {
    margin-left: 0; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0; } }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px; }

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color .4s ease; }
  .baguetteBox-button:focus, .baguetteBox-button:hover {
    background-color: rgba(50, 50, 50, 0.9); }
  .baguetteBox-button#next-button {
    right: 2%; }
  .baguetteBox-button#previous-button {
    left: 2%; }
  .baguetteBox-button#close-button {
    top: 20px;
    right: 2%;
    right: calc(2% + 6px);
    width: 30px;
    height: 30px; }
  .baguetteBox-button svg {
    position: absolute;
    left: 0;
    top: 0; }

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px; }

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out; }

.baguetteBox-double-bounce2 {
  animation-delay: -1s; }

@keyframes bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

@keyframes splide-loading {
  0% {
    transform: rotate(0); }
  to {
    transform: rotate(1turn); } }

.splide--draggable > .splide__slider > .splide__track, .splide--draggable > .splide__track {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.splide--fade > .splide__slider > .splide__track > .splide__list, .splide--fade > .splide__track > .splide__list {
  display: block; }

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide, .splide--fade > .splide__track > .splide__list > .splide__slide {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 0; }

.splide--fade > .splide__slider > .splide__track > .splide__list > .splide__slide.is-active, .splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  opacity: 1;
  position: relative;
  z-index: 1; }

.splide--rtl {
  direction: rtl; }

.splide--ttb.is-active > .splide__slider > .splide__track > .splide__list, .splide--ttb.is-active > .splide__track > .splide__list {
  display: block; }

.splide__container {
  box-sizing: border-box;
  position: relative; }

.splide__list {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  transform-style: preserve-3d; }

.splide.is-initialized:not(.is-active) .splide__list {
  display: block; }

.splide__pagination {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  pointer-events: none; }

.splide__pagination li {
  display: inline-block;
  line-height: 1;
  list-style-type: none;
  margin: 0;
  pointer-events: auto; }

.splide__progress__bar {
  width: 0; }

.splide {
  outline: none;
  position: relative;
  visibility: hidden; }

.splide.is-initialized, .splide.is-rendered {
  visibility: visible; }

.splide__slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  list-style-type: none !important;
  margin: 0;
  outline: none;
  position: relative; }

.splide__slide img {
  vertical-align: bottom; }

.splide__slider {
  position: relative; }

.splide__spinner {
  animation: splide-loading 1s linear infinite;
  border: 2px solid #999;
  border-left-color: transparent;
  border-radius: 50%;
  bottom: 0;
  contain: strict;
  display: inline-block;
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px; }

.splide__track {
  overflow: hidden;
  position: relative;
  z-index: 0; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  left: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  /*
      */
  right: 0;
  /*
      /*rtl:end:ignore*/
  /*
      */ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto; }

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none; }

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #959ea9; }

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff; }

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7; }

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

/*** IMPORT CONFIG ***/
/*** IMPORT BASIC STUFF ***/
/* Fluid for mobile (375px) */
/* Fluid up to xl */
/* Fluid up to xlplus */
/* Fluid up to outer */
/*
  fluid mixin
  - provides a fluid layout for mobile and px layout for everything else
  - one value for both sizes
 */
/*
  fluidDiff mixin
  - provides a fluid layout for mobile and px layout for everything else
  - different values for both sizes
 */
/* Container Mimicry */
.embed-responsive {
  position: relative;
  width: 100%;
  height: 0; }
  .embed-responsive-1by1 {
    padding-bottom: 100%; }
  .embed-responsive-3by2 {
    padding-bottom: 66.66666%; }
  .embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive > * {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .embed-responsive > iframe {
    border: none; }

.is-hidden {
  display: none !important; }

.layer-banner {
  display: none;
  max-width: calc(100vw - 60px);
  max-height: calc(100vh - 60px);
  width: 750px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;
  opacity: 0;
  transform: translate(-50%, -35%);
  transition: opacity .3s, transform .3s;
  pointer-events: none; }
  .layer-banner.is-transitioning {
    display: block; }
  .layer-banner.is-visible {
    opacity: 1;
    transform: translate(-50%, -50%);
    display: block;
    pointer-events: auto; }
  .layer-banner--inner {
    max-height: calc(100vh - 60px);
    border-radius: 5px;
    background-color: #F2F6F7;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    overflow-y: auto; }
    @media (min-width: 768px) {
      .layer-banner--inner {
        display: grid;
        grid-template-columns: 50% 50%; } }
  .layer-banner--media {
    position: relative; }
    .layer-banner--media:before {
      display: block;
      content: '';
      width: 100%;
      height: 0;
      padding-bottom: 56.25%; }
      @media (min-width: 768px) {
        .layer-banner--media:before {
          padding-bottom: 75%; } }
    .layer-banner--media img,
    .layer-banner--media video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .layer-banner--badge {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    left: 50%;
    top: 25px;
    padding: 15px;
    width: 140px;
    height: 140px;
    transform: translateX(-50%) rotate(10deg);
    font-size: 13px;
    line-height: 1.25;
    font-weight: 500;
    background: #FF9C59;
    color: #fff;
    border-radius: 100%;
    text-align: center;
    overflow: hidden;
    z-index: 2; }
    @media (max-width: 767.98px) {
      .layer-banner--badge {
        top: 10px;
        left: 10px;
        padding: 5px;
        width: 100px;
        height: 100px;
        transform: translateX(0) rotate(-10deg);
        font-size: 12px; } }
    .layer-banner--badge strong {
      margin-bottom: 5px;
      font-weight: 600; }
    .layer-banner--badge *:last-child {
      margin-bottom: 0; }
  .layer-banner--content {
    padding: 60px;
    position: relative;
    color: #004563; }
    @media (min-width: 768px) {
      .layer-banner--content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-items: flex-start;
        justify-content: flex-start; } }
    @media (max-width: 575.98px) {
      .layer-banner--content {
        padding: 30px; } }
    .layer-banner--content > * {
      margin-bottom: 1em; }
      .layer-banner--content > *:last-child {
        margin-bottom: 0; }
  @media (min-width: 768px) {
    .layer-banner.has-badge .layer-banner--content {
      padding-left: 100px; } }
  .layer-banner--close {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    padding: 0;
    width: 47px;
    height: 47px;
    transform: translate(50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    border: none;
    border-radius: 100%;
    cursor: pointer;
    transition: transform .3s, background-color .3s;
    z-index: 1; }
    .layer-banner--close:hover {
      background-color: #000;
      transform: translate(50%, -50%) scale(1.1); }
    .layer-banner--close i {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      height: 25px;
      width: 25px;
      transform: translate(-50%, -50%); }
      .layer-banner--close i:before, .layer-banner--close i:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 2px;
        width: 100%;
        background-color: #fff; }
      .layer-banner--close i:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      .layer-banner--close i:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
  .layer-banner.media-only .layer-banner--inner, .layer-banner.text-only .layer-banner--inner {
    display: block; }
  .layer-banner.media-only .layer-banner--badge, .layer-banner.text-only .layer-banner--badge {
    left: 20px;
    top: 20px;
    transform: translateX(0) rotate(-10deg); }
    @media (max-width: 767.98px) {
      .layer-banner.media-only .layer-banner--badge, .layer-banner.text-only .layer-banner--badge {
        top: 10px;
        left: 10px; } }
  .layer-banner.text-only .layer-banner--content {
    display: block; }
  .layer-banner.text-only.has-badge .layer-banner--content {
    padding-left: 200px; }
    @media (max-width: 767.98px) {
      .layer-banner.text-only.has-badge .layer-banner--content {
        padding-left: 130px; } }
  .layer-banner.media-only .layer-banner--inner {
    background-color: transparent; }
  .layer-banner.media-only .layer-banner--media:before {
    padding-bottom: 56.25%; }
  .layer-banner.media-only .layer-banner--content {
    position: absolute;
    justify-content: center;
    justify-items: center;
    left: 0;
    right: 0;
    bottom: 0; }

div#tarteaucitronAlertBig:focus {
  outline: 0; }

.tarteaucitron-modal-open {
  overflow: hidden;
  height: 100%; }

#tarteaucitronContentWrapper {
  display: unset; }

/** 14042021 **/
span.tarteaucitronReadmoreSeparator {
  display: inline !important; }

/******/
/** 09052021 **/
.tarteaucitronName .tacCurrentStatus, .tarteaucitronName .tarteaucitronReadmoreSeparator {
  color: #333 !important;
  font-size: 12px !important;
  text-transform: capitalize; }

/**************/
/** 27032021 **/
button.tarteaucitron-toggle-group {
  display: block; }

span.tarteaucitronH3 {
  font-weight: 700 !important; }

#tarteaucitron #tarteaucitronServices_mandatory .tarteaucitronH3 {
  font-weight: 500 !important;
  font-size: 14px;
  margin-top: 7px; }

.tarteaucitronLine {
  border-left: 0px solid transparent !important; }

/*****/
/** BETTER MOBILE MODE **/
@media screen and (max-width: 767px) {
  html body #tarteaucitronRoot #tarteaucitron ul#tarteaucitronServices_mandatory .tarteaucitronDeny {
    display: none !important; }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button,
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronAsk,
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronName {
    width: 100% !important;
    display: block !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-sizing: border-box !important;
    max-width: 100% !important;
    margin-bottom: 8px !important; }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder ul .tarteaucitronLine {
    padding: 16px !important; }
  html body #tarteaucitronRoot #tarteaucitron div#tarteaucitronMainLineOffset .tarteaucitronName {
    display: none !important; }
  #tarteaucitronServices_mandatory li.tarteaucitronLine .tarteaucitronName span {
    width: 100% !important;
    display: inline-block; }
  li.tarteaucitronLine .tarteaucitronName span {
    width: 80% !important;
    display: inline-block; }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group {
    width: 10% !important;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 0px;
    padding: 10px 0; }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:before {
    content: '\0025BE';
    font-weight: 700;
    font-size: 14px; }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:before {
    content: '\0025B4'; } }

@media screen and (min-width: 768px) {
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder button.tarteaucitron-toggle-group:after {
    content: '\0025BE';
    font-weight: 700;
    font-size: 14px;
    margin-left: 15px; }
  html body #tarteaucitronRoot #tarteaucitron .tarteaucitronBorder .tarteaucitronIsExpanded button.tarteaucitron-toggle-group:after {
    content: '\0025B4';
    margin-left: 15px; } }

/****/
/***
* Reset CSS
*/
#tarteaucitronRoot div, #tarteaucitronRoot span, #tarteaucitronRoot applet, #tarteaucitronRoot object, #tarteaucitronRoot iframe, #tarteaucitronRoot h1, #tarteaucitronRoot h2, #tarteaucitronRoot h3, #tarteaucitronRoot h4, #tarteaucitronRoot h5, #tarteaucitronRoot h6, #tarteaucitronRoot p, #tarteaucitronRoot blockquote, #tarteaucitronRoot pre, #tarteaucitronRoot a, #tarteaucitronRoot abbr, #tarteaucitronRoot acronym, #tarteaucitronRoot address, #tarteaucitronRoot big, #tarteaucitronRoot cite, #tarteaucitronRoot code, #tarteaucitronRoot del, #tarteaucitronRoot dfn, #tarteaucitronRoot em, #tarteaucitronRoot img, #tarteaucitronRoot ins, #tarteaucitronRoot kbd, #tarteaucitronRoot q, #tarteaucitronRoot s, #tarteaucitronRoot samp, #tarteaucitronRoot small, #tarteaucitronRoot strike, #tarteaucitronRoot strong, #tarteaucitronRoot sub, #tarteaucitronRoot sup, #tarteaucitronRoot tt, #tarteaucitronRoot var, #tarteaucitronRoot b, #tarteaucitronRoot u, #tarteaucitronRoot i, #tarteaucitronRoot center, #tarteaucitronRoot dl, #tarteaucitronRoot dt, #tarteaucitronRoot dd, #tarteaucitronRoot ol, #tarteaucitronRoot ul, #tarteaucitronRoot li, #tarteaucitronRoot fieldset, #tarteaucitronRoot form, #tarteaucitronRoot label, #tarteaucitronRoot legend, #tarteaucitronRoot table, #tarteaucitronRoot caption, #tarteaucitronRoot tbody, #tarteaucitronRoot tfoot, #tarteaucitronRoot thead, #tarteaucitronRoot tr, #tarteaucitronRoot th, #tarteaucitronRoot td, #tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot canvas, #tarteaucitronRoot details, #tarteaucitronRoot embed, #tarteaucitronRoot figure, #tarteaucitronRoot figcaption, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot output, #tarteaucitronRoot ruby, #tarteaucitronRoot section, #tarteaucitronRoot summary, #tarteaucitronRoot time, #tarteaucitronRoot mark, #tarteaucitronRoot audio, #tarteaucitronRoot video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /*background: initial;*/
  text-align: initial;
  text-shadow: initial; }

/* Animation */
#tarteaucitronRoot * {
  transition: border 300ms, background 300ms, opacity 200ms, box-shadow 400ms; }

/* HTML5 display-role reset for older browsers */
#tarteaucitronRoot article, #tarteaucitronRoot aside, #tarteaucitronRoot details, #tarteaucitronRoot figcaption, #tarteaucitronRoot figure, #tarteaucitronRoot footer, #tarteaucitronRoot header, #tarteaucitronRoot hgroup, #tarteaucitronRoot menu, #tarteaucitronRoot nav, #tarteaucitronRoot section {
  display: block; }

#tarteaucitronRoot ol, #tarteaucitronRoot ul {
  list-style: none; }

#tarteaucitronRoot blockquote, #tarteaucitronRoot q {
  quotes: none; }

#tarteaucitronRoot blockquote:before, #tarteaucitronRoot blockquote:after, #tarteaucitronRoot q:before, #tarteaucitronRoot q:after {
  content: '';
  content: none; }

#tarteaucitronRoot table {
  border-collapse: collapse;
  border-spacing: 0; }

#tarteaucitronRoot a:focus-visible, #tarteaucitronRoot button:focus-visible {
  outline: 3px dashed #3d86d8; }

/***
 * Better scroll management
 */
div#tarteaucitronMainLineOffset {
  margin-top: 0 !important; }

div#tarteaucitronServices {
  margin-top: 21px !important; }

#tarteaucitronServices::-webkit-scrollbar {
  width: 5px; }

#tarteaucitronServices::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0); }

#tarteaucitronServices::-webkit-scrollbar-thumb {
  background-color: #ddd;
  outline: 0px solid slategrey; }

div#tarteaucitronServices {
  box-shadow: 0 40px 60px #545454; }

/***
 * Responsive layout for the control panel
 */
@media screen and (max-width: 479px) {
  #tarteaucitron .tarteaucitronLine .tarteaucitronName {
    width: 90% !important; }
  #tarteaucitron .tarteaucitronLine .tarteaucitronAsk {
    float: left !important;
    margin: 10px 15px 5px; } }

@media screen and (max-width: 767px) {
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer, #tarteaucitron {
    background: #fff;
    border: 0 !important;
    bottom: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin: 0 !important;
    max-height: 100% !important;
    max-width: 100% !important;
    top: 0 !important;
    width: 100% !important; }
  #tarteaucitron .tarteaucitronBorder {
    border: 0 !important; }
  #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
    border: 0 !important; }
  #tarteaucitron #tarteaucitronServices .tarteaucitronTitle {
    text-align: left !important; }
  .tarteaucitronName .tarteaucitronH2 {
    max-width: 80%; }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
    text-align: center !important; }
  #tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk button {
    margin-bottom: 5px; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  #tarteaucitron {
    border: 0 !important;
    left: 0 !important;
    margin: 0 5% !important;
    max-height: 80% !important;
    width: 90% !important; } }

/***
 * Common value
 */
#tarteaucitronRoot div#tarteaucitron {
  left: 0;
  right: 0;
  margin: auto; }

#tarteaucitronRoot button#tarteaucitronBack {
  background: #eee; }

#tarteaucitron .clear {
  clear: both; }

#tarteaucitron a {
  color: #424242;
  font-size: 11px;
  font-weight: 700;
  text-decoration: none; }

#tarteaucitronRoot button {
  background: transparent;
  border: 0; }

#tarteaucitronAlertBig strong, #tarteaucitronAlertSmall strong,
#tarteaucitronAlertBig a, #tarteaucitronAlertSmall a {
  color: #fff; }

#tarteaucitron strong {
  font-size: 22px;
  font-weight: 500; }

#tarteaucitron ul {
  padding: 0; }

#tarteaucitron .tarteaucitronH1, #tarteaucitron .tarteaucitronH2, #tarteaucitron .tarteaucitronH3, #tarteaucitron .tarteaucitronH4, #tarteaucitron .tarteaucitronH5, #tarteaucitron .tarteaucitronH6 {
  display: block; }

.cookie-list {
  list-style: none;
  padding: 0;
  margin: 0; }

/***
 * Root div added just before </body>
 */
#tarteaucitronRoot {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%; }

#tarteaucitronRoot * {
  box-sizing: initial;
  color: #004563;
  font-size: 14px;
  line-height: normal;
  vertical-align: initial; }

#tarteaucitronRoot .tarteaucitronH1 {
  font-size: 1.5em;
  text-align: center;
  color: #fff;
  margin: 15px 0; }

#tarteaucitronRoot .tarteaucitronH2 {
  display: inline-block;
  margin: 12px 0 0 10px;
  color: #fff; }

#tarteaucitronCookiesNumberBis.tarteaucitronH2 {
  margin-left: 0; }

/***
 * Control panel
 */
#tarteaucitronBack {
  background: #fff;
  display: none;
  height: 100%;
  left: 0;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2147483646; }

#tarteaucitron {
  display: none;
  max-height: 80%;
  left: 50%;
  margin: 0 auto 0 -430px;
  padding: 0;
  position: fixed;
  top: 6%;
  width: 860px;
  z-index: 2147483647; }

#tarteaucitron .tarteaucitronBorder {
  background: #fff;
  border: 2px solid #333;
  border-top: 0;
  height: auto;
  overflow: auto; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronClosePanelCookie,
#tarteaucitron #tarteaucitronClosePanel {
  background: #004563;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  padding: 4px 0;
  position: absolute;
  right: 0;
  text-align: center;
  width: 70px; }

#tarteaucitron #tarteaucitronDisclaimer {
  color: #555;
  font-size: 12px;
  margin: 15px auto 0;
  width: 80%; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden,
#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  background: rgba(51, 51, 51, 0.07); }

#tarteaucitron #tarteaucitronServices .tarteaucitronHidden {
  display: none;
  position: relative; }

#tarteaucitronCookiesList .tarteaucitronH3.tarteaucitronTitle {
  width: 100%;
  box-sizing: border-box; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button,
#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  margin: 20px 0px 0px;
  padding: 5px 20px;
  text-align: left;
  width: auto;
  background: #004563; }

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle button {
  font-weight: 500; }

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  color: #fff;
  font-weight: 500; }

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a:hover,
#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a:hover {
  text-decoration: none !important; }

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName a {
  font-size: 22px; }

#tarteaucitron #tarteaucitronServices .tarteaucitronTitle a {
  font-size: 14px; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronTitle {
  padding: 5px 10px;
  margin: 0; }

#tarteaucitron #tarteaucitronInfo,
#tarteaucitron #tarteaucitronServices .tarteaucitronDetails {
  color: #fff;
  display: none;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  max-width: 270px;
  padding: 20px;
  position: absolute;
  z-index: 2147483647; }

#tarteaucitron #tarteaucitronInfo a {
  color: #fff;
  text-decoration: underline; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine:hover {
  background: #E5ECEF; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine {
  background: #F2F6F7;
  border-left: 5px solid transparent;
  margin: 0;
  overflow: hidden;
  padding: 15px 5px; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed {
  border-color: #1B870B; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied {
  border-color: #9C1A1A; }

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  background: #004563;
  border: 3px solid #004563;
  border-left: 9px solid #004563;
  border-top: 5px solid #004563;
  margin-bottom: 0;
  margin-top: 21px;
  position: relative; }

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine:hover {
  background: #004563; }

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName {
  margin-left: 15px;
  margin-top: 2px; }

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronName button {
  color: #fff; }

#tarteaucitron #tarteaucitronServices .tarteaucitronMainLine .tarteaucitronAsk {
  margin-top: 0px !important; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName {
  display: inline-block;
  float: left;
  margin-left: 10px;
  text-align: left;
  width: 50%; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName a:hover {
  text-decoration: underline; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk {
  display: inline-block;
  float: right;
  margin: 7px 15px 0;
  text-align: right; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronAsk .tarteaucitronDeny,
.tac_activate .tarteaucitronAllow {
  background: #555;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 6px 10px;
  text-align: center;
  text-decoration: none;
  width: auto;
  border: 0; }

#tarteaucitron #tarteaucitronServices #tarteaucitronAllAllowed.tarteaucitronIsSelected {
  background-color: #1B870B;
  opacity: 1; }

#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied.tarteaucitronIsSelected,
#tarteaucitron #tarteaucitronServices #tarteaucitronAllDenied2.tarteaucitronIsSelected {
  background-color: #9C1A1A;
  opacity: 1; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsAllowed .tarteaucitronAllow,
#tarteaucitron #tarteaucitronServices #tarteaucitronServices_mandatory .tarteaucitronLine button.tarteaucitronAllow {
  background-color: #1B870B; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine.tarteaucitronIsDenied .tarteaucitronDeny {
  background-color: #9C1A1A; }

#tarteaucitron #tarteaucitronServices #tarteaucitronServices_mandatory .tarteaucitronLine button.tarteaucitronAllow {
  opacity: 0.4; }

#tarteaucitron #tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies {
  color: #333;
  font-size: 12px; }

#tarteaucitron .tarteaucitronH3 {
  font-size: 18px; }

#tarteaucitron #tarteaucitronMainLineOffset .tarteaucitronName {
  width: auto !important;
  margin-left: 0 !important;
  font-size: 14px; }

span#tarteaucitronDisclaimerAlert {
  padding: 0 10px;
  display: inline-block; }

#tarteaucitron .tarteaucitronBorder, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList, #tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronHidden, #tarteaucitron #tarteaucitronServices .tarteaucitronMainLine {
  border-color: #004563 !important; }

/***
 * Big alert
 */
.tarteaucitronAlertBigTop {
  top: 0; }

.tarteaucitronAlertBigBottom {
  bottom: 0; }

#tarteaucitronRoot #tarteaucitronAlertBig {
  background: #fff;
  color: #004563;
  display: none;
  font-size: 15px !important;
  left: 0;
  position: fixed;
  z-index: 2147483645;
  text-align: center;
  padding: 10px 0 10px 0;
  margin: auto;
  width: calc(100vw - 60px) !important;
  max-width: 600px;
  max-height: calc(100vh - 60px);
  border-radius: 5px !important;
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto; }

#tarteaucitronRoot #tarteaucitronAlertBig:before {
  font-size: 24px !important; }

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert,
#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  color: #004563; }

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert {
  padding: 0 !important;
  font-size: 14px !important;
  line-height: 1.33; }

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl {
  margin-top: 5px;
  font-weight: 500; }

#tarteaucitronAlertBig #tarteaucitronDisclaimerAlert strong {
  font-weight: 700; }

#tarteaucitronAlertBig #tarteaucitronPrivacyUrl,
#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog {
  cursor: pointer; }

#tarteaucitronAlertBig #tarteaucitronCloseAlert,
#tarteaucitronAlertBig #tarteaucitronPersonalize,
#tarteaucitronAlertBig #tarteaucitronPersonalize2,
.tarteaucitronCTAButton,
#tarteaucitron #tarteaucitronPrivacyUrl,
#tarteaucitron #tarteaucitronPrivacyUrlDialog,
#tarteaucitronRoot .tarteaucitronDeny,
#tarteaucitronRoot .tarteaucitronAllow {
  background: #008300;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px !important;
  line-height: 1.2;
  padding: 5px 10px;
  text-decoration: none;
  margin-left: 7px; }

#tarteaucitronAlertBig #tarteaucitronPrivacyUrlDialog {
  font-weight: 500; }

#tarteaucitronRoot .tarteaucitronDeny {
  background: #9C1A1A; }

#tarteaucitronAlertBig #tarteaucitronCloseAlert, #tarteaucitron #tarteaucitronPrivacyUrl, #tarteaucitron #tarteaucitronPrivacyUrlDialog {
  background: #fff;
  color: #333;
  font-size: 13px;
  margin-bottom: 3px;
  margin-left: 7px;
  padding: 5px 10px; }

#tarteaucitronAlertBig #tarteaucitronCloseAlert {
  background: #004563;
  color: #fff; }

#tarteaucitronPercentage {
  background: #0A0 !important;
  box-shadow: 0 0 2px #fff, 0 1px 2px #555;
  height: 5px;
  left: 0;
  position: fixed;
  width: 0;
  z-index: 2147483644; }

/***
 * Icon
 */
.tarteaucitronIconBottomRight {
  bottom: 0;
  right: 0; }

.tarteaucitronIconBottomLeft {
  bottom: 0;
  left: 0; }

.tarteaucitronIconTopRight {
  top: 0;
  right: 0; }

.tarteaucitronIconTopLeft {
  top: 0;
  left: 0; }

.tarteaucitronIconTopLeft #tarteaucitronManager {
  border-radius: 2px 7px 7px 2px; }

.tarteaucitronIconTopRight #tarteaucitronManager {
  border-radius: 7px 2px 2px 7px; }

.tarteaucitronIconBottomLeft #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px; }

.tarteaucitronIconBottomRight #tarteaucitronManager {
  border-radius: 7px 7px 2px 2px; }

#tarteaucitronIcon {
  background: transparent;
  position: fixed;
  display: none;
  width: auto;
  z-index: 2147483646; }

#tarteaucitronIcon #tarteaucitronManager {
  color: transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px;
  border: none; }

#tarteaucitronIcon #tarteaucitronManager img {
  width: 50px;
  height: 50px; }

#tarteaucitronRoot .tarteaucitronCross::before {
  content: '\2717';
  display: inline-block;
  color: white; }

#tarteaucitronRoot .tarteaucitronCheck::before {
  content: '\2713';
  display: inline-block;
  color: white; }

#tarteaucitronRoot .tarteaucitronPlus::before {
  content: '\271b';
  display: inline-block;
  color: white; }

/***
 * Small alert
 */
.tarteaucitronAlertSmallTop, .tarteaucitronAlertSmallBottom {
  bottom: 0; }

#tarteaucitronAlertSmall {
  background: #333;
  display: none;
  padding: 0;
  position: fixed;
  right: 0;
  text-align: center;
  width: auto;
  z-index: 2147483646; }

#tarteaucitronAlertSmall #tarteaucitronManager {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 11px !important;
  padding: 8px 10px 8px; }

#tarteaucitronAlertSmall #tarteaucitronManager:hover {
  background: rgba(255, 255, 255, 0.05); }

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot {
  background-color: gray;
  border-radius: 5px;
  display: block;
  height: 8px;
  margin-bottom: 1px;
  margin-top: 5px;
  overflow: hidden;
  width: 100%; }

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow,
#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  display: block;
  float: left;
  height: 100%;
  width: 0%; }

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotGreen {
  background-color: #1B870B; }

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotYellow {
  background-color: #FBDA26; }

#tarteaucitronAlertSmall #tarteaucitronManager #tarteaucitronDot #tarteaucitronDotRed {
  background-color: #9C1A1A; }

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 30px;
  padding: 0px 10px;
  vertical-align: top; }

#tarteaucitronAlertSmall #tarteaucitronCookiesNumber:hover {
  background: rgba(255, 255, 255, 0.3); }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer {
  display: none;
  max-height: 70%;
  max-width: 500px;
  position: fixed;
  right: 0;
  width: 100%; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList {
  background: #fff;
  border: 2px solid #333;
  color: #333;
  font-size: 11px;
  height: auto;
  overflow: auto;
  text-align: left; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList strong {
  color: #333; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle {
  background: #333;
  margin-top: 21px;
  padding: 13px 0 9px 13px;
  text-align: left; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesTitle strong {
  color: #fff;
  font-size: 16px; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain {
  background: rgba(51, 51, 51, 0.1);
  padding: 7px 5px 10px;
  word-wrap: break-word; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain:hover {
  background: rgba(51, 51, 51, 0.2); }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain a {
  color: #333;
  text-decoration: none; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft {
  display: inline-block;
  width: 50%; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListLeft a strong {
  color: darkred; }

#tarteaucitronAlertSmall #tarteaucitronCookiesListContainer #tarteaucitronCookiesList .tarteaucitronCookiesListMain .tarteaucitronCookiesListRight {
  color: #333;
  display: inline-block;
  font-size: 11px;
  margin-left: 10%;
  vertical-align: top;
  width: 30%; }

/***
 * Fallback activate link
 */
.tac_activate {
  background: #333;
  color: #fff;
  display: table;
  font-size: 12px;
  height: 100%;
  line-height: initial;
  margin: auto;
  text-align: center;
  width: 100%; }

.tac_float {
  display: table-cell;
  text-align: center;
  vertical-align: middle; }

.tac_activate .tac_float strong {
  color: #fff; }

.tac_activate .tac_float .tarteaucitronAllow {
  background-color: #1B870B;
  display: inline-block; }

/***
 * CSS for services
 */
ins.ferank-publicite, ins.adsbygoogle {
  text-decoration: none; }

div.amazon_product {
  height: 240px;
  width: 120px; }

.tarteaucitronIsAllowed .tarteaucitronDeny {
  opacity: 0.4 !important; }

.tarteaucitronIsDenied .tarteaucitronAllow {
  opacity: 0.4 !important; }

.tarteaucitronIsAllowed .tarteaucitronAllow {
  opacity: 1 !important; }

.tarteaucitronIsDenied .tarteaucitronDeny {
  opacity: 1 !important; }

.tarteaucitronLine .tarteaucitronAllow, .tarteaucitronLine .tarteaucitronDeny {
  opacity: 0.4; }

#tarteaucitronServices_mandatory button.tarteaucitronAllow {
  opacity: 1; }

div#tarteaucitronInfo {
  display: block !important;
  position: relative !important;
  text-align: center !important;
  max-width: 80% !important;
  padding: 15px 0 !important;
  margin: -10px auto 20px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  border: none; }

a.tarteaucitronSelfLink {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -30px;
  text-align: center !important;
  display: block;
  height: 30px; }

.tarteaucitronMainLine .tarteaucitronH2 {
  font-size: 1.2em !important;
  margin-top: 4px !important; }

span.tarteaucitronTitle.tarteaucitronH3 {
  margin-top: 12px !important; }

#tarteaucitronCloseCross {
  position: absolute;
  color: #FFFF;
  font-size: 1.8rem;
  cursor: pointer;
  top: 10px;
  right: 26px; }

.tarteaucitron-spacer-20 {
  height: 20px;
  display: block; }

.tarteaucitron-display-block {
  display: block; }

.tarteaucitron-display-none {
  display: none; }

#tarteaucitronServices .tarteaucitronLine .tarteaucitronName br,
#tarteaucitronServices .tarteaucitronLine .tarteaucitronName a + .tarteaucitronReadmoreSeparator,
#tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronListCookies,
#tarteaucitronServices .tarteaucitronLine .tarteaucitronName .tarteaucitronReadmoreOfficial {
  display: none !important; }

/**
 * IMPORT FONTS
 * ------------
 * insert fonts in .build/.fonts
 * execute gulp fonts
 */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  font-stretch: normal;
  src: url(../fonts/googlefonts/Work_Sans-normal-300.woff2) format("woff2"), url(../fonts/googlefonts/Work_Sans-normal-300.woff) format("woff"), url(../fonts/googlefonts/Work_Sans-normal-300.ttf) format("truetype");
  unicode-range: U+0-10FFFF; }

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  font-stretch: normal;
  src: url(../fonts/googlefonts/Work_Sans-normal-400.woff2) format("woff2"), url(../fonts/googlefonts/Work_Sans-normal-400.woff) format("woff"), url(../fonts/googlefonts/Work_Sans-normal-400.ttf) format("truetype");
  unicode-range: U+0-10FFFF; }

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  font-stretch: normal;
  src: url(../fonts/googlefonts/Work_Sans-normal-500.woff2) format("woff2"), url(../fonts/googlefonts/Work_Sans-normal-500.woff) format("woff"), url(../fonts/googlefonts/Work_Sans-normal-500.ttf) format("truetype");
  unicode-range: U+0-10FFFF; }

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  font-stretch: normal;
  src: url(../fonts/googlefonts/Work_Sans-normal-600.woff2) format("woff2"), url(../fonts/googlefonts/Work_Sans-normal-600.woff) format("woff"), url(../fonts/googlefonts/Work_Sans-normal-600.ttf) format("truetype");
  unicode-range: U+0-10FFFF; }

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  font-stretch: normal;
  src: url(../fonts/googlefonts/Work_Sans-normal-700.woff2) format("woff2"), url(../fonts/googlefonts/Work_Sans-normal-700.woff) format("woff"), url(../fonts/googlefonts/Work_Sans-normal-700.ttf) format("truetype");
  unicode-range: U+0-10FFFF; }

/*** IMPORT BASIC STUFF ***/
/* Fluid for mobile (375px) */
/* Fluid up to xl */
/* Fluid up to xlplus */
/* Fluid up to outer */
/*
  fluid mixin
  - provides a fluid layout for mobile and px layout for everything else
  - one value for both sizes
 */
/*
  fluidDiff mixin
  - provides a fluid layout for mobile and px layout for everything else
  - different values for both sizes
 */
/* Container Mimicry */
*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Work Sans", sans-serif;
  font-size: 17px;
  font-weight: 400;
  line-height: 1.64;
  color: #004563;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1em; }

p {
  margin-top: 0;
  margin-bottom: 1em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: 500; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #004563;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #004563;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 10px;
  padding-bottom: 10px;
  color: #000000;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin: 0; }

button {
  border-radius: 0; }

button:focus {
  /*outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;*/
  outline: none; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

@keyframes spin {
  from {
    transform: rotateZ(0deg); }
  to {
    transform: rotateZ(360deg); } }

@keyframes bounce {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(10px); }
  100% {
    transform: translateY(0); } }

@keyframes fade-in-left {
  0%, 33% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fade-in-left-fw {
  0%, 33% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0, 0); } }

@keyframes fade-in-right {
  0%, 33% {
    opacity: 0;
    transform: translate3d(50%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fade-in-right-fw {
  0%, 33% {
    opacity: 0;
    transform: translate3d(0, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0, 0); } }

@keyframes fade-in-top {
  0%, 33% {
    opacity: 0;
    transform: translate3d(0, -15vh, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fade-in-top-fw {
  0%, 33% {
    opacity: 0;
    transform: translate3d(-50%, -15vh, 0); }
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0, 0); } }

@keyframes fade-in-bottom {
  0%, 33% {
    opacity: 0;
    transform: translate3d(0, 15vh, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes fade-in-bottom-fw {
  0%, 33% {
    opacity: 0;
    transform: translate3d(-50%, 15vh, 0); }
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0, 0); } }

@keyframes fade-in-bottom-special {
  0%, 33% {
    opacity: 0;
    transform: translate3d(-50%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(-50%, -50%, 0); } }

.embed-responsive {
  position: relative;
  width: 100%;
  height: 0; }
  .embed-responsive-1by1 {
    padding-bottom: 100%; }
  .embed-responsive-3by2 {
    padding-bottom: 66.66666%; }
  .embed-responsive-16by9 {
    padding-bottom: 56.25%; }
  .embed-responsive > * {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .embed-responsive > iframe {
    border: none; }

.is-hidden {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  word-break: break-all;
  hyphens: auto;
  word-break: break-all; }
  @supports (hyphens: auto) {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      word-break: normal; } }
  @media (min-width: 992px) {
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      word-break: normal; } }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
  .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
    color: inherit;
    text-decoration: none; }
    h1 a:hover, h1 a:focus, h2 a:hover, h2 a:focus, h3 a:hover, h3 a:focus, h4 a:hover, h4 a:focus, h5 a:hover, h5 a:focus, h6 a:hover, h6 a:focus,
    .h1 a:hover,
    .h1 a:focus, .h2 a:hover, .h2 a:focus, .h3 a:hover, .h3 a:focus, .h4 a:hover, .h4 a:focus, .h5 a:hover, .h5 a:focus, .h6 a:hover, .h6 a:focus {
      color: inherit;
      text-decoration: none; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
  .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
    display: block;
    margin: 0;
    font-size: .8em;
    line-height: 1.4;
    font-weight: 300;
    hyphens: none;
    word-break: normal; }
    @media (max-width: 767.98px) {
      h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
      .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
        font-size: 17px; } }
    h1 small .icon, h2 small .icon, h3 small .icon, h4 small .icon, h5 small .icon, h6 small .icon,
    .h1 small .icon, .h2 small .icon, .h3 small .icon, .h4 small .icon, .h5 small .icon, .h6 small .icon {
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%);
      transform: translateY(-0.1em); }

h1.super, h2.super, h3.super,
.h1.super, .h2.super, .h3.super {
  line-height: 1.1875; }

h1 small, h2 small, h3 small,
.h1 small, .h2 small, .h3 small {
  font-size: 16px; }
  @media screen and (min-width: 768px) {
    h1 small, h2 small, h3 small,
    .h1 small, .h2 small, .h3 small {
      font-size: calc(16px + 9 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    h1 small, h2 small, h3 small,
    .h1 small, .h2 small, .h3 small {
      font-size: 25px; } }

h1, .h1 {
  line-height: 1.083333;
  font-weight: 300; }
  h1, .h1 {
    font-size: 35px; }
    @media screen and (min-width: 768px) {
      h1, .h1 {
        font-size: calc(35px + 25 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      h1, .h1 {
        font-size: 60px; } }
  h1.super, .h1.super {
    font-size: 35px; }
    @media screen and (min-width: 768px) {
      h1.super, .h1.super {
        font-size: calc(35px + 45 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      h1.super, .h1.super {
        font-size: 80px; } }

h2, .h2 {
  line-height: 1.1; }
  h2, .h2 {
    font-size: 30px; }
    @media screen and (min-width: 768px) {
      h2, .h2 {
        font-size: calc(30px + 20 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      h2, .h2 {
        font-size: 50px; } }
  h2.super, .h2.super {
    font-size: 30px; }
    @media screen and (min-width: 768px) {
      h2.super, .h2.super {
        font-size: calc(30px + 50 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      h2.super, .h2.super {
        font-size: 80px; } }

h3, .h3 {
  line-height: 1.16666;
  font-weight: 500; }
  h3, .h3 {
    font-size: 25px; }
    @media screen and (min-width: 768px) {
      h3, .h3 {
        font-size: calc(25px + 11 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      h3, .h3 {
        font-size: 36px; } }
  h3.super, .h3.super {
    font-size: 30px; }
    @media screen and (min-width: 768px) {
      h3.super, .h3.super {
        font-size: calc(30px + 50 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      h3.super, .h3.super {
        font-size: 80px; } }
  @media (max-width: 767.98px) {
    h3, .h3 {
      font-weight: 600; } }

h4, .h4 {
  line-height: 1.17308;
  font-weight: 600; }
  h4, .h4 {
    font-size: 18px; }
    @media screen and (min-width: 768px) {
      h4, .h4 {
        font-size: calc(18px + 8 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      h4, .h4 {
        font-size: 26px; } }

h5, .h5 {
  line-height: 1.173;
  font-weight: 500; }
  h5, .h5 {
    font-size: 17px; }
    @media screen and (min-width: 768px) {
      h5, .h5 {
        font-size: calc(17px + 3 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      h5, .h5 {
        font-size: 20px; } }

h6, .h6 {
  font-weight: 500; }
  h6, .h6 {
    font-size: 17px; }
    @media screen and (min-width: 768px) {
      h6, .h6 {
        font-size: calc(17px + 0 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      h6, .h6 {
        font-size: 17px; } }

hr {
  display: block;
  position: relative;
  color: #004563;
  margin-top: 1.76471rem;
  margin-bottom: 1.76471rem;
  height: 1px;
  background: #004563;
  opacity: .2;
  border: none; }
  hr:before {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 15px;
    height: 10px;
    transform: translate(-50%, -50%);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.12985 10.8018L15.1515 6.12936C15.2569 6.0604 15.2569 5.90522 15.1515 5.83626L8.12985 1.12074C8.07309 1.08626 8.00822 1.08626 7.95958 1.12074L0.897413 5.83626C0.792008 5.90522 0.792008 6.0604 0.897413 6.12936L7.95958 10.8018C8.01633 10.8363 8.0812 10.8363 8.12985 10.8018Z' stroke='%23004563' fill='%23fff'/%3E%3C/svg%3E%0A");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center; }
  @media (min-width: 992px) {
    hr {
      margin-top: 3.52941rem;
      margin-bottom: 3.52941rem; } }

p {
  margin-bottom: 1em; }

.lead {
  margin-bottom: 1.2em; }
  .lead {
    font-size: 16px; }
    @media screen and (min-width: 768px) {
      .lead {
        font-size: calc(16px + 9 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .lead {
        font-size: 25px; } }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }

/*** IMPORT LAYOUT ***/
html {
  scroll-behavior: smooth;
  scroll-padding-top: 120px; }
  @media (max-width: 767.98px) {
    html {
      scroll-padding-top: 100px; } }

body {
  overflow-x: hidden;
  width: 100%; }
  @media (max-width: 767.98px) {
    .offcanvas-nav--visible body {
      overflow-y: hidden; } }

::selection {
  background: #FF9C59;
  color: #fff; }

.page-header {
  position: relative;
  padding-top: 100px; }
  @media (max-width: 767.98px) {
    .page-header {
      padding-top: 80px; } }
  .frontpage .page-header {
    padding-top: 0; }
    @media (max-width: 767.98px) {
      .frontpage .page-header {
        margin-bottom: 70px; } }
  .page-header--top {
    display: flex; }
    @media (max-width: 1199.98px) {
      .page-header--top {
        padding-right: 60px; } }
    .page-header--top, .header-top--fixed .frontpage .page-header--top {
      flex-wrap: nowrap;
      position: fixed;
      left: 0;
      top: 0;
      padding: 0 0 0 50px;
      width: 100%;
      height: 100px;
      background: #fff;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      z-index: 999;
      align-items: center;
      justify-items: stretch;
      justify-content: space-between; }
      @media (max-width: 767.98px) {
        .page-header--top, .header-top--fixed .frontpage .page-header--top {
          padding: 0 25px;
          height: 80px;
          justify-content: center; } }
    @media (min-width: 1200px) {
      html:not(.header-top--fixed) .frontpage .page-header--top {
        position: absolute;
        left: auto;
        top: 30px;
        padding: 0 0 140px;
        right: 30px;
        bottom: 30px;
        width: 370px;
        height: auto;
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(20px);
        box-shadow: none;
        flex-wrap: wrap;
        align-items: stretch;
        align-content: stretch;
        justify-items: normal;
        justify-content: normal; } }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      html:not(.header-top--fixed) .frontpage .page-header--top {
        top: 60px;
        left: 60px;
        right: 60px;
        padding: 0 30px;
        width: auto; } }
    @media (max-width: 767.98px) {
      html:not(.header-top--fixed) .frontpage .page-header--top {
        left: 15px;
        top: 15px;
        right: 15px;
        height: 90px;
        width: auto; } }
    @media (max-width: 1199.98px) {
      .frontpage .page-header--top {
        transition: all .3s;
        width: auto; } }
    html.header-top--fixed .frontpage .page-header--top {
      transform: translateY(-100%);
      left: 0;
      top: 0;
      right: 0; }
      @media (max-width: 1199.98px) {
        html.header-top--fixed .frontpage .page-header--top {
          transform: none;
          width: auto; } }
      @media (min-width: 768px) and (max-width: 1199.98px) {
        html.header-top--fixed .frontpage .page-header--top {
          padding: 0 60px 0 50px; } }
    .landingpage .page-header--top {
      justify-content: center; }
    @media (min-width: 1200px) {
      html.header-top--fixed .frontpage .page-header--top.is-visible {
        transition: transform .3s;
        transform: translateY(0); } }
    @media (max-width: 767.98px) {
      .page-header--top .main-nav--toggle {
        position: absolute;
        right: 25px; } }
  .page-header--logo {
    flex: 0 0 auto;
    width: 148px; }
    @media (max-width: 767.98px) {
      .page-header--logo {
        width: 108px; } }
    @media (min-width: 1200px) {
      html:not(.header-top--fixed) .frontpage .page-header--logo {
        align-self: flex-start;
        flex: 0 0 100%;
        width: 100%;
        padding: 65px 0;
        background: #fff; }
        html:not(.header-top--fixed) .frontpage .page-header--logo .logo {
          margin: 0 auto; } }
  .page-header--buttons {
    display: flex;
    position: relative; }
    @media (max-width: 1599.98px) {
      .page-header--buttons {
        display: none; } }
    html:not(.header-top--fixed) .frontpage .page-header--buttons {
      position: absolute;
      bottom: 40px;
      right: 40px; }
    @media (min-width: 768px) and (max-width: 1599.98px) {
      .frontpage .page-header--buttons,
      html:not(.header-top--fixed) .frontpage .page-header--buttons {
        display: flex; } }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .frontpage .page-header--buttons,
      html:not(.header-top--fixed) .frontpage .page-header--buttons {
        position: relative;
        bottom: auto;
        right: auto; } }
    @media (min-width: 1200px) and (max-width: 1599.98px) {
      html.header-top--fixed .frontpage .page-header--buttons {
        display: none; } }
    .page-header--buttons--popup {
      position: absolute;
      left: 1px;
      top: 100%;
      padding: 60px 60px 50px;
      width: 100%;
      max-height: calc(100vh - 100px);
      background: #004563;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      overflow: visible;
      overflow-y: auto;
      transition: opacity .3s, visibility .3s; }
      @media (max-width: 991.98px) {
        .page-header--buttons--popup {
          padding: 30px; } }
      @media (max-width: 767.98px) {
        .page-header--buttons--popup {
          left: 0;
          height: calc(100vh - 150px); } }
      html:not(.header-top--fixed) .frontpage .page-header--buttons--popup {
        left: 0; }
        @media (min-width: 1200px) {
          html:not(.header-top--fixed) .frontpage .page-header--buttons--popup {
            top: auto;
            bottom: 100px;
            max-height: calc(100vh - 170px);
            min-height: 530px; } }
  @media (min-width: 1200px) and (min-height: 800px) {
    html:not(.header-top--fixed) .frontpage .page-header--buttons--popup {
      min-height: 635px; } }
        @media (min-width: 768px) and (max-width: 1199.98px) {
          html:not(.header-top--fixed) .frontpage .page-header--buttons--popup {
            max-height: calc(100vh - 160px); } }
        @media (max-width: 767.98px) {
          html:not(.header-top--fixed) .frontpage .page-header--buttons--popup {
            height: calc(100vh - 190px);
            min-height: 380px; } }
      .page-header--buttons--popup .area {
        margin: 0 0 20px 0; }
        .page-header--buttons--popup .area:last-child {
          margin-bottom: 0; }
    .page-header--buttons input[name^="page-header--button"] {
      display: none; }
      .page-header--buttons input[name^="page-header--button"]:checked ~ a, html:not(.header-top--fixed) .frontpage .page-header--buttons input[name^="page-header--button"]:checked ~ a, .page-header--buttons input[name^="page-header--button"]:checked ~ label, html:not(.header-top--fixed) .frontpage .page-header--buttons input[name^="page-header--button"]:checked ~ label, .page-header--buttons input[name^="page-header--button"]:checked ~ button, html:not(.header-top--fixed) .frontpage .page-header--buttons input[name^="page-header--button"]:checked ~ button {
        background-color: #004563;
        color: #fff; }
      .page-header--buttons input[name^="page-header--button"]:checked ~ a .icon, .page-header--buttons input[name^="page-header--button"]:checked ~ label .icon, .page-header--buttons input[name^="page-header--button"]:checked ~ button .icon {
        filter: saturate(0) brightness(0) invert(100%) opacity(1); }
        html:not(.header-top--fixed) .frontpage .page-header--buttons input[name^="page-header--button"]:checked ~ a .icon, html:not(.header-top--fixed) .frontpage .page-header--buttons input[name^="page-header--button"]:checked ~ label .icon, html:not(.header-top--fixed) .frontpage .page-header--buttons input[name^="page-header--button"]:checked ~ button .icon {
          filter: invert(66%) sepia(62%) saturate(557%) hue-rotate(327deg) brightness(100%) contrast(107%); }
      .page-header--buttons input[name^="page-header--button"]:checked ~ .page-header--buttons--popup {
        opacity: 1;
        visibility: visible; }
    .page-header--buttons--close {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 60px;
      height: 60px;
      cursor: pointer;
      opacity: .4;
      transition: opacity .3s; }
      .page-header--buttons--close:hover {
        opacity: 1; }
      .page-header--buttons--close:before, .page-header--buttons--close:after {
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 15px;
        height: 1px;
        background: #fff; }
      .page-header--buttons--close:before {
        transform: translateX(-50%) rotateZ(-45deg); }
      .page-header--buttons--close:after {
        transform: translateX(-50%) rotateZ(45deg); }
    .page-header--buttons--content {
      position: relative;
      z-index: 1; }
      .page-header--buttons--content .area--text table {
        margin-bottom: 1em; }
        .page-header--buttons--content .area--text table:last-child {
          margin-bottom: 0; }
        .page-header--buttons--content .area--text table thead tr th,
        .page-header--buttons--content .area--text table thead tr:first-child th,
        .page-header--buttons--content .area--text table thead tr:not(:first-child) th {
          padding: 0 0 .5em;
          line-height: 1.173;
          font-weight: 500;
          text-transform: none; }
          .page-header--buttons--content .area--text table thead tr th,
          .page-header--buttons--content .area--text table thead tr:first-child th,
          .page-header--buttons--content .area--text table thead tr:not(:first-child) th {
            font-size: 17px; }
            @media screen and (min-width: 768px) {
              .page-header--buttons--content .area--text table thead tr th,
              .page-header--buttons--content .area--text table thead tr:first-child th,
              .page-header--buttons--content .area--text table thead tr:not(:first-child) th {
                font-size: calc(17px + 3 * ((100vw - 768px) / 432)); } }
            @media screen and (min-width: 1200px) {
              .page-header--buttons--content .area--text table thead tr th,
              .page-header--buttons--content .area--text table thead tr:first-child th,
              .page-header--buttons--content .area--text table thead tr:not(:first-child) th {
                font-size: 20px; } }
        .page-header--buttons--content .area--text table th, .page-header--buttons--content .area--text table td {
          padding: 0 5px;
          border: none; }
          .page-header--buttons--content .area--text table th:first-child, .page-header--buttons--content .area--text table td:first-child {
            padding-left: 0; }
          .page-header--buttons--content .area--text table th:last-child, .page-header--buttons--content .area--text table td:last-child {
            padding-right: 0; }
      .page-header--buttons--content .area--media {
        width: auto;
        margin-left: -60px;
        margin-right: -60px;
        margin-bottom: 40px; }
        @media (max-width: 991.98px) {
          .page-header--buttons--content .area--media {
            margin-left: -30px;
            margin-right: -30px; } }
        @media (max-width: 991.98px) {
          .page-header--buttons--content .area--media:first-child {
            margin-top: 30px; } }
      .page-header--buttons--content .infobox {
        margin-bottom: 30px; }
        .page-header--buttons--content .infobox:not(.infobox-primary) {
          border-color: rgba(242, 246, 247, 0.2); }
          .page-header--buttons--content .infobox:not(.infobox-primary) a:not(.btn) {
            color: #fff; }
            .page-header--buttons--content .infobox:not(.infobox-primary) a:not(.btn):hover {
              color: #004563; }
            .page-header--buttons--content .infobox:not(.infobox-primary) a:not(.btn):after {
              background-color: #fff; }
      .page-header--buttons--content .btn {
        display: block;
        margin-bottom: 20px;
        width: 100%; }
        .page-header--buttons--content .btn:last-child {
          margin-bottom: 0; }
        .page-header--buttons--content .btn:after {
          opacity: .2; }
  .page-header--button > a, .page-header--button > button, .page-header--button > label {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    height: 100px;
    width: 110px;
    border-left: 1px solid #E5ECEF;
    text-align: center;
    cursor: pointer;
    transition: color .3s, background-color .3s; }
    @media (min-width: 768px) and (max-width: 800px) {
      .page-header--button > a, .page-header--button > button, .page-header--button > label {
        width: 100px; } }
    html.header-top--fixed .frontpage .page-header--button > a, html.header-top--fixed .frontpage .page-header--button > button, html.header-top--fixed .frontpage .page-header--button > label {
      transition: none; }
    html.header-top--fixed .frontpage .is-visible .page-header--button > a, html.header-top--fixed .frontpage .is-visible .page-header--button > button, html.header-top--fixed .frontpage .is-visible .page-header--button > label {
      transition: color .3s, background-color .3s; }
    @media (min-width: 1200px) {
      html:not(.header-top--fixed) .frontpage .page-header--button > a, html:not(.header-top--fixed) .frontpage .page-header--button > button, html:not(.header-top--fixed) .frontpage .page-header--button > label {
        flex: 0 0 auto;
        background: #FF9C59;
        color: #fff;
        border-left-color: #E28A4E; } }
    .page-header--button > a:hover, html:not(.header-top--fixed) .frontpage .page-header--button > a:hover, .page-header--button > button:hover, html:not(.header-top--fixed) .frontpage .page-header--button > button:hover, .page-header--button > label:hover, html:not(.header-top--fixed) .frontpage .page-header--button > label:hover {
      background-color: #004563;
      color: #fff; }
    .page-header--button > a:hover .icon, .page-header--button > button:hover .icon, .page-header--button > label:hover .icon {
      filter: saturate(0) brightness(0) invert(100%) opacity(1); }
      @media (min-width: 1200px) {
        html:not(.header-top--fixed) .frontpage .page-header--button > a:hover .icon, html:not(.header-top--fixed) .frontpage .page-header--button > button:hover .icon, html:not(.header-top--fixed) .frontpage .page-header--button > label:hover .icon {
          filter: invert(66%) sepia(62%) saturate(557%) hue-rotate(327deg) brightness(100%) contrast(107%); } }
    .page-header--button > a .icon, .page-header--button > button .icon, .page-header--button > label .icon {
      position: relative;
      margin-bottom: 10px;
      font-size: 25px;
      filter: invert(66%) sepia(62%) saturate(557%) hue-rotate(327deg) brightness(100%) contrast(107%);
      transition: filter .3s;
      z-index: 1; }
      @media (min-width: 1200px) {
        html:not(.header-top--fixed) .frontpage .page-header--button > a .icon, html:not(.header-top--fixed) .frontpage .page-header--button > button .icon, html:not(.header-top--fixed) .frontpage .page-header--button > label .icon {
          filter: saturate(0) brightness(0) invert(100%) opacity(1); } }
    .page-header--button > a span, .page-header--button > button span, .page-header--button > label span {
      position: relative;
      display: block;
      width: 100%;
      flex: 0 0 100%;
      font-size: 12px;
      line-height: 1;
      font-weight: 500;
      z-index: 1; }

@keyframes bubbles {
  100% {
    background-position: 99% -15%, 10% 32%, 84% -120%, 59% -182%, 56% -5%, -4% -142%, -2% -166%, 83% -167%, 73% -110%, 56% -176%, 52% -167%;
    opacity: 0; } }
    .page-header--button > a:before, .page-header--button > button:before, .page-header--button > label:before {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      pointer-events: none;
      opacity: .3;
      background: none no-repeat transparent; }
    .page-header--button > a:hover:before, .page-header--button > button:hover:before, .page-header--button > label:hover:before {
      animation: bubbles 1s forwards ease-out;
      background: radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) 97% 95% / 1.08em 1.08em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) 14% 104% / 0.52em 0.52em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) 81% 83% / 0.67em 0.67em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) 56% 103% / 1.17em 1.17em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) 54% 84% / 0.93em 0.93em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) -8% 149% / 0.81em 0.81em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) -1% 134% / 0.53em 0.53em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) 78% 104% / 1.06em 1.06em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) 63% 133% / 0.62em 0.62em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) 49% 82% / 0.51em 0.51em, radial-gradient(circle at center, transparent 30%, #eef 60%, #eef 65%, transparent 70%) 55% 113% / 0.57em 0.57em;
      background-repeat: no-repeat; }
  html:not(.header-top--fixed) .frontpage .page-header--button:first-child > a, html:not(.header-top--fixed) .frontpage .page-header--button:first-child > button, html:not(.header-top--fixed) .frontpage .page-header--button:first-child > label {
    border: none; }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    html.header-top--fixed .frontpage .page-header--button:first-child > a, html.header-top--fixed .frontpage .page-header--button:first-child > button, html.header-top--fixed .frontpage .page-header--button:first-child > label {
      border: none; } }
  @media (max-width: 1199.98px) {
    .page-header > .lang-nav {
      display: none; } }
  .page-header--hint {
    position: absolute;
    right: 50%;
    top: 200px;
    width: 80px;
    height: 160px;
    transform: translateX(570px);
    background: #004563;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.16667;
    text-align: center;
    overflow: hidden;
    z-index: 1; }
    @media (max-width: 1399.98px) {
      .page-header--hint {
        transform: none;
        right: 120px; } }
    @media (max-width: 767.98px) {
      .page-header--hint {
        top: 125px;
        right: 15px;
        width: 60px;
        height: 120px; } }
    .page-header--hint > * {
      display: flex;
      width: 100%;
      height: 50%;
      align-content: center;
      align-items: center;
      justify-content: center; }
    .page-header--hint > span {
      text-transform: uppercase; }
      @media (max-width: 767.98px) {
        .page-header--hint > span {
          font-size: 9px;
          line-height: 1.22222; } }
    .page-header--hint--icon {
      display: flex;
      flex-wrap: wrap;
      background: #FF9C59; }
      .page-header--hint--icon .icon {
        display: block;
        margin-bottom: 5px;
        width: 30px;
        height: 30px;
        filter: saturate(0) brightness(0) invert(100%) opacity(1); }
        @media (max-width: 767.98px) {
          .page-header--hint--icon .icon {
            width: 23px;
            height: 23px; } }
      .page-header--hint--icon span {
        display: block;
        width: 100%; }
  .page-header--slogan {
    position: absolute;
    left: 50%;
    bottom: 30px;
    width: 182px;
    transform: translateX(-50%);
    z-index: 2; }
    @media (max-width: 767.98px) {
      .page-header--slogan {
        bottom: 15px;
        width: 128px; } }
    .frontpage .page-header--slogan {
      bottom: 60px; }
      @media (min-width: 1200px) {
        .frontpage .page-header--slogan {
          left: 60px;
          transform: translateX(0); } }
      @media (max-width: 767.98px) {
        .frontpage .page-header--slogan {
          bottom: 15px; } }
    .page-header--slogan:before, .page-header--slogan:after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      width: calc(50vw - 91px);
      transform: translateY(-1px);
      border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
      @media (max-width: 767.98px) {
        .page-header--slogan:before, .page-header--slogan:after {
          width: calc(50vw - 64px); } }
    .page-header--slogan:before {
      right: 100%; }
      @media (min-width: 1200px) {
        .frontpage .page-header--slogan:before {
          width: 60px; } }
    .page-header--slogan:after {
      left: 100%; }
      @media (min-width: 1200px) {
        .frontpage .page-header--slogan:after {
          width: calc(100vw - 60px - 182px); } }
    .page-header--slogan .rhombus {
      color: #fff;
      filter: saturate(0) brightness(0) invert(100%) opacity(1); }
      @media (max-width: 767.98px) {
        .page-header--slogan .rhombus {
          font-size: 11px; } }
  .page-header--meta {
    display: flex;
    position: absolute;
    bottom: 45px;
    z-index: 2; }
    .page-header--meta {
      left: 200px; }
      @media screen and (min-width: 1200px) {
        .page-header--meta {
          left: calc(200px + 100 * ((100vw - 1200px) / 400)); } }
      @media screen and (min-width: 1600px) {
        .page-header--meta {
          left: 300px; } }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .page-header--meta {
        left: 0;
        right: 0;
        justify-content: center; } }
    @media (max-width: 767.98px) {
      .page-header--meta {
        left: 15px;
        right: 15px;
        bottom: 15px;
        justify-content: center; } }
    .page-header--meta a {
      display: block;
      margin-right: 60px;
      text-decoration: none;
      color: #fff;
      font-size: 17px;
      line-height: 1.64;
      transition: opacity .3s; }
      @media (min-width: 768px) and (max-width: 1199.98px) {
        .page-header--meta a {
          margin-right: 250px; } }
      @media (max-width: 767.98px) {
        .page-header--meta a {
          margin-right: 230px; } }
      .page-header--meta a:last-child {
        margin-right: 0; }
      .page-header--meta a:hover {
        opacity: .65; }
      .page-header--meta a .icon {
        margin-right: 10px;
        filter: saturate(0) brightness(0) invert(100%) opacity(1);
        transform: translateY(-1px);
        font-size: 20px; }
      @media (max-width: 767.98px) {
        .page-header--meta a span {
          display: none; } }
  .frontpage .page-header > .page-header--buttons,
  html.header-top--fixed .frontpage .page-header > .page-header--buttons,
  html:not(.header-top--fixed) .frontpage .page-header > .page-header--buttons {
    display: none; }
    @media (max-width: 767.98px) {
      .frontpage .page-header > .page-header--buttons,
      html.header-top--fixed .frontpage .page-header > .page-header--buttons,
      html:not(.header-top--fixed) .frontpage .page-header > .page-header--buttons {
        display: flex;
        z-index: 5;
        transition: transform .3s; } }
  @media (max-width: 767.98px) {
    .frontpage .page-header > .page-header--buttons,
    html:not(.header-top--fixed) .frontpage .page-header > .page-header--buttons {
      bottom: 0;
      right: 0;
      left: 0;
      transform: translateY(100%); } }
  html.header-top--fixed .frontpage .page-header > .page-header--buttons {
    position: fixed;
    transform: translateY(0); }
  @media (max-width: 767.98px) {
    .page-header > .page-header--buttons .page-header--button {
      flex: 0 0 25%; }
      .page-header > .page-header--buttons .page-header--button > a, .page-header > .page-header--buttons .page-header--button > label, .page-header > .page-header--buttons .page-header--button > button {
        height: 70px;
        width: 100%;
        color: #fff;
        background: #FF9C59;
        border-left-color: #E28A4E; }
        .page-header > .page-header--buttons .page-header--button > a .icon, .page-header > .page-header--buttons .page-header--button > label .icon, .page-header > .page-header--buttons .page-header--button > button .icon {
          width: 20px;
          height: 20px;
          filter: saturate(0) brightness(0) invert(100%) opacity(1); }
        .page-header > .page-header--buttons .page-header--button > a span, .page-header > .page-header--buttons .page-header--button > label span, .page-header > .page-header--buttons .page-header--button > button span {
          font-size: 11px; } }
  @media (max-width: 767.98px) {
    .page-header > .page-header--buttons .page-header--buttons--popup {
      top: auto;
      bottom: 100%; } }

html.js .frontpage .page-header:before {
  display: block;
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  border: 10px solid #004563;
  border-top-color: transparent;
  border-radius: 100%;
  transform-origin: 0 0;
  transition: opacity .3s;
  opacity: 1;
  pointer-events: none;
  animation: spin 1s linear infinite; }

html.js .frontpage .page-header--top {
  opacity: 0;
  transition: opacity .5s, transform .5s;
  transition-delay: .5s; }
  @media (min-width: 1200px) {
    html.js .frontpage .page-header--top {
      transform: translateX(30%); } }

html.js .frontpage .page-header .lang-nav {
  opacity: 0;
  transform: translateX(-30%);
  transition: opacity .5s, transform .5s;
  transition-delay: .25s; }

html.js .frontpage .page-header .header-media {
  opacity: 0;
  transition: opacity .5s; }
  html.js .frontpage .page-header .header-media--content {
    transition-delay: 1s; }

@media (max-width: 1199.98px) {
  html.js .frontpage .page-header--slogan {
    transform: translate(-50%, -50%); } }

html.js .frontpage .page-header--meta {
  opacity: 0;
  transition: opacity .5s;
  transition-delay: .75s; }

html.js .frontpage .page-header.loaded:before {
  opacity: 0; }

html.js .frontpage .page-header.loaded .page-header--top {
  opacity: 1; }
  @media (min-width: 1200px) {
    html.js .frontpage .page-header.loaded .page-header--top {
      transform: none; } }

html.js .frontpage .page-header.loaded .lang-nav {
  opacity: 1;
  transform: none; }

html.js .frontpage .page-header.loaded .header-media {
  opacity: 1; }

@media (min-width: 1200px) {
  html.js .frontpage .page-header.loaded .page-header--slogan {
    transform: translate(0, 0); } }

html.js .frontpage .page-header.loaded .page-header--meta {
  opacity: 1; }

html.js .page-header .header-media--content {
  opacity: 0;
  transition: opacity .5s; }

html.js .page-header--slogan {
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: opacity .5s, transform .5s;
  transition-delay: .75s; }

html.js .page-header.loaded .header-media--content {
  opacity: 1; }

html.js .page-header.loaded .page-header--slogan {
  opacity: 1;
  transform: translate(-50%, 0); }

html.js .subpage .page-header .header-media--content {
  transition-delay: 0s; }

.header-media {
  position: relative;
  height: 60vh;
  min-height: 250px;
  overflow: hidden; }
  @media (max-width: 767.98px) {
    .header-media {
      height: 52.5vh; } }
  .frontpage .header-media {
    height: 100vh;
    min-height: 700px; }
    @media (max-width: 1199.98px) {
      .frontpage .header-media {
        min-height: 500px; } }
    @media (max-width: 767.98px) {
      .frontpage .header-media {
        height: calc(100vh - 70px); } }
  .landingpage .header-media {
    height: calc(100vh - 174px);
    min-height: 500px; }
    @media (max-width: 767.98px) {
      .landingpage .header-media {
        height: calc(100vh - 140px);
        min-height: 350px; } }
  .header-media:before {
    display: block;
    content: '';
    position: absolute;
    left: 60px;
    right: 60px;
    bottom: 0;
    height: calc(50% - 60px);
    background: linear-gradient(to bottom, rgba(0, 69, 99, 0), #004563);
    opacity: .8;
    pointer-events: none;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .header-media:before {
        left: 0;
        right: 0;
        height: 50%; } }
    .frontpage .header-media:before,
    .landingpage .header-media:before {
      left: 0;
      right: 0;
      bottom: 0;
      height: 50%; }
  .header-media > img,
  .header-media > picture > img,
  .header-media > video,
  .header-media > iframe {
    position: absolute;
    top: 60px;
    left: 60px;
    width: calc(100% - 120px);
    height: calc(100% - 60px); }
    @media (max-width: 767.98px) {
      .header-media > img,
      .header-media > picture > img,
      .header-media > video,
      .header-media > iframe {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
    .frontpage .header-media > img,
    .landingpage .header-media > img, .frontpage
    .header-media > picture > img,
    .landingpage
    .header-media > picture > img, .frontpage
    .header-media > video,
    .landingpage
    .header-media > video, .frontpage
    .header-media > iframe,
    .landingpage
    .header-media > iframe {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .header-media > img,
  .header-media > picture > img,
  .header-media > video {
    object-fit: cover; }
  .header-media--content {
    position: absolute;
    left: 120px;
    right: 120px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: #fff;
    z-index: 2; }
    @media (max-width: 767.98px) {
      .header-media--content {
        left: 15px;
        right: 15px; } }
    .frontpage .header-media--content {
      right: 530px;
      top: auto;
      bottom: 160px;
      transform: none;
      text-align: left; }
      .frontpage .header-media--content {
        left: 200px; }
        @media screen and (min-width: 1200px) {
          .frontpage .header-media--content {
            left: calc(200px + 100 * ((100vw - 1200px) / 400)); } }
        @media screen and (min-width: 1600px) {
          .frontpage .header-media--content {
            left: 300px; } }
      @media (min-width: 768px) and (max-width: 1199.98px) {
        .frontpage .header-media--content {
          left: 60px;
          right: 60px;
          bottom: 210px; } }
      @media (max-width: 767.98px) {
        .frontpage .header-media--content {
          left: 15px;
          right: 15px;
          bottom: 110px; } }
    .landingpage .header-media--content {
      left: 60px;
      right: 60px; }
      @media (max-width: 767.98px) {
        .landingpage .header-media--content {
          left: 15px;
          right: 15px; } }
    .header-media--content > p {
      font-weight: 500; }
      .landingpage .header-media--content > p {
        line-height: 1.1875; }
        .landingpage .header-media--content > p {
          font-size: 35px; }
          @media screen and (min-width: 768px) {
            .landingpage .header-media--content > p {
              font-size: calc(35px + 45 * ((100vw - 768px) / 432)); } }
          @media screen and (min-width: 1200px) {
            .landingpage .header-media--content > p {
              font-size: 80px; } }
    .header-media--content > span {
      display: block;
      text-transform: uppercase;
      font-weight: 500; }
      .landingpage .header-media--content > span {
        line-height: 1.15; }
        .landingpage .header-media--content > span {
          font-size: 20px; }
          @media screen and (min-width: 768px) {
            .landingpage .header-media--content > span {
              font-size: calc(20px + 6 * ((100vw - 768px) / 432)); } }
          @media screen and (min-width: 1200px) {
            .landingpage .header-media--content > span {
              font-size: 26px; } }
    .header-media--content > .btn {
      margin-top: 30px;
      white-space: nowrap; }
      @media (max-width: 767.98px) {
        .header-media--content > .btn {
          font-size: 18px; } }
      .landingpage .header-media--content > .btn {
        font-size: 25px; }
        @media (max-width: 767.98px) {
          .landingpage .header-media--content > .btn {
            font-size: 20px; } }
      .header-media--content > .btn:after {
        opacity: .2; }
    .header-media--content--item {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      line-height: 1.16666;
      font-weight: 500;
      color: #fff;
      overflow: hidden;
      z-index: 1; }
      .header-media--content--item {
        font-size: 25px; }
        @media screen and (min-width: 768px) {
          .header-media--content--item {
            font-size: calc(25px + 11 * ((100vw - 768px) / 432)); } }
        @media screen and (min-width: 1200px) {
          .header-media--content--item {
            font-size: 36px; } }
      @media (max-width: 767.98px) {
        .header-media--content--item {
          font-size: 18px;
          line-height: 1.27778;
          font-weight: 600; } }
      .header-media--content--item.is-active {
        z-index: 5; }
      .header-media--content--item a, .header-media--content--item a:hover, .header-media--content--item a:focus {
        text-decoration: none;
        color: #fff; }
      .header-media--content--item--inner {
        position: relative;
        padding-left: 1.38889em;
        opacity: 0;
        transform: translateY(100%);
        transition: transform .5s, opacity .5s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
        @media (max-width: 767.98px) {
          .header-media--content--item--inner {
            padding-left: 32px; } }
        .is-active .header-media--content--item--inner {
          opacity: 1;
          transform: translateY(0); }
        .is-active.transition-out .header-media--content--item--inner {
          opacity: 0;
          transform: translateY(-100%); }
        .header-media--content--item--inner:before {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          top: 0.22222em;
          border-left: 0.55556em solid #FF9C59;
          border-top: 0.36111em solid transparent;
          border-bottom: 0.36111em solid transparent; }
          @media (max-width: 767.98px) {
            .header-media--content--item--inner:before {
              top: 1px;
              border-left-width: 17px;
              border-top-width: 11px;
              border-bottom-width: 11px; } }
        .header-media--content--item--inner > *:last-child {
          margin-bottom: 0;
          padding-bottom: 0; }
    .header-media--content.bottom-left {
      text-align: left;
      top: auto;
      transform: translate(0, 0); }
      .header-media--content.bottom-left {
        left: 100px; }
        @media screen and (min-width: 768px) {
          .header-media--content.bottom-left {
            left: calc(100px + 40 * ((100vw - 768px) / 432)); } }
        @media screen and (min-width: 1200px) {
          .header-media--content.bottom-left {
            left: 140px; } }
      .header-media--content.bottom-left {
        bottom: 40px; }
        @media screen and (min-width: 768px) {
          .header-media--content.bottom-left {
            bottom: calc(40px + 40 * ((100vw - 768px) / 432)); } }
        @media screen and (min-width: 1200px) {
          .header-media--content.bottom-left {
            bottom: 80px; } }
      @media (max-width: 767.98px) {
        .header-media--content.bottom-left {
          left: 15px;
          bottom: 30px; } }
      .header-media--content.bottom-left h1, .header-media--content.bottom-left .h1,
      .header-media--content.bottom-left h2, .header-media--content.bottom-left .h2,
      .header-media--content.bottom-left h3, .header-media--content.bottom-left .h3 {
        position: relative; }
        .header-media--content.bottom-left h1:before, .header-media--content.bottom-left .h1:before,
        .header-media--content.bottom-left h2:before, .header-media--content.bottom-left .h2:before,
        .header-media--content.bottom-left h3:before, .header-media--content.bottom-left .h3:before {
          display: block;
          content: '';
          position: absolute;
          left: 0; }
      .header-media--content.bottom-left h1, .header-media--content.bottom-left .h1 {
        padding-left: 0.83333em; }
        .header-media--content.bottom-left h1:before, .header-media--content.bottom-left .h1:before {
          top: 0.33333em;
          border-left: 0.33333em solid #FF9C59;
          border-top: 0.21667em solid transparent;
          border-bottom: 0.21667em solid transparent; }
      .header-media--content.bottom-left h2, .header-media--content.bottom-left .h2 {
        padding-left: 1em; }
        .header-media--content.bottom-left h2:before, .header-media--content.bottom-left .h2:before {
          top: 0.32em;
          border-left: 0.4em solid #FF9C59;
          border-top: 0.26em solid transparent;
          border-bottom: 0.26em solid transparent; }
      .header-media--content.bottom-left h3, .header-media--content.bottom-left .h3 {
        padding-left: 1.38889em; }
        @media (max-width: 767.98px) {
          .header-media--content.bottom-left h3, .header-media--content.bottom-left .h3 {
            font-size: 18px; } }
        .header-media--content.bottom-left h3:before, .header-media--content.bottom-left .h3:before {
          top: 0.22222em;
          border-left: 0.55556em solid #FF9C59;
          border-top: 0.36111em solid transparent;
          border-bottom: 0.36111em solid transparent; }

.main-content {
  padding-top: 60px;
  padding-bottom: 80px; }
  @media (min-width: 992px) {
    .main-content {
      padding-top: 100px;
      padding-bottom: 130px; } }
  @media (max-width: 767.98px) {
    .main-content {
      padding-top: 40px;
      padding-bottom: 40px; } }
  .frontpage .main-content {
    padding-top: 80px; }
    @media (min-width: 992px) {
      .frontpage .main-content {
        padding-top: 160px; } }
    @media (max-width: 767.98px) {
      .frontpage .main-content {
        padding-bottom: 0; } }
  .main-content > .container {
    max-width: 1260px; }
    @media (max-width: 767.98px) {
      .main-content > .container {
        padding-left: 30px;
        padding-right: 30px; } }
    .main-content > .container > *:first-child {
      margin-top: 0; }
    .main-content > .container > *:last-child {
      margin-bottom: 0; }
  :root {
    --scrollbarWidth: 0px; }

.page-footer {
  background: url(../images/layout/bg-footer.png) center 238px no-repeat #fff;
  background-size: cover; }
  @media (max-width: 767.98px) {
    .page-footer {
      padding-bottom: 70px; } }
  .page-footer .container {
    max-width: 1260px; }
    @media (max-width: 767.98px) {
      .page-footer .container {
        padding-left: 30px;
        padding-right: 30px; } }
  .page-footer--social {
    margin-bottom: 30px;
    padding: 30px 0;
    overflow: hidden;
    text-align: center; }
    .page-footer--social ul {
      display: inline-block;
      position: relative;
      margin: 0;
      padding: 0;
      list-style-type: none; }
      .page-footer--social ul:before, .page-footer--social ul:after {
        display: block;
        content: '';
        position: absolute;
        top: 50%;
        width: 50vw;
        border-bottom: 1px solid #E5ECEF; }
      .page-footer--social ul:before {
        right: 100%;
        transform: translateX(-20px); }
        @media (max-width: 767.98px) {
          .page-footer--social ul:before {
            transform: translateX(-15px); } }
      .page-footer--social ul:after {
        left: 100%;
        transform: translateX(20px); }
        @media (max-width: 767.98px) {
          .page-footer--social ul:after {
            transform: translateX(15px); } }
      .page-footer--social ul > li {
        display: inline-block;
        padding: 0 12px; }
        @media (max-width: 767.98px) {
          .page-footer--social ul > li {
            padding: 0 5px; } }
        .page-footer--social ul > li > a {
          position: relative;
          display: block;
          width: 60px;
          height: 60px;
          background: #fff;
          border-radius: 100%;
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
          transition: background-color .3s; }
          .page-footer--social ul > li > a:hover {
            background: #004563; }
            .page-footer--social ul > li > a:hover .icon {
              filter: saturate(0) brightness(0) invert(100%) opacity(1); }
          .page-footer--social ul > li > a .icon {
            position: absolute;
            left: 50%;
            top: 50%;
            width: 30px;
            height: 30px;
            transform: translate(-50%, -50%);
            transition: filter .3s; }
  .page-footer--teaser .teaser {
    overflow: hidden; }
    @media (max-width: 767.98px) {
      .page-footer--teaser .teaser--content {
        text-align: center; } }
    .page-footer--teaser .teaser--content h4, .page-footer--teaser .teaser--content .h4 {
      margin-bottom: 0; }
    @media (max-width: 767.98px) {
      .page-footer--teaser .teaser--buttons .btn {
        white-space: normal; } }
  .page-footer--main {
    padding: 160px 0 60px; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .page-footer--main {
        padding-top: 100px; } }
    @media (max-width: 767.98px) {
      .page-footer--main {
        padding: 60px 0 40px; } }
    .page-footer--main > .container {
      width: 100%; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .page-footer--main > .container {
          flex-wrap: wrap; } }
      @media (min-width: 768px) {
        .page-footer--main > .container {
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between; } }
    .page-footer--main h5, .page-footer--main .h5 {
      margin-bottom: 1em; }
      @media (max-width: 767.98px) {
        .page-footer--main h5, .page-footer--main .h5 {
          text-align: center; } }
    @media (max-width: 991.98px) {
      .page-footer--main address {
        text-align: center; } }
    .page-footer--main .btn {
      display: block;
      margin-bottom: 20px; }
      .page-footer--main .btn-outline {
        background-color: #f2fafe; }
      .page-footer--main .btn:last-child {
        margin-bottom: 0; }
  .page-footer--col {
    position: relative;
    z-index: 1; }
    @media (min-width: 768px) {
      .page-footer--col {
        padding: 0 15px;
        min-width: 205px; } }
    @media (max-width: 767.98px) {
      .page-footer--col {
        margin-bottom: 60px; } }
    .page-footer--col:first-child {
      padding-left: 0; }
    .page-footer--col:last-child {
      padding-right: 0; }
      @media (max-width: 767.98px) {
        .page-footer--col:last-child {
          margin-bottom: 0; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .page-footer--address {
      flex: 0 0 100%;
      margin-bottom: 60px; } }
  .page-footer--buttons {
    flex: 0 1 380px; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .page-footer--buttons {
        flex: 0 0 50%; } }
  .page-footer--links {
    flex: 0 1 350px; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .page-footer--links {
        flex: 0 0 50%; } }
    .page-footer--links ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      column-count: 2; }
      .page-footer--links ul li {
        position: relative;
        margin-bottom: 15px;
        padding: 0 0 0 22px;
        font-size: 17px;
        line-height: 1.2;
        font-weight: 300; }
        .page-footer--links ul li:before {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          top: 5px;
          border-left: 9px solid #004563;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent; }
        .page-footer--links ul li a {
          display: inline-block;
          transition: color .3s; }
          .page-footer--links ul li a:hover {
            color: #FF9C59; }
  .page-footer--info {
    position: relative;
    margin-top: 65px;
    padding: 8px 15px;
    flex: 0 0 100%;
    text-align: center;
    border: 1px solid #E5ECEF; }
    @media (max-width: 767.98px) {
      .page-footer--info {
        margin-top: 40px;
        padding-left: 20px;
        text-align: left; } }
    .page-footer--info p {
      margin: 0;
      font-size: 12px;
      line-height: 1.16667; }
    .page-footer--info .icon {
      vertical-align: baseline;
      font-size: 11px;
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
      @media (max-width: 767.98px) {
        .page-footer--info .icon {
          position: absolute;
          left: 6px;
          top: 8px; } }
  .page-footer--copyright {
    padding: 50px 0;
    font-size: 17px;
    line-height: 28px;
    white-space: nowrap;
    font-weight: 500;
    background: #fff; }
    @media (max-width: 767.98px) {
      .page-footer--copyright {
        text-align: center;
        font-size: 15px;
        line-height: 25px; } }
    @media (min-width: 768px) {
      .page-footer--copyright > .container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between; } }
    .page-footer--copyright p {
      margin: 0; }
      @media (max-width: 767.98px) {
        .page-footer--copyright p {
          margin-bottom: 30px; } }

.conecto-copyright {
  width: 140px;
  height: 28px;
  background: svg-load("../layout/svg/site_by_CONECTO.svg", fill= "") no-repeat center center transparent;
  background-size: contain;
  overflow: hidden;
  font-size: 0;
  text-indent: -9999em; }
  @media (max-width: 767.98px) {
    .conecto-copyright {
      margin: 0 auto; } }

/*** IMPORT COMPONENTS ***/
.a11y--jump-link {
  background-color: #000;
  color: #fff;
  text-decoration: none;
  margin: 0;
  padding: .5em 1em;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 1000000; }
  .a11y--jump-link:active, .a11y--jump-link:focus {
    display: block;
    width: 100%; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }
  .sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal; }

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 1720px; }
  @media (max-width: 575.98px) {
    .container {
      padding-left: 30px;
      padding-right: 30px; } }
  .container--narrow {
    max-width: 1140px; }
  .container--full-width {
    max-width: none;
    width: 100%; }

.logo {
  display: block;
  width: 100%;
  max-width: 148px;
  background-image: url("../images/layout/svg/logo.svg");
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999em;
  overflow: hidden;
  font-size: 0; }
  .logo:after {
    display: block;
    content: '';
    width: 100%;
    height: 0;
    padding-bottom: 47.97297%; }

.btn {
  position: relative;
  display: inline-block;
  left: 0;
  top: 0;
  margin: 0 5px 5px 0;
  padding: 0.55em 1em;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  color: #004563;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: none;
  transition: background-color 0.3s, color 0.3s, border 0.3s, left 0.3s, top 0.3s; }
  .btn:after {
    position: absolute;
    display: block;
    content: '';
    left: 4px;
    top: 4px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border-radius: 5px;
    background: #E5ECEF;
    transition: left .3s, top .3s;
    z-index: -1; }
    .highlight .btn:after {
      background: rgba(255, 255, 255, 0.15); }
  .btn:hover {
    left: 2px;
    top: 2px;
    color: #004563;
    text-decoration: none; }
    .btn:hover:after {
      top: 0;
      left: 0; }
  .btn:focus {
    outline: 0;
    box-shadow: none; }
  .btn:disabled, .btn.disabled {
    pointer-events: none;
    opacity: 0.4; }
    .btn:disabled:after, .btn.disabled:after {
      display: none; }
  .btn.btn-block {
    display: block; }
  .btn-primary {
    color: #fff;
    background-color: #FF9C59;
    border-color: #FF9C59; }
    .btn-primary:hover {
      color: #FF9C59;
      background-color: #fff;
      border-color: #E5ECEF; }
    .btn-primary .icon {
      filter: saturate(0) brightness(0) invert(100%) opacity(1); }
    .btn-primary:hover .icon {
      filter: invert(66%) sepia(62%) saturate(557%) hue-rotate(327deg) brightness(100%) contrast(107%); }
  .btn-secondary {
    color: #fff;
    background-color: #004563;
    border-color: #fff; }
    .btn-secondary:hover {
      color: #004563;
      background-color: #fff;
      border-color: #E5ECEF; }
    .highlight .btn-secondary {
      background-color: #fff;
      color: #004563; }
    .btn-secondary .icon {
      filter: saturate(0) brightness(0) invert(100%) opacity(1); }
      .highlight .btn-secondary .icon {
        filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
    .highlight .btn-secondary:hover {
      background: #004563;
      color: #fff; }
    .btn-secondary:hover .icon {
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
      .highlight .btn-secondary:hover .icon {
        filter: saturate(0) brightness(0) invert(100%) opacity(1); }
  .btn-outline {
    color: #004563;
    background-color: #fff;
    border-color: #004563; }
    .btn-outline:hover {
      color: #fff;
      background-color: #0B5C7F;
      border-color: #fff; }
    .highlight .btn-outline {
      background-color: #004563;
      color: #fff;
      border-color: #fff; }
    .btn-outline .icon {
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
      .highlight .btn-outline .icon {
        filter: saturate(0) brightness(0) invert(100%) opacity(1); }
    .highlight .btn-outline:hover {
      background-color: #fff;
      color: #004563; }
    .btn-outline:hover .icon {
      filter: saturate(0) brightness(0) invert(100%) opacity(1); }
      .highlight .btn-outline:hover .icon {
        filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
  .btn.btn-lg {
    padding: .64em 1.2em;
    font-size: 25px; }
    .btn.btn-lg.icon-only {
      padding: .64em; }
    .btn.btn-lg.btn:not(.icon-only) .icon--angle-right {
      width: 10px;
      transform: translateY(-2px); }
  .btn.btn-sm {
    font-size: 18px; }
  .btn.icon-only {
    padding: .55em; }
    .btn.icon-only .icon {
      display: block; }
  .btn:not(.icon-only) .icon {
    transform: translateY(-1px); }
  .btn .icon {
    transition: filter .3s; }
    .btn .icon--angle-right {
      width: 7px; }

@media (min-width: 1200px) and (max-width: 1599.98px) {
  .main-nav {
    padding-right: 40px; } }

@media (max-width: 1199.98px) {
  .main-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    padding: 55px 30px 60px;
    max-height: 100vh;
    background: #fff;
    transition: transform .5s;
    overflow: hidden;
    overflow-y: auto;
    z-index: 9999; } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  .main-nav {
    right: 0;
    width: 370px;
    transform: translateX(100%);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15); } }

@media (max-width: 767.98px) {
  .main-nav {
    left: 0;
    width: 100%;
    transform: translateX(-100%); } }

@media (min-width: 768px) and (max-width: 1199.98px) {
  input[name="toggleMobileNav"]:checked ~ .page-wrap .main-nav {
    transform: translateX(0); } }

@media (max-width: 767.98px) {
  input[name="toggleMobileNav"]:checked ~ .page-wrap .main-nav {
    transform: translateX(0); } }

@media (min-width: 1200px) {
  html:not(.header-top--fixed) .frontpage .main-nav {
    width: 100%;
    padding-right: 40px; } }

.main-nav a {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 1.125; }

.main-nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.main-nav label {
  display: none; }

@media (min-width: 1200px) {
  .main-nav > ul {
    display: flex;
    flex-wrap: nowrap; } }

@media (max-width: 1199.98px) {
  .main-nav > ul {
    margin: 75px 0 90px 0; } }

@media (min-width: 1200px) {
  html:not(.header-top--fixed) .frontpage .main-nav > ul {
    display: block; } }

.main-nav > ul > li {
  position: relative; }
  @media (max-width: 1199.98px) {
    .main-nav > ul > li {
      margin-bottom: 20px; } }
  @media (max-width: 1199.98px) {
    .main-nav > ul > li:last-child {
      margin-bottom: 0; } }
  @media (min-width: 1200px) {
    .main-nav > ul > li:last-child > ul {
      left: auto;
      right: 0;
      border-top-left-radius: 5px;
      border-top-right-radius: 0; } }
  @media (min-width: 1200px) {
    html:not(.header-top--fixed) .frontpage .main-nav > ul > li {
      position: static; } }
  @media (min-width: 1200px) {
    .main-nav > ul > li.is-active > a {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px; } }
  @media (min-width: 1200px) {
    .main-nav > ul > li.is-active > a:hover:not(:only-child),
    .main-nav > ul > li.is-active:hover > a:not(:only-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; } }
  @media (min-width: 1200px) {
    .main-nav > ul > li.is-active > a,
    .main-nav > ul > li:hover > a,
    .main-nav > ul > li > a:hover {
      color: #fff;
      background-color: #004563; } }
  .main-nav > ul > li > a {
    padding: 11px 55px 11px 15px;
    color: #004563;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    transition: background-color .3s, color .3s; }
    @media (min-width: 1200px) {
      .main-nav > ul > li > a {
        padding: 20px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: hidden; } }
    @media (min-width: 1200px) {
      .main-nav > ul > li > a:only-child {
        border-radius: 5px; } }
    @media (min-width: 1200px) {
      html:not(.header-top--fixed) .frontpage .main-nav > ul > li > a {
        padding: 20px 0 20px 105px;
        border-radius: 0; } }
  @media (max-width: 1199.98px) {
    .main-nav > ul > li > label {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(0, 69, 99, 0.2);
      border-radius: 5px;
      cursor: pointer;
      transition: background-color .3s; } }
  @media (max-width: 1199.98px) {
    .main-nav > ul > li > label:before {
      display: block;
      position: absolute;
      content: '';
      left: 50%;
      top: 50%;
      width: 8px;
      height: 15px;
      transform: translate(-50%, -50%);
      background-image: svg-load("angle-right.svg", fill= "");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      transition: transform .3s, filter .3s; } }
  @media (max-width: 1199.98px) {
    .main-nav > ul > li > input[name=toggleSubNav]:checked ~ label {
      background: #004563;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; } }
  @media (max-width: 1199.98px) {
    .main-nav > ul > li > input[name=toggleSubNav]:checked ~ label:before {
      filter: saturate(0) brightness(0) invert(100%) opacity(1);
      transform: translate(-50%, -50%) rotateZ(90deg); } }
  @media (max-width: 1199.98px) {
    .main-nav > ul > li > input[name=toggleSubNav]:checked ~ ul {
      padding: 15px 0;
      max-height: 1500px; } }
  @media (min-width: 1200px) {
    .main-nav > ul > li:hover > ul {
      opacity: 1;
      visibility: visible; } }
  .main-nav > ul > li > ul {
    overflow: hidden; }
    @media (min-width: 1200px) {
      .main-nav > ul > li > ul {
        position: absolute;
        top: 100%;
        left: 0;
        padding: 25px 0;
        min-width: 300px;
        max-height: calc(100vh - 100px);
        background: #004563;
        opacity: 0;
        overflow-y: auto;
        visibility: hidden;
        transition: opacity .3s, visibility .3s;
        border-radius: 5px;
        border-top-left-radius: 0; } }
    @media (max-width: 1199.98px) {
      .main-nav > ul > li > ul {
        padding: 0;
        max-height: 0;
        background: #004563;
        border-radius: 5px;
        border-top-right-radius: 0;
        transition: padding .3s, max-height .5s; } }
    @media (min-width: 1200px) {
      html:not(.header-top--fixed) .frontpage .main-nav > ul > li > ul {
        display: flex;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
        left: auto;
        top: 0;
        right: 100%;
        padding: 0;
        width: 100%;
        min-width: 0;
        height: 100%;
        max-height: none;
        border-radius: 0; } }
    @media (min-width: 1200px) {
      html:not(.header-top--fixed) .frontpage .main-nav > ul > li > ul > li {
        flex: 0 0 100%;
        margin-bottom: 0; } }
    @media (min-width: 1200px) {
      .main-nav > ul > li > ul > li.is-active > a,
      .main-nav > ul > li > ul > li:hover > a,
      .main-nav > ul > li > ul > li > a:hover {
        background: #fff;
        color: #004563; } }
    @media (max-width: 1199.98px) {
      .main-nav > ul > li > ul > li.is-active > a {
        background: #0B5C7F; } }
    .main-nav > ul > li > ul > li > a {
      position: relative;
      color: #fff;
      transition: color .3s, background-color .3s;
      font-weight: 500; }
      @media (min-width: 1200px) {
        .main-nav > ul > li > ul > li > a {
          padding: 15px 0 15px 56px; } }
      @media (max-width: 1199.98px) {
        .main-nav > ul > li > ul > li > a {
          padding: 15px 30px 15px 46px; } }
      @media (min-width: 1200px) {
        html:not(.header-top--fixed) .frontpage .main-nav > ul > li > ul > li > a {
          padding-left: 76px; } }
      .main-nav > ul > li > ul > li > a:before {
        display: block;
        content: '';
        position: absolute;
        left: 30px;
        top: 50%;
        transform: translateY(-50%);
        border-left: 6px solid #FF9C59;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent; }
        @media (min-width: 1200px) {
          .main-nav > ul > li > ul > li > a:before {
            left: 40px; } }
        @media (min-width: 1200px) {
          html:not(.header-top--fixed) .frontpage .main-nav > ul > li > ul > li > a:before {
            left: 60px; } }

.main-nav--toggle {
  display: none; }
  @media (max-width: 1199.98px) {
    .main-nav--toggle {
      display: block;
      position: relative;
      padding: 7px 0;
      cursor: pointer; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .main-nav--toggle {
      width: 35px; } }
  @media (max-width: 767.98px) {
    .main-nav--toggle {
      width: 25px; } }
  @media (max-width: 1199.98px) {
    .main-nav--toggle span {
      display: block;
      width: 100%;
      background: #004563;
      transition: background-color .3s; } }
  @media (max-width: 1199.98px) {
    .main-nav--toggle span, .main-nav--toggle span:before, .main-nav--toggle span:after {
      height: 2px; } }
  @media (max-width: 1199.98px) {
    .main-nav--toggle span:before, .main-nav--toggle span:after {
      display: block;
      position: absolute;
      content: '';
      left: 0;
      width: 100%;
      background: #004563;
      transition: top .3s, bottom .3s, transform .3s; } }
  @media (max-width: 1199.98px) {
    .main-nav--toggle span:before {
      top: 0; } }
  @media (max-width: 1199.98px) {
    .main-nav--toggle span:after {
      bottom: 0; } }
  @media (max-width: 1199.98px) {
    .main-nav--toggle.main-nav--toggle--close {
      padding: 11px 0; } }
  @media (max-width: 1199.98px) {
    .main-nav--toggle.main-nav--toggle--close span {
      background-color: transparent; } }
  @media (max-width: 1199.98px) {
    .main-nav--toggle.main-nav--toggle--close span:before {
      top: auto;
      transform: rotateZ(-45deg); } }
  @media (max-width: 1199.98px) {
    .main-nav--toggle.main-nav--toggle--close span:after {
      bottom: auto;
      transform: rotateZ(45deg); } }

.main-nav > .main-nav--toggle {
  display: none; }
  @media (max-width: 1199.98px) {
    .page-header--top .main-nav > .main-nav--toggle {
      display: block;
      position: absolute;
      top: 30px;
      right: 30px; } }

.main-nav .logo {
  display: none; }
  @media (max-width: 767.98px) {
    .main-nav .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 128px; } }

.main-nav .lang-nav {
  display: none; }
  @media (max-width: 1199.98px) {
    .main-nav .lang-nav {
      display: block;
      position: relative;
      left: auto;
      top: auto;
      z-index: 1; } }
  @media (max-width: 1199.98px) {
    .main-nav .lang-nav > ul {
      justify-content: center; } }
  @media (max-width: 1199.98px) {
    .main-nav .lang-nav > ul > li.is-active a, .main-nav .lang-nav > ul > li.is-active a:hover {
      background: #F2F6F7;
      border-color: transparent;
      color: #004563; } }
  @media (max-width: 767.98px) {
    .main-nav .lang-nav > ul > li.is-active a .flag, .main-nav .lang-nav > ul > li.is-active a:hover .flag {
      display: inline-block; } }
  @media (max-width: 1199.98px) {
    .main-nav .lang-nav > ul > li a {
      border-color: rgba(0, 69, 99, 0.2);
      color: #004563;
      background: transparent; } }
  @media (max-width: 1199.98px) {
    .main-nav .lang-nav > ul > li a:hover {
      color: #fff;
      background: #004563; } }
  @media (max-width: 767.98px) {
    .main-nav .lang-nav > ul > li a:hover .flag {
      display: none; } }

.frontpage .lang-nav {
  position: absolute;
  left: 60px;
  top: 60px;
  z-index: 998; }

.lang-nav > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap; }
  .lang-nav > ul > li {
    margin-right: 10px; }
    .lang-nav > ul > li:last-child {
      margin: 0; }
    .lang-nav > ul > li a {
      display: block;
      padding: 0 10px;
      min-width: 40px;
      font-size: 12px;
      line-height: 38px;
      font-weight: 700;
      text-align: center;
      color: rgba(0, 69, 99, 0.5);
      border: 1px solid rgba(0, 69, 99, 0.15);
      border-radius: 5px;
      transition: color .3s, background-color .3s, border .3s; }
      .frontpage .lang-nav > ul > li a {
        border-color: transparent;
        background: rgba(0, 69, 99, 0.15);
        color: #fff; }
      .lang-nav > ul > li a:hover {
        background: #fff;
        border-color: transparent;
        color: #004563; }
        .frontpage .lang-nav > ul > li a:hover {
          background: #004563;
          color: #fff; }
    .lang-nav > ul > li.is-active a {
      background-color: #fff;
      color: #004563;
      border-color: transparent; }
      .frontpage .lang-nav > ul > li.is-active a {
        background-color: rgba(255, 255, 255, 0.6);
        color: #004563; }
    .lang-nav > ul > li.is-active .flag,
    .lang-nav > ul > li a:hover .flag {
      margin-right: 10px;
      width: 30px;
      opacity: 1; }

.lang-nav .flag {
  display: none;
  margin-right: 0;
  width: 0;
  height: 20px;
  vertical-align: middle;
  border-radius: 3px;
  overflow: hidden;
  opacity: 0;
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  transition: width .3s, margin .3s, opacity .3s; }
  .frontpage .lang-nav .flag {
    display: inline-block; }
  .lang-nav .flag--at {
    background-image: svg-load("../layout/svg/flag_at.svg", fill= ""); }
  .lang-nav .flag--gb {
    background-image: svg-load("../layout/svg/flag_uk.svg", fill= ""); }
  .lang-nav .flag--it {
    background-image: svg-load("../layout/svg/flag_it.svg", fill= ""); }

.breadcrumbs {
  padding: 20px 0;
  background: #F2F6F7; }
  @media (max-width: 767.98px) {
    .breadcrumbs {
      padding: 23px 0; } }
  @media (max-width: 767.98px) {
    .landingpage .breadcrumbs {
      padding: 13px 0; } }
  .breadcrumbs > .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 1260px; }
    @media (max-width: 767.98px) {
      .breadcrumbs > .container {
        padding-left: 15px;
        padding-right: 15px; } }
  .breadcrumbs--nav {
    margin-right: 20px; }
    @media (max-width: 767.98px) {
      .breadcrumbs--nav {
        margin-right: 0;
        width: 100%; } }
  .breadcrumbs--list {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    list-style-type: none; }
    @media (max-width: 767.98px) {
      .breadcrumbs--list:before {
        display: block;
        content: '...';
        padding-right: 10px;
        font-size: 12px;
        line-height: 1.16667;
        color: rgba(0, 69, 99, 0.5);
        white-space: nowrap; } }
  .breadcrumbs--item {
    position: relative;
    padding: 0 13px 0 10px; }
    @media (max-width: 767.98px) {
      .breadcrumbs--item {
        display: none; } }
    @media (max-width: 767.98px) {
      .breadcrumbs--item:nth-last-child(2), .breadcrumbs--item:last-child {
        display: block; } }
    @media (max-width: 767.98px) {
      .breadcrumbs--item:nth-last-child(2) {
        padding-left: 14px; } }
    @media (max-width: 767.98px) {
      .breadcrumbs--item:nth-last-child(2):before {
        display: block;
        left: 0; } }
    .breadcrumbs--item:last-child:after {
      display: none; }
    .breadcrumbs--item.is-active > a {
      color: #004563; }
    .breadcrumbs--item:nth-last-child(2):before, .breadcrumbs--item:after {
      display: none;
      content: '';
      position: absolute;
      top: 0;
      width: 5px;
      height: 100%;
      background: svg-load("angle-right.svg", fill= "") no-repeat center center transparent;
      background-size: contain;
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%);
      opacity: .5; }
    .breadcrumbs--item:after {
      display: block;
      right: 0; }
    .breadcrumbs--item > a {
      display: block;
      font-size: 12px;
      line-height: 1.16667;
      color: rgba(0, 69, 99, 0.5);
      white-space: nowrap;
      transition: color .3s; }
      .breadcrumbs--item > a:hover {
        color: #004563; }
  .breadcrumbs .lang-nav {
    justify-self: flex-end; }
    @media (max-width: 767.98px) {
      .breadcrumbs .lang-nav {
        display: none; } }
    @media (max-width: 767.98px) {
      .landingpage .breadcrumbs .lang-nav {
        display: flex; } }
    .breadcrumbs .lang-nav > ul > li a {
      padding: 0 5px;
      min-width: 34px;
      height: 34px;
      line-height: 32px; }
  .breadcrumbs .page-header--meta {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto; }
    .breadcrumbs .page-header--meta a {
      color: #004563;
      font-weight: 500; }
      @media (max-width: 767.98px) {
        .breadcrumbs .page-header--meta a {
          font-size: 14px;
          line-height: 20px; } }
      .breadcrumbs .page-header--meta a .icon {
        filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
        @media (max-width: 767.98px) {
          .breadcrumbs .page-header--meta a .icon {
            font-size: 16px;
            margin-right: 3px; } }
      @media (max-width: 767.98px) {
        .breadcrumbs .page-header--meta a span {
          display: inline-block; } }

.cols {
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px; }
  @media (max-width: 767.98px) {
    .cols {
      grid-column-gap: 15px;
      grid-row-gap: 15px; } }
  .cols.no-gutter {
    grid-column-gap: 0;
    grid-row-gap: 0; }
  @media (min-width: 576px) {
    .cols--2 {
      grid-template-columns: repeat(2, minmax(0, 1fr)); }
      .cols--2.ratio--25-75 {
        grid-template-columns: 25% 75%; }
      .cols--2.ratio--40-60 {
        grid-template-columns: 40% 60%; }
      .cols--2.ratio--60-40 {
        grid-template-columns: 60% 40%; }
      .cols--2.ratio--75-25 {
        grid-template-columns: 75% 25%; } }
  @media (min-width: 992px) {
    .cols--3 {
      grid-template-columns: repeat(3, minmax(0, 1fr)); }
      .cols--3.ratio--50-25-25 {
        grid-template-columns: 50% 25% 25%; }
      .cols--3.ratio--25-50-25 {
        grid-template-columns: 25% 50% 25%; }
      .cols--3.ratio--25-25-50 {
        grid-template-columns: 25% 25% 50%; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .cols--3 {
      grid-template-columns: repeat(2, minmax(0, 1fr)); } }
  @media (min-width: 992px) {
    .cols--4 {
      grid-template-columns: repeat(4, minmax(0, 1fr)); }
      .cols--4.ratio--40-20-20-20 {
        grid-template-columns: 40% 20% 20% 20%; }
      .cols--4.ratio--20-40-20-20 {
        grid-template-columns: 20% 40% 20% 20%; }
      .cols--4.ratio--20-20-40-20 {
        grid-template-columns: 20% 20% 40% 20%; }
      .cols--4.ratio--20-20-20-40 {
        grid-template-columns: 20% 20% 20% 40%; } }
  @media (min-width: 576px) and (max-width: 991.98px) {
    .cols--4 {
      grid-template-columns: repeat(2, minmax(0, 1fr)); } }
  @media (min-width: 1200px) {
    .cols--5 {
      grid-template-columns: repeat(5, minmax(0, 1fr)); } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .cols--5 {
      grid-template-columns: repeat(4, minmax(0, 1fr)); } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .cols--5 {
      grid-template-columns: repeat(3, minmax(0, 1fr)); } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .cols--5 {
      grid-template-columns: repeat(2, minmax(0, 1fr)); } }
  @media (min-width: 1200px) {
    .cols--6 {
      grid-template-columns: repeat(6, minmax(0, 1fr)); } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .cols--6 {
      grid-template-columns: repeat(4, minmax(0, 1fr)); } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .cols--6 {
      grid-template-columns: repeat(3, minmax(0, 1fr)); } }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .cols--6 {
      grid-template-columns: repeat(2, minmax(0, 1fr)); } }
  .cols .col--2 {
    grid-column: auto / span 2; }
  .cols .col--3 {
    grid-column: auto / span 3; }
  .cols .col--4 {
    grid-column: auto / span 4; }
  .cols .col--5 {
    grid-column: auto / span 5; }
  .cols .col--6 {
    grid-column: auto / span 6; }

table.contenttable,
.area--text table {
  width: 100%;
  border: none; }
  table.contenttable th,
  table.contenttable td,
  .area--text table th,
  .area--text table td {
    padding: 6px 20px;
    font-weight: 300;
    min-width: 25vw; }
    table.contenttable th:first-child,
    table.contenttable td:first-child,
    .area--text table th:first-child,
    .area--text table td:first-child {
      padding-left: 40px; }
    table.contenttable th:last-child,
    table.contenttable td:last-child,
    .area--text table th:last-child,
    .area--text table td:last-child {
      padding-right: 40px; }
    @media (min-width: 768px) {
      table.contenttable th,
      table.contenttable td,
      .area--text table th,
      .area--text table td {
        min-width: 0; } }
  table.contenttable thead tr:first-child th,
  .area--text table thead tr:first-child th {
    padding: 15px 20px;
    background-color: #004563;
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1.15;
    font-weight: 500; }
  table.contenttable thead tr:not(:first-child) th,
  table.contenttable tbody tr th,
  .area--text table thead tr:not(:first-child) th,
  .area--text table tbody tr th {
    background-color: #F2F6F7;
    color: #004563;
    font-weight: 500; }
  table.contenttable td,
  .area--text table td {
    border-bottom: 1px solid #E5ECEF; }
  table.contenttable p:last-child,
  .area--text table p:last-child {
    margin-bottom: 0; }

@media (max-width: 575.98px) {
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.icon,
.inline-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1em; }

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }
  .icon--color--white {
    filter: saturate(0) brightness(0) invert(100%) opacity(1); }

.icon--angle-right {
  background-image: svg-load("angle-right.svg"); }

.icon--arrow-down {
  background-image: svg-load("arrow-down.svg"); }

.icon--arrow-right {
  background-image: svg-load("arrow-right.svg"); }

.icon--bathrobe {
  background-image: svg-load("bathrobe.svg"); }

.icon--bed {
  background-image: svg-load("bed.svg"); }

.icon--calendar {
  background-image: svg-load("calendar.svg"); }

.icon--check {
  background-image: svg-load("check.svg"); }

.icon--clock {
  background-image: svg-load("clock.svg"); }

.icon--envelope {
  background-image: svg-load("envelope.svg"); }

.icon--eye {
  background-image: svg-load("eye.svg"); }

.icon--gesundheit {
  background-image: svg-load("gesundheit.svg"); }

.icon--gift {
  background-image: svg-load("gift.svg"); }

.icon--globe {
  background-image: svg-load("globe.svg"); }

.icon--info {
  background-image: svg-load("info.svg"); }

.icon--location {
  background-image: svg-load("location.svg"); }

.icon--phone {
  background-image: svg-load("phone.svg"); }

.icon--question {
  background-image: svg-load("question.svg"); }

.icon--quotes {
  background-image: svg-load("quotes.svg"); }

.icon--raindrop {
  background-image: svg-load("raindrop.svg"); }

.icon--search {
  background-image: svg-load("search.svg"); }

.icon--thermometer {
  background-image: svg-load("thermometer.svg"); }

.icon--ticket {
  background-image: svg-load("ticket.svg"); }

.icon--beauty-world {
  background-image: svg-load("beauty-world.svg"); }

.icon--family-world {
  background-image: svg-load("family-world.svg"); }

.icon--ladies-world {
  background-image: svg-load("ladies-world.svg"); }

.icon--relax-world {
  background-image: svg-load("relax-world.svg"); }

.icon--sauna-world {
  background-image: svg-load("sauna-world.svg"); }

.icon--sport-world {
  background-image: svg-load("sport-world.svg"); }

.icon--facebook {
  background-image: svg-load("facebook.svg"); }

.icon--flickr {
  background-image: svg-load("flickr.svg"); }

.icon--youtube {
  background-image: svg-load("youtube.svg"); }

.icon--instagram {
  background-image: svg-load("instagram.svg"); }

.teaser a {
  text-decoration: none; }

.area .teaser > a:not(.btn),
.area .teaser > a:not(.btn)[target="_blank"],
.teaser > a:not(.btn),
.teaser > a:not(.btn)[target="_blank"] {
  display: inline;
  height: 100%;
  font-weight: inherit;
  color: inherit;
  text-decoration: none; }
  .area .teaser > a:not(.btn):hover,
  .area .teaser > a:not(.btn)[target="_blank"]:hover,
  .teaser > a:not(.btn):hover,
  .teaser > a:not(.btn)[target="_blank"]:hover {
    opacity: 1; }
    .area .teaser > a:not(.btn):hover button.btn,
    .area .teaser > a:not(.btn)[target="_blank"]:hover button.btn,
    .teaser > a:not(.btn):hover button.btn,
    .teaser > a:not(.btn)[target="_blank"]:hover button.btn {
      left: 2px;
      top: 2px; }
      .area .teaser > a:not(.btn):hover button.btn:after,
      .area .teaser > a:not(.btn)[target="_blank"]:hover button.btn:after,
      .teaser > a:not(.btn):hover button.btn:after,
      .teaser > a:not(.btn)[target="_blank"]:hover button.btn:after {
        top: 0;
        left: 0; }
      .area .teaser > a:not(.btn):hover button.btn-primary,
      .area .teaser > a:not(.btn)[target="_blank"]:hover button.btn-primary,
      .teaser > a:not(.btn):hover button.btn-primary,
      .teaser > a:not(.btn)[target="_blank"]:hover button.btn-primary {
        background-color: #fff;
        border-color: #fff;
        color: #FF9C59; }
        .area .teaser > a:not(.btn):hover button.btn-primary .icon,
        .area .teaser > a:not(.btn)[target="_blank"]:hover button.btn-primary .icon,
        .teaser > a:not(.btn):hover button.btn-primary .icon,
        .teaser > a:not(.btn)[target="_blank"]:hover button.btn-primary .icon {
          filter: invert(66%) sepia(62%) saturate(557%) hue-rotate(327deg) brightness(100%) contrast(107%); }
      .area .teaser > a:not(.btn):hover button.btn-secondary,
      .area .teaser > a:not(.btn)[target="_blank"]:hover button.btn-secondary,
      .teaser > a:not(.btn):hover button.btn-secondary,
      .teaser > a:not(.btn)[target="_blank"]:hover button.btn-secondary {
        background-color: #004563;
        color: #fff; }
        .area .teaser > a:not(.btn):hover button.btn-secondary .icon,
        .area .teaser > a:not(.btn)[target="_blank"]:hover button.btn-secondary .icon,
        .teaser > a:not(.btn):hover button.btn-secondary .icon,
        .teaser > a:not(.btn)[target="_blank"]:hover button.btn-secondary .icon {
          filter: saturate(0) brightness(0) invert(100%) opacity(1); }
      .area .teaser > a:not(.btn):hover button.btn-outline,
      .area .teaser > a:not(.btn)[target="_blank"]:hover button.btn-outline,
      .teaser > a:not(.btn):hover button.btn-outline,
      .teaser > a:not(.btn)[target="_blank"]:hover button.btn-outline {
        background-color: #0B5C7F; }
  .area .teaser > a:not(.btn):after,
  .area .teaser > a:not(.btn)[target="_blank"]:after,
  .teaser > a:not(.btn):after,
  .teaser > a:not(.btn)[target="_blank"]:after {
    display: none; }

.teaser:hover .teaser--media > img,
.teaser:hover .teaser--media > picture > img,
.teaser:hover .teaser--media > video {
  transform: scale(1.1); }

.teaser--inner {
  position: relative;
  width: 100%;
  height: 100%;
  background: #004563;
  color: #fff; }

.teaser--offer {
  display: flex;
  position: absolute;
  top: 0;
  right: 30px;
  transform: translateY(-50%);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  z-index: 2; }
  .teaser--offer label {
    display: flex;
    align-items: center;
    padding: 0 15px;
    background: #fff;
    color: #004563;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase; }
  .teaser--offer span {
    display: block;
    padding: 0 15px;
    height: 56px;
    line-height: 56px;
    white-space: nowrap;
    background: #FF9C59;
    color: #fff;
    font-weight: 600;
    font-size: 26px; }
    @media (max-width: 767.98px) {
      .teaser--offer span {
        height: 45px;
        line-height: 45px;
        font-size: 20px; } }
    .teaser--offer span small {
      font-size: 15px;
      font-weight: 400; }

.teaser--partner {
  position: absolute;
  top: -20px;
  right: 30px;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
  overflow: hidden;
  white-space: nowrap;
  text-indent: -999em;
  border-radius: 5px;
  z-index: 2; }
  @media (max-width: 767.98px) {
    .teaser--partner {
      top: -10px;
      width: 60px;
      height: 60px; } }
  .teaser--partner.service {
    background-image: svg-load("../layout/svg/logo-service-partner.svg", fill= ""); }
  .teaser--partner.premium {
    background-image: svg-load("../layout/svg/logo-premium-partner.svg", fill= ""); }

.teaser--media {
  overflow: hidden; }
  .teaser--media > img,
  .teaser--media > picture > img,
  .teaser--media > video,
  .teaser--media > .pimcore_editable_video > video {
    display: block;
    width: 100%;
    height: auto;
    transition: transform .3s; }

.teaser--content {
  position: relative;
  padding: 60px 60px 40px;
  font-weight: 300;
  z-index: 1; }
  @media (max-width: 767.98px) {
    .teaser--content {
      padding: 30px 30px 25px; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .cols--3 .teaser--content {
      padding: 30px 30px 25px; } }
  .teaser--content h4, .teaser--content .h4 {
    margin-bottom: 15px;
    /*hyphens: none;
                word-break: normal;*/ }
  @media (max-width: 767.98px) {
    .teaser--content p, .teaser--content ul {
      font-size: 15px; } }
  .teaser--content ul li:before, .area .teaser--content ul li:before {
    border-left-color: #fff; }

.area .teaser:not(.teaser--light) .teaser--content a:not(.btn) {
  color: #fff; }
  .area .teaser:not(.teaser--light) .teaser--content a:not(.btn):hover {
    color: #004563; }
  .area .teaser:not(.teaser--light) .teaser--content a:not(.btn):after {
    background: #fff; }
  .area .teaser:not(.teaser--light) .teaser--content a:not(.btn)[target="_blank"]:hover {
    color: rgba(255, 255, 255, 0.8); }

.teaser--date {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  padding-left: 35px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 1.64706; }
  .teaser--date:before {
    position: absolute;
    display: block;
    content: '';
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    background-image: svg-load("calendar.svg", fill="");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    filter: saturate(0) brightness(0) invert(100%) opacity(1); }
    @media (max-width: 991.98px) {
      .teaser--date:before {
        top: -1px; } }
    .teaser--light .teaser--date:before {
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
    .area--textmedia .teaser--date:before {
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
    .area--textmedia.highlight .teaser--date:before {
      filter: saturate(0) brightness(0) invert(100%) opacity(1); }
  .teaser--date .icon {
    margin-right: 5px;
    font-size: 25px;
    filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
  .teaser--date > * {
    vertical-align: middle; }

.teaser--icon {
  left: 60px;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  transform: translateY(-50%);
  background: #fff;
  border-radius: 100%; }
  .teaser--icon, .teaser--icon[data-tooltip] {
    position: absolute; }
  @media (max-width: 767.98px) {
    .teaser--icon {
      left: 30px; } }
  .teaser--icon .icon {
    filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%);
    font-size: 30px; }

.teaser--price {
  margin-top: 22px;
  font-size: 26px;
  line-height: 1; }
  @media (max-width: 767.98px) {
    .teaser--price {
      font-size: 20px;
      line-height: 25px; } }
  .teaser--price b {
    font-weight: 600; }
  .teaser--price small {
    font-size: 15px;
    line-height: 18px;
    font-weight: 400; }
    @media (max-width: 767.98px) {
      .teaser--price small {
        font-size: 13px;
        line-height: 19px; } }
  .teaser--price span {
    display: block;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    line-height: 1.33333; }

.teaser--buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 30px -10px 0 -10px; }

.teaser .btn {
  flex: 1 0 auto;
  margin: 0 10px 20px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  text-align: center; }
  .teaser .btn:after {
    opacity: .2; }
  .teaser .btn-secondary {
    background-color: #fff;
    color: #004563; }
    .teaser .btn-secondary:hover {
      background-color: #004563;
      color: #fff; }
      .teaser .btn-secondary:hover .icon {
        filter: saturate(0) brightness(0) invert(100%) opacity(1); }
    .teaser .btn-secondary .icon {
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
  .teaser .btn-outline {
    background-color: #004563;
    border-color: #fff;
    color: #fff; }
    .teaser .btn-outline:hover {
      background-color: #0B5C7F; }
    .teaser .btn-outline .icon {
      filter: saturate(0) brightness(0) invert(100%) opacity(1); }

@media (min-width: 768px) {
  .teaser--horizontal .teaser--inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch; } }

.teaser--horizontal .teaser--media {
  position: relative; }
  @media (min-width: 1200px) {
    .teaser--horizontal .teaser--media {
      min-width: 360px; } }
  @media (min-width: 768px) {
    .teaser--horizontal .teaser--media {
      flex: 0 0 31.66667%;
      max-width: 360px;
      min-height: 160px;
      min-width: 160px; } }
  @media (min-width: 768px) {
    .teaser--horizontal .teaser--media:after {
      display: block;
      content: '';
      width: 100%;
      height: 0;
      padding-bottom: 44.44444%; } }
  @media (min-width: 768px) {
    .teaser--horizontal .teaser--media > img,
    .teaser--horizontal .teaser--media > picture > img,
    .teaser--horizontal .teaser--media > video,
    .teaser--horizontal .teaser--media > .pimcore_editable_video > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

@media (min-width: 768px) {
  .teaser--horizontal .teaser--content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    flex: 1 1 auto;
    padding: 50px 60px; } }

.teaser--horizontal .teaser--buttons {
  display: block; }
  @media (min-width: 768px) {
    .teaser--horizontal .teaser--buttons {
      margin: 0 0 0 30px; } }

.teaser--horizontal .btn {
  display: block;
  padding: 0.55em 1em;
  margin: 0 auto 20px; }
  @media (min-width: 768px) {
    .teaser--horizontal .btn {
      margin: 0 auto; } }

.teaser.teaser--light > a:hover button.btn-outline {
  border-color: #fff;
  color: #fff; }
  .teaser.teaser--light > a:hover button.btn-outline .icon {
    filter: saturate(0) brightness(0) invert(100%) opacity(1); }

.teaser.teaser--light .teaser--inner {
  background: #F2F6F7;
  color: #004563; }

.teaser.teaser--light .teaser--date:before {
  filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }

.teaser.teaser--light .teaser--content ul li:before {
  border-left-color: #004563; }

.teaser.teaser--light .btn:after {
  opacity: 1;
  background: #E5ECEF; }

.teaser.teaser--light .btn-outline {
  background-color: #F2F6F7;
  border-color: #004563;
  color: #004563; }
  .teaser.teaser--light .btn-outline:hover {
    background-color: #0B5C7F;
    border-color: #fff;
    color: #fff; }
    .teaser.teaser--light .btn-outline:hover .icon {
      filter: saturate(0) brightness(0) invert(100%) opacity(1); }
  .teaser.teaser--light .btn-outline .icon {
    filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }

.teaser.media-only .teaser--content h4, .teaser.media-only .teaser--content .h4, .teaser.headline-arrow .teaser--content h4, .teaser.headline-arrow .teaser--content .h4 {
  position: relative;
  padding-left: 1.34615em; }
  .teaser.media-only .teaser--content h4:before, .teaser.media-only .teaser--content .h4:before, .teaser.headline-arrow .teaser--content h4:before, .teaser.headline-arrow .teaser--content .h4:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0.11538em;
    border-left: 0.76923em solid #FF9C59;
    border-top: 0.5em solid transparent;
    border-bottom: 0.5em solid transparent; }

.teaser.media-only .teaser {
  overflow: hidden; }
  .teaser.media-only .teaser--media {
    position: relative; }
    @media (max-width: 767.98px) {
      .teaser.media-only .teaser--media:after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50%;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
        opacity: .8;
        pointer-events: none; } }
  .teaser.media-only .teaser--content {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0; }
    .teaser.media-only .teaser--content > * {
      position: relative;
      z-index: 1; }
    .teaser.media-only .teaser--content:after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 100%);
      opacity: .7;
      pointer-events: none;
      z-index: 0; }
      @media (max-width: 767.98px) {
        .teaser.media-only .teaser--content:after {
          display: none; } }
  .teaser.media-only .teaser--date {
    position: relative;
    display: block;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 1.64706; }
    .teaser.media-only .teaser--date {
      padding-left: 60px; }
      @media screen and (min-width: 768px) {
        .teaser.media-only .teaser--date {
          padding-left: calc(60px + 10 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .teaser.media-only .teaser--date {
          padding-left: 70px; } }
    @media (max-width: 991.98px) {
      .teaser.media-only .teaser--date {
        font-size: 15px;
        line-height: 1.66667; } }
    .teaser.media-only .teaser--date:before {
      left: 35px; }
      .teaser.media-only .teaser--date:before {
        left: 24px; }
        @media screen and (min-width: 768px) {
          .teaser.media-only .teaser--date:before {
            left: calc(24px + 11 * ((100vw - 768px) / 432)); } }
        @media screen and (min-width: 1200px) {
          .teaser.media-only .teaser--date:before {
            left: 35px; } }
    .teaser.media-only .teaser--date + .teaser--text {
      margin-top: 15px; }
  .teaser.media-only .teaser--text {
    padding-left: 24px; }
    @media screen and (min-width: 768px) {
      .teaser.media-only .teaser--text {
        padding-left: calc(24px + 11 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .teaser.media-only .teaser--text {
        padding-left: 35px; } }
  .teaser.media-only .teaser--text > *:last-child {
    margin-bottom: 0; }
  @media (max-width: 767.98px) {
    .teaser.media-only .teaser--text {
      display: none; } }
  .teaser.media-only .teaser--text ul, .teaser.media-only .teaser--text ol {
    padding: 0; }
    .teaser.media-only .teaser--text ul li, .teaser.media-only .teaser--text ol li {
      margin: 0;
      padding: 0;
      display: inline; }
      .teaser.media-only .teaser--text ul li:before, .teaser.media-only .teaser--text ol li:before {
        display: none; }
      .teaser.media-only .teaser--text ul li:after, .teaser.media-only .teaser--text ol li:after {
        display: inline;
        content: ','; }
      .teaser.media-only .teaser--text ul li:last-child:after, .teaser.media-only .teaser--text ol li:last-child:after {
        display: none; }

.teaser--accommodation .teaser--content, .cols--3 .teaser--accommodation .teaser--content {
  padding: 50px 30px 35px; }

.teaser--accommodation .teaser--location {
  position: absolute;
  top: 20px;
  right: 30px;
  display: flex;
  column-gap: 3px;
  align-items: center;
  font-size: 14px;
  font-weight: 500; }
  @media (max-width: 767.98px) {
    .teaser--accommodation .teaser--location {
      top: 15px; } }
  .teaser--accommodation .teaser--location .icon {
    filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }

.teaser--accommodation .teaser--highlights {
  font-weight: 400; }
  .teaser--accommodation .teaser--highlights li {
    padding-left: 25px; }
    .teaser--accommodation .teaser--highlights li:before {
      top: 6px;
      border: none;
      display: inline-block;
      vertical-align: middle;
      width: 1em;
      height: 1em;
      line-height: 1em;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: svg-load("check.svg", fill="");
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }

.teaser--accommodation .teaser--buttons {
  row-gap: 15px;
  column-gap: 15px;
  margin-left: 0;
  margin-right: 0; }
  .teaser--accommodation .teaser--buttons .btn {
    margin: 0; }

.teaser.test-teaser {
  display: none; }

.tab-nav {
  border-bottom: 1px solid #004563; }
  .tab-nav, .tab-nav li {
    list-style: none;
    padding: 0;
    margin: 0; }
  .tab-nav .tab-item {
    display: inline-block;
    margin: 0;
    padding: 0; }
    .tab-nav .tab-item:before {
      display: none; }
  .tab-nav a.tab-link:not(.btn) {
    padding: 15px 25px;
    font-size: 20px;
    line-height: 1.2;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: background-color .3s, color .3s, box-shadow .3s;
    border: 1px solid #004563;
    border-bottom: none;
    box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2); }
    .tab-nav a.tab-link:not(.btn):after {
      display: none; }
    .tab-nav a.tab-link:not(.btn):hover {
      background: #F2F6F7;
      color: #004563; }
    .tab-nav a.tab-link:not(.btn).active {
      color: #fff;
      background-color: #004563;
      box-shadow: none; }

.tab-content > .tab-pane {
  display: none; }
  .tab-content > .tab-pane.active {
    display: block; }
  .tab-content > .tab-pane > *:first-child {
    margin-top: 0; }
  .tab-content > .tab-pane > *:last-child {
    margin-bottom: 0; }

.accordion {
  margin-bottom: 10px;
  border: 1px solid #F2F6F7; }
  .accordion--header {
    position: relative;
    display: block;
    padding: 22px 20px 22px 60px;
    background: #F2F6F7 none;
    color: #004563;
    font-size: 20px;
    line-height: 1.2;
    font-weight: 500;
    list-style: none;
    -webkit-appearance: none;
    transition: background-color .3s, color .3s;
    word-break: break-word; }
    .accordion--header::-webkit-details-marker, .accordion--header::marker {
      display: none; }
    .accordion--header:before {
      display: block;
      content: '';
      position: absolute;
      left: 15px;
      top: 50%;
      width: 30px;
      height: 30px;
      transform: translateY(-50%) rotate(-90deg);
      background-image: svg-load("arrow-down.svg", fill= "");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%);
      transition: transform .3s; }
      .area--faq .accordion--header:before {
        transform: translateY(-50%) rotate(0deg);
        background-image: svg-load("question.svg", fill= ""); }
    .accordion--header:hover {
      background: transparent; }
  .accordion--content {
    padding: 30px 60px 60px; }
    .accordion--content > *:first-child {
      margin-top: 0; }
    .accordion--content > *:last-child {
      margin-bottom: 0; }
  .accordion[open] .accordion--header {
    background-color: transparent; }
    .accordion[open] .accordion--header:before {
      transform: translateY(-50%) rotate(0); }
      .area--faq .accordion[open] .accordion--header:before {
        transform: translateY(-50%) rotate(0deg); }

.form-container fieldset,
form.formbuilder fieldset {
  margin-bottom: 10px; }
  .form-container fieldset:last-child,
  form.formbuilder fieldset:last-child {
    margin-bottom: 0; }

.form-container label,
form.formbuilder label {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  line-height: 1.2; }
  .form-container label.required:after,
  form.formbuilder label.required:after {
    content: "*"; }

.form-container input[type=email],
.form-container input[type=text],
.form-container input[type=date],
.form-container input[type=password],
.form-container select,
.form-container textarea,
form.formbuilder input[type=email],
form.formbuilder input[type=text],
form.formbuilder input[type=date],
form.formbuilder input[type=password],
form.formbuilder select,
form.formbuilder textarea {
  padding: 8px 12px;
  width: 100%;
  color: #004563;
  background: #fff;
  border: 1px solid #000;
  border-radius: 0;
  line-height: 1.5; }
  .form-container input[type=email]:focus,
  .form-container input[type=text]:focus,
  .form-container input[type=date]:focus,
  .form-container input[type=password]:focus,
  .form-container select:focus,
  .form-container textarea:focus,
  form.formbuilder input[type=email]:focus,
  form.formbuilder input[type=text]:focus,
  form.formbuilder input[type=date]:focus,
  form.formbuilder input[type=password]:focus,
  form.formbuilder select:focus,
  form.formbuilder textarea:focus {
    color: #004563;
    background: #fff;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(255, 156, 89, 0.5); }
  .form-container input[type=email][readonly]:not(.flatpickr-input), .form-container input[type=email][disabled],
  .form-container input[type=text][readonly]:not(.flatpickr-input),
  .form-container input[type=text][disabled],
  .form-container input[type=date][readonly]:not(.flatpickr-input),
  .form-container input[type=date][disabled],
  .form-container input[type=password][readonly]:not(.flatpickr-input),
  .form-container input[type=password][disabled],
  .form-container select[readonly]:not(.flatpickr-input),
  .form-container select[disabled],
  .form-container textarea[readonly]:not(.flatpickr-input),
  .form-container textarea[disabled],
  form.formbuilder input[type=email][readonly]:not(.flatpickr-input),
  form.formbuilder input[type=email][disabled],
  form.formbuilder input[type=text][readonly]:not(.flatpickr-input),
  form.formbuilder input[type=text][disabled],
  form.formbuilder input[type=date][readonly]:not(.flatpickr-input),
  form.formbuilder input[type=date][disabled],
  form.formbuilder input[type=password][readonly]:not(.flatpickr-input),
  form.formbuilder input[type=password][disabled],
  form.formbuilder select[readonly]:not(.flatpickr-input),
  form.formbuilder select[disabled],
  form.formbuilder textarea[readonly]:not(.flatpickr-input),
  form.formbuilder textarea[disabled] {
    color: rgba(0, 69, 99, 0.5);
    background-color: rgba(0, 0, 0, 0.1);
    border-color: rgba(0, 0, 0, 0.3); }

.form-container input[type=email],
.form-container input[type=text],
.form-container input[type=date],
.form-container input[type=password],
.form-container select,
form.formbuilder input[type=email],
form.formbuilder input[type=text],
form.formbuilder input[type=date],
form.formbuilder input[type=password],
form.formbuilder select {
  height: 44px; }

.form-container textarea,
form.formbuilder textarea {
  min-height: 120px; }

.form-container .checkbox,
.form-container .radio,
form.formbuilder .checkbox,
form.formbuilder .radio {
  position: relative;
  padding-left: 20px; }
  .form-container .checkbox input[type="checkbox"],
  .form-container .checkbox input[type="radio"],
  .form-container .radio input[type="checkbox"],
  .form-container .radio input[type="radio"],
  form.formbuilder .checkbox input[type="checkbox"],
  form.formbuilder .checkbox input[type="radio"],
  form.formbuilder .radio input[type="checkbox"],
  form.formbuilder .radio input[type="radio"] {
    position: absolute;
    left: 0;
    top: 4px;
    cursor: pointer; }

.form-container .cols,
form.formbuilder .cols {
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin-bottom: 15px; }

.form-group {
  margin-bottom: 15px; }
  .form-group.datepicker {
    position: relative; }
    .form-group.datepicker:after {
      display: block;
      content: '';
      position: absolute;
      right: 8px;
      bottom: 9px;
      width: 25px;
      height: 25px;
      background-image: svg-load("calendar.svg", fill="");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
  .cols .form-group {
    margin-bottom: 0; }

.infobox {
  position: relative;
  padding: 10px 10px 10px 40px;
  overflow: hidden;
  border: 1px solid #004563;
  border-radius: 5px; }
  .infobox > *:last-child {
    margin-bottom: 0; }
  .infobox-primary {
    border-color: #0B5C7F;
    background-color: #0B5C7F;
    color: #fff; }
    .infobox-primary > * {
      position: relative;
      z-index: 1; }
    .infobox-primary a:not(.btn), .area .infobox-primary a:not(.btn) {
      color: #fff; }
      .infobox-primary a:not(.btn):hover, .area .infobox-primary a:not(.btn):hover {
        color: #004563; }
      .infobox-primary a:not(.btn):after, .area .infobox-primary a:not(.btn):after {
        background-color: #fff; }
    .infobox-primary ul li, .area .infobox-primary ul li {
      padding-left: 15px; }
      .infobox-primary ul li:before, .area .infobox-primary ul li:before {
        top: 3px;
        border-left-color: #fff; }
  .infobox .icon {
    display: block;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    filter: invert(66%) sepia(62%) saturate(557%) hue-rotate(327deg) brightness(100%) contrast(107%); }

.rhombus {
  display: block;
  position: relative;
  background: svg-load("../layout/svg/rhombus.svg", fill= "") no-repeat 0 0 transparent;
  background-size: contain;
  width: 100%;
  color: #004563;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2; }
  .rhombus:before {
    display: block;
    content: '';
    width: 100%;
    height: 0;
    padding-bottom: 63.55218%; }
  .rhombus span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.duration {
  display: block;
  text-align: center; }
  .duration .icon {
    margin-bottom: 5px;
    font-size: 20px; }
  .duration span {
    display: block;
    font-size: 12px;
    line-height: 1.16667;
    white-space: nowrap; }

.button-nav, .pagination {
  margin-bottom: 60px; }
  @media (max-width: 767.98px) {
    .button-nav, .pagination {
      margin-bottom: 40px; } }
  .button-nav ul, .pagination ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center; }
    .button-nav ul li, .pagination ul li {
      display: inline-block; }
      .button-nav ul li, .pagination ul li, .area .button-nav ul li, .area .pagination ul li {
        padding: 0;
        margin: 0 2px 10px 2px; }
      .area .button-nav ul li:before, .area .pagination ul li:before {
        display: none; }
      .button-nav ul li a:not(.btn), .pagination ul li a:not(.btn) {
        display: block;
        position: static;
        padding: 8px 10px;
        font-weight: 300;
        line-height: 1.2;
        border: 1px solid #E5ECEF;
        border-radius: 5px;
        transition: color .3s, background-color .3s, border-color .3s; }
        .button-nav ul li a:not(.btn):hover, .pagination ul li a:not(.btn):hover {
          background: #E5ECEF;
          color: #004563; }
        .button-nav ul li a:not(.btn).is-active, .pagination ul li a:not(.btn).is-active {
          background: #004563;
          color: #fff;
          border-color: #004563; }
        .area .button-nav ul li a:not(.btn):after, .area .pagination ul li a:not(.btn):after {
          display: none; }
  .button-nav.button-nav--secondary ul li a:not(.btn), .button-nav--secondary.pagination ul li a:not(.btn) {
    border-color: transparent;
    background-color: #F2F6F7; }
    .button-nav.button-nav--secondary ul li a:not(.btn):hover, .button-nav--secondary.pagination ul li a:not(.btn):hover {
      background: #E5ECEF;
      color: #004563; }
    .button-nav.button-nav--secondary ul li a:not(.btn).is-active, .button-nav--secondary.pagination ul li a:not(.btn).is-active {
      background: #004563;
      color: #fff;
      border-color: #004563; }

.pagination {
  margin: 0; }
  .pagination.is-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 10px 5px;
    background: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    z-index: 998; }
  .pagination ul li a:not(.btn) {
    min-width: 38px; }

.partner-box {
  position: relative;
  padding-left: 50px;
  height: 100%; }
  @media (max-width: 991.98px) {
    .partner-box {
      padding: 10px 0 0; } }
  .partner-box:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 60px;
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain; }
    @media (max-width: 991.98px) {
      .partner-box:before {
        top: 0;
        left: 30px;
        width: 60px;
        height: 60px; } }
  .partner-box.service:before {
    background-image: svg-load("../layout/svg/logo-service-partner.svg", fill= ""); }
  .partner-box.premium:before {
    background-image: svg-load("../layout/svg/logo-premium-partner.svg", fill= ""); }
  .partner-box--inner {
    padding: 60px 60px 60px 90px;
    height: 100%;
    background: #F2F6F7; }
    @media (max-width: 991.98px) {
      .partner-box--inner {
        padding: 70px 30px 30px; } }
  .partner-box--content {
    position: relative;
    z-index: 1; }
  .partner-box h5, .partner-box .h5 {
    margin-bottom: 10px; }
  .partner-box p:not(.h5) {
    font-weight: 300; }
    @media (max-width: 767.98px) {
      .partner-box p:not(.h5) {
        font-size: 15px; } }
  .partner-box .btn {
    position: relative;
    display: block;
    margin: 25px 0 0 0;
    width: 100%;
    background: #F2F6F7;
    text-align: center; }
    .partner-box .btn:hover {
      background: #004563; }

[data-tooltip] {
  position: relative; }
  [data-tooltip]:before, [data-tooltip]:after {
    display: block;
    position: absolute;
    bottom: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity .3s, visibility .3s; }
  [data-tooltip]:before {
    content: '';
    left: 20px;
    margin-bottom: 5px;
    border-top: 5px solid #fff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    z-index: 2; }
  [data-tooltip]:after {
    content: attr(data-tooltip);
    left: 0;
    margin-bottom: 10px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    font-size: 12px;
    line-height: 14px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    white-space: nowrap;
    font-weight: 400;
    z-index: 1; }
  [data-tooltip]:hover:before, [data-tooltip]:hover:after {
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }

.accommodation-box {
  margin: 60px auto;
  width: 100%;
  max-width: 47.05882em; }
  @media (min-width: 768px) {
    .accommodation-box {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px; } }
  @media (min-width: 768px) {
    .accommodation-box.no-cols {
      grid-template-columns: 100%; } }
  .accommodation-box--address {
    position: relative;
    padding: 5px 0;
    height: 100%;
    text-align: center; }
    @media (max-width: 767.98px) {
      .accommodation-box--address {
        margin-bottom: 30px; } }
    .accommodation-box--address:before, .accommodation-box--address:after {
      display: block;
      position: absolute;
      content: '';
      left: 50%;
      width: 20px;
      height: 11px;
      transform: translateX(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.12985 10.8018L15.1515 6.12936C15.2569 6.0604 15.2569 5.90522 15.1515 5.83626L8.12985 1.12074C8.07309 1.08626 8.00822 1.08626 7.95958 1.12074L0.897413 5.83626C0.792008 5.90522 0.792008 6.0604 0.897413 6.12936L7.95958 10.8018C8.01633 10.8363 8.0812 10.8363 8.12985 10.8018Z' stroke='rgba(0,69,99,.2)' fill='%23fff'/%3E%3C/svg%3E%0A");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center; }
    .accommodation-box--address:before {
      top: 0; }
    .accommodation-box--address:after {
      bottom: 0; }
    .accommodation-box--address--links {
      flex: 0 0 auto;
      text-align: left; }
  .accommodation-box address {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 30px;
    width: 100%;
    height: 100%;
    border-top: 1px solid rgba(0, 69, 99, 0.2);
    border-bottom: 1px solid rgba(0, 69, 99, 0.2); }
    .accommodation-box address p {
      flex: 0 0 100%;
      margin-bottom: 0.94118em;
      font-weight: 300; }
    .accommodation-box address b, .accommodation-box address strong {
      display: block;
      margin-bottom: 0.70588em; }
      .accommodation-box address b:last-child, .accommodation-box address strong:last-child {
        margin-bottom: 0; }
      .accommodation-box address b .icon, .accommodation-box address strong .icon {
        margin-right: 10px;
        filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%);
        font-size: 20px; }
    .accommodation-box address a {
      text-decoration: underline; }
  .accommodation-box--price {
    display: flex;
    justify-content: stretch;
    align-items: center;
    align-content: center;
    padding: 30px;
    background: #F2F6F7; }
    .accommodation-box--price--wrap {
      position: relative;
      z-index: 1;
      width: 100%; }
    .accommodation-box--price--inner {
      width: 100%;
      text-align: center; }
      .accommodation-box--price--inner > * {
        vertical-align: bottom; }
      .accommodation-box--price--inner small {
        display: inline-block;
        font-size: 15px;
        line-height: 1.2; }
      .accommodation-box--price--inner b, .accommodation-box--price--inner strong {
        display: inline-block;
        font-size: 26px;
        line-height: 1;
        font-weight: 600; }
      .accommodation-box--price--inner span {
        display: block;
        font-size: 12px;
        line-height: 1.33333;
        font-style: italic; }
    .accommodation-box--price .btn {
      margin-top: 30px;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      text-align: center;
      white-space: nowrap; }
      @media (max-width: 767.98px) {
        .accommodation-box--price .btn {
          margin-top: 10px; } }
      .accommodation-box--price .btn:only-child {
        margin-top: 0; }

.accommodation--highlights {
  max-width: 47.05882em;
  margin-left: auto;
  margin-right: auto; }
  .accommodation--highlights ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    column-count: 2; }
    @media (max-width: 767.98px) {
      .accommodation--highlights ul {
        column-count: initial; } }
    .accommodation--highlights ul li {
      position: relative;
      padding: 0 0 0 30px; }
      .accommodation--highlights ul li:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 15px;
        height: 15px;
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        height: 1em;
        line-height: 1em;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: svg-load("check.svg", fill="");
        filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }

body.magazin .main-content .area--text h2 {
  line-height: 1.16666;
  font-weight: 500; }
  body.magazin .main-content .area--text h2 {
    font-size: 25px; }
    @media screen and (min-width: 768px) {
      body.magazin .main-content .area--text h2 {
        font-size: calc(25px + 11 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      body.magazin .main-content .area--text h2 {
        font-size: 36px; } }
  body.magazin .main-content .area--text h2.super {
    font-size: 30px; }
    @media screen and (min-width: 768px) {
      body.magazin .main-content .area--text h2.super {
        font-size: calc(30px + 50 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      body.magazin .main-content .area--text h2.super {
        font-size: 80px; } }
  @media (max-width: 767.98px) {
    body.magazin .main-content .area--text h2 {
      font-weight: 600; } }

body.magazin .main-content .area--text h3 {
  line-height: 1.17308;
  font-weight: 600; }
  body.magazin .main-content .area--text h3 {
    font-size: 18px; }
    @media screen and (min-width: 768px) {
      body.magazin .main-content .area--text h3 {
        font-size: calc(18px + 8 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      body.magazin .main-content .area--text h3 {
        font-size: 26px; } }

body.magazin .main-content .area--text h4 {
  line-height: 1.173;
  font-weight: 500; }
  body.magazin .main-content .area--text h4 {
    font-size: 17px; }
    @media screen and (min-width: 768px) {
      body.magazin .main-content .area--text h4 {
        font-size: calc(17px + 3 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      body.magazin .main-content .area--text h4 {
        font-size: 20px; } }

body.magazin .main-content .area--text h5 {
  font-weight: 500; }
  body.magazin .main-content .area--text h5 {
    font-size: 17px; }
    @media screen and (min-width: 768px) {
      body.magazin .main-content .area--text h5 {
        font-size: calc(17px + 0 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      body.magazin .main-content .area--text h5 {
        font-size: 17px; } }

body.magazin h1 small.author {
  font-size: 16px;
  font-weight: 400; }

.author--content {
  grid-template-columns: minmax(0, 0.4fr) minmax(0, 0.6fr);
  margin-left: auto;
  margin-right: auto;
  max-width: 47.05882em; }
  @media (max-width: 767.98px) {
    .author--content {
      grid-template-columns: 100%; } }

.author--image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: auto;
  height: auto;
  max-width: 100%; }
  @media (min-width: 768px) {
    .author--image img {
      width: 100%;
      height: auto; } }

.area .iconbox a:not(.btn) {
  font-weight: inherit;
  position: static; }
  .area .iconbox a:not(.btn), .area .iconbox a:not(.btn):hover, .area .iconbox a:not(.btn):focus {
    color: inherit;
    text-decoration: none; }
  .area .iconbox a:not(.btn):after {
    display: none; }

.iconbox--inner {
  text-align: center; }

.iconbox--icon {
  position: relative;
  margin-bottom: 40px; }
  .iconbox--icon:after {
    display: block;
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    width: 15px;
    height: 0;
    border-top: 3px solid #FF9C59;
    border-radius: 3px;
    transform: translate(-50%, 19px); }
  .iconbox--icon .icon {
    font-size: 60px;
    filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }

.iconbox--title:not(:last-child) {
  margin-bottom: 15px; }

.iconbox--text {
  font-weight: 300; }

.author-box {
  position: relative;
  padding-top: 60px; }
  @media (max-width: 767.98px) {
    .author-box {
      padding-top: 40px; } }
  .author-box:before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    right: -60px;
    width: 416px;
    aspect-ratio: 8 / 5;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 323 260'%3E%3Cpath stroke='%23FF9C59' stroke-width='3' d='M320.175 129.443 115.562 258.229-90.164 129.443 115.562 1.767l204.613 127.676Z'/%3E%3C/svg%3E") no-repeat 100% 0 transparent;
    background-size: contain;
    clip-path: polygon(0 0, 100% 0, 100% 75%, 85.57692% 75%, 85.57692% 48%, 60% 23.07692%, 0 23.07692%);
    pointer-events: none;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .author-box:before {
        top: 12px;
        right: -20px;
        width: 200px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 152 125'%3E%3Cpath stroke='%23FF9C59' stroke-width='3' d='m149.175 62.235-96.906 60.994-97.433-60.994L52.269 1.767l96.906 60.468Z'/%3E%3C/svg%3E");
        clip-path: polygon(0 0, 100% 0, 100% 75%, 90% 75%, 90% 48%, 60% 23.07692%, 0 23.07692%); } }
  .author-box a:not(.btn), .area .author-box a:not(.btn), .author-box a:not(.btn):hover, .area .author-box a:not(.btn):hover {
    color: inherit;
    text-decoration: none; }
    .author-box a:not(.btn):after, .area .author-box a:not(.btn):after, .author-box a:not(.btn):hover:after, .area .author-box a:not(.btn):hover:after {
      display: none; }
  .author-box--inner {
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    background: #F2F6F7; }
    .author-box--inner {
      column-gap: 30px; }
      @media screen and (min-width: 768px) {
        .author-box--inner {
          column-gap: calc(30px + 30 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .author-box--inner {
          column-gap: 60px; } }
    .author-box--inner {
      padding: 30px; }
      @media screen and (min-width: 768px) {
        .author-box--inner {
          padding: calc(30px + 30 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .author-box--inner {
          padding: 60px; } }
    @media (max-width: 767.98px) {
      .author-box--inner {
        flex-direction: column;
        padding: 0 30px 30px 40px; } }
  .author-box--image {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 2; }
    .author-box--image {
      flex-basis: 180px; }
      @media screen and (min-width: 768px) {
        .author-box--image {
          flex-basis: calc(180px + 80 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .author-box--image {
          flex-basis: 260px; } }
    .author-box--image {
      margin-top: -60px; }
      @media screen and (min-width: 768px) {
        .author-box--image {
          margin-top: calc(-60px + -60 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .author-box--image {
          margin-top: -120px; } }
    @media (max-width: 767.98px) {
      .author-box--image {
        flex-basis: 120px;
        margin: -40px 0 15px 0;
        width: 120px; } }
    .author-box--image img {
      display: block;
      width: 100%;
      height: auto;
      clip-path: circle(50%); }
  .author-box--text {
    flex: 1 1 auto; }
    .author-box--text > *:first-child {
      margin-top: 0; }
    .author-box--text > *:last-child {
      margin-bottom: 0; }
    .author-box--text h4, .author-box--text .h4 {
      margin-bottom: 1.15em; }
      .author-box--text h4 small, .author-box--text .h4 small {
        font-size: 17px;
        line-height: 1.64;
        font-weight: 400;
        font-style: italic; }
    .author-box--text p, .author-box--text ul, .author-box--text ol {
      color: #000;
      font-weight: 300;
      line-height: 1.64; }
      @media (max-width: 767.98px) {
        .author-box--text p, .author-box--text ul, .author-box--text ol {
          font-size: 15px; } }

/*** IMPORT AREAS ***/
.area {
  /** fade in for full width elements **/
  /** special fade in for area media with text **/
  /*&.area--media.has-text .area--media--text {
        &.fade-in.has-fade-in {
            &--bottom {
                animation: fade-in-bottom-special $animation-duration $animation-easing;
            }
        }
    }*/ }
  .area {
    margin-top: 30px; }
    @media screen and (min-width: 768px) {
      .area {
        margin-top: calc(30px + 30 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area {
        margin-top: 60px; } }
  .area {
    margin-bottom: 30px; }
    @media screen and (min-width: 768px) {
      .area {
        margin-bottom: calc(30px + 30 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area {
        margin-bottom: 60px; } }
  .area.margin-top--none {
    margin-top: 0; }
  .area.margin-top--tiny {
    margin-top: 15px; }
  .area.margin-top--small {
    margin-top: 30px; }
  .area.margin-top--medium {
    margin-top: 30px; }
    @media screen and (min-width: 768px) {
      .area.margin-top--medium {
        margin-top: calc(30px + 30 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area.margin-top--medium {
        margin-top: 60px; } }
  .area.margin-top--large {
    margin-top: 50px; }
    @media screen and (min-width: 768px) {
      .area.margin-top--large {
        margin-top: calc(50px + 50 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area.margin-top--large {
        margin-top: 100px; } }
  .area.margin-top--xlarge {
    margin-top: 80px; }
    @media screen and (min-width: 768px) {
      .area.margin-top--xlarge {
        margin-top: calc(80px + 80 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area.margin-top--xlarge {
        margin-top: 160px; } }
  .area.margin-bottom--none {
    margin-bottom: 0; }
  .area.margin-bottom--tiny {
    margin-bottom: 15px; }
  .area.margin-bottom--small {
    margin-bottom: 30px; }
  .area.margin-bottom--medium {
    margin-bottom: 30px; }
    @media screen and (min-width: 768px) {
      .area.margin-bottom--medium {
        margin-bottom: calc(30px + 30 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area.margin-bottom--medium {
        margin-bottom: 60px; } }
  .area.margin-bottom--large {
    margin-bottom: 50px; }
    @media screen and (min-width: 768px) {
      .area.margin-bottom--large {
        margin-bottom: calc(50px + 50 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area.margin-bottom--large {
        margin-bottom: 100px; } }
  .area.margin-bottom--xlarge {
    margin-bottom: 80px; }
    @media screen and (min-width: 768px) {
      .area.margin-bottom--xlarge {
        margin-bottom: calc(80px + 80 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area.margin-bottom--xlarge {
        margin-bottom: 160px; } }
  .area > *:first-child {
    margin-top: 0; }
  .area > *:last-child {
    margin-bottom: 0; }
  .area.is-narrow {
    margin-left: auto;
    margin-right: auto;
    max-width: 47.05882em; }
  .area.full-width {
    position: relative;
    width: 100vw;
    width: calc(100vw - var(--scrollbarWidth));
    left: 50%;
    transform: translateX(-50%); }
  .area--thumbnail img, .area--thumbnail video {
    display: block;
    width: 100%;
    height: auto; }
  .area--thumbnail--copyright:before {
    display: inline;
    content: '©'; }
  .area a:not(.btn) {
    display: inline-block;
    position: relative;
    color: #004563;
    font-weight: 500;
    transition: color .3s; }
    .area a:not(.btn):after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: #004563;
      transition: height .3s;
      z-index: -1; }
    .area a:not(.btn):hover {
      color: #fff; }
      .area a:not(.btn):hover:after {
        height: 100%; }
    .area a:not(.btn)[target="_blank"] {
      display: inline;
      position: static;
      font-weight: 300;
      text-decoration: underline;
      transition: opacity .3s; }
      .area a:not(.btn)[target="_blank"]:hover {
        color: #004563;
        opacity: .5; }
      .area a:not(.btn)[target="_blank"]:after {
        display: none; }
  .area ul {
    list-style-type: none;
    margin: 0 0 1em;
    padding: 0; }
    .area ul li {
      position: relative;
      margin-bottom: 6px;
      padding-left: 22px; }
      .area ul li:last-child {
        margin-bottom: 0; }
      .area ul li:before {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 9px;
        border-left: 8px solid #004563;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent; }
      .area ul li > ul {
        margin: 5px 0 0; }
  .area.highlight a:not(.btn), .area .highlight a:not(.btn) {
    color: #fff; }
    .area.highlight a:not(.btn):after, .area .highlight a:not(.btn):after {
      background-color: #fff; }
    .area.highlight a:not(.btn):hover, .area .highlight a:not(.btn):hover {
      color: #004563; }
    .area.highlight a:not(.btn)[target="_blank"]:hover, .area .highlight a:not(.btn)[target="_blank"]:hover {
      color: #fff;
      opacity: .6; }
  .area.highlight ul li:before, .area .highlight ul li:before {
    border-left-color: #fff; }
  .area.fade-in.has-fade-in--left {
    animation: fade-in-left 0.8s cubic-bezier(0.33, 1, 0.68, 1); }
  .area.fade-in.has-fade-in--top {
    animation: fade-in-top 0.8s cubic-bezier(0.33, 1, 0.68, 1); }
  .area.fade-in.has-fade-in--right {
    animation: fade-in-right 0.8s cubic-bezier(0.33, 1, 0.68, 1); }
  .area.fade-in.has-fade-in--bottom {
    animation: fade-in-bottom 0.8s cubic-bezier(0.33, 1, 0.68, 1); }
  .area.full-width.fade-in.has-fade-in--left, .area.area--textmedia:not(.is-narrow).fade-in.has-fade-in--left, .area.area--textmedia-special.fade-in.has-fade-in--left, .area.area--teaser.is-slider:not(.is-narrow).fade-in.has-fade-in--left, .area.area--interactive-map:not(.is-narrow).fade-in.has-fade-in--left {
    animation: fade-in-left-fw 0.8s cubic-bezier(0.33, 1, 0.68, 1); }
  .area.full-width.fade-in.has-fade-in--top, .area.area--textmedia:not(.is-narrow).fade-in.has-fade-in--top, .area.area--textmedia-special.fade-in.has-fade-in--top, .area.area--teaser.is-slider:not(.is-narrow).fade-in.has-fade-in--top, .area.area--interactive-map:not(.is-narrow).fade-in.has-fade-in--top {
    animation: fade-in-top-fw 0.8s cubic-bezier(0.33, 1, 0.68, 1); }
  .area.full-width.fade-in.has-fade-in--right, .area.area--textmedia:not(.is-narrow).fade-in.has-fade-in--right, .area.area--textmedia-special.fade-in.has-fade-in--right, .area.area--teaser.is-slider:not(.is-narrow).fade-in.has-fade-in--right, .area.area--interactive-map:not(.is-narrow).fade-in.has-fade-in--right {
    animation: fade-in-right-fw 0.8s cubic-bezier(0.33, 1, 0.68, 1); }
  .area.full-width.fade-in.has-fade-in--bottom, .area.area--textmedia:not(.is-narrow).fade-in.has-fade-in--bottom, .area.area--textmedia-special.fade-in.has-fade-in--bottom, .area.area--teaser.is-slider:not(.is-narrow).fade-in.has-fade-in--bottom, .area.area--interactive-map:not(.is-narrow).fade-in.has-fade-in--bottom {
    animation: fade-in-bottom-fw 0.8s cubic-bezier(0.33, 1, 0.68, 1); }

.area--text {
  font-weight: 300; }
  .area--text h1, .area--text h2, .area--text h3, .area--text h4, .area--text h5, .area--text h6,
  .area--text .h1, .area--text .h2, .area--text .h3, .area--text .h4, .area--text .h5, .area--text .h6 {
    margin-bottom: .5em; }
  .area--text p small {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400; }

.area--textmedia {
  position: relative;
  width: 100vw;
  width: calc(100vw - var(--scrollbarWidth));
  left: 50%;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 1920px;
  transform: translateX(-50%);
  /*** Special Text + Media ***/ }
  @media (max-width: 767.98px) {
    .area--textmedia {
      padding-left: 0;
      padding-right: 0; } }
  .area--textmedia.is-narrow {
    left: auto;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    transform: translateX(0); }
  .area--textmedia > .cols,
  .area--textmedia > a > .cols {
    position: relative;
    background: #F2F6F7;
    z-index: 1;
    grid-column-gap: 0;
    grid-row-gap: 0; }
    @media (max-width: 991.98px) {
      .area--textmedia > .cols,
      .area--textmedia > a > .cols {
        grid-template-columns: 100%; } }
  .area--textmedia > a:not(.btn) {
    display: inline;
    font-weight: inherit;
    position: static;
    transition: none; }
    .area--textmedia > a:not(.btn):after {
      display: none; }
    .area--textmedia > a:not(.btn):hover {
      color: #004563; }
      .area--textmedia > a:not(.btn):hover .area--thumbnail img,
      .area--textmedia > a:not(.btn):hover .area--thumbnail video {
        transform: scale(1.1); }
      .area--textmedia > a:not(.btn):hover .btn {
        left: 2px;
        top: 2px; }
        .area--textmedia > a:not(.btn):hover .btn:after {
          left: 0;
          top: 0; }
        .area--textmedia > a:not(.btn):hover .btn-primary {
          border-color: #E5ECEF;
          background-color: #fff;
          color: #FF9C59; }
          .area--textmedia > a:not(.btn):hover .btn-primary .icon {
            filter: invert(66%) sepia(62%) saturate(557%) hue-rotate(327deg) brightness(100%) contrast(107%); }
        .area--textmedia > a:not(.btn):hover .btn-secondary {
          background-color: #fff;
          border-color: #E5ECEF;
          color: #004563; }
          .area--textmedia > a:not(.btn):hover .btn-secondary .icon {
            filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
        .area--textmedia > a:not(.btn):hover .btn-outline {
          background-color: #0B5C7F;
          border-color: #fff;
          color: #fff; }
          .area--textmedia > a:not(.btn):hover .btn-outline .icon {
            filter: saturate(0) brightness(0) invert(100%) opacity(1); }
  .area--textmedia .area--thumbnail {
    position: relative;
    margin: 0;
    overflow: hidden; }
    @media (min-width: 992px) {
      .area--textmedia .area--thumbnail:before {
        display: block;
        content: '';
        width: 100%;
        height: 0;
        padding-bottom: 50%; } }
    .area--textmedia .area--thumbnail a:not(.btn) {
      display: inline;
      position: static; }
      .area--textmedia .area--thumbnail a:not(.btn):after {
        display: none; }
    @media (min-width: 992px) {
      .area--textmedia .area--thumbnail iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } }
    @media (max-width: 767.98px) {
      .area--textmedia .area--thumbnail iframe {
        height: 25vh;
        aspect-ratio: 4 / 3; } }
    .area--textmedia .area--thumbnail img,
    .area--textmedia .area--thumbnail video {
      transition: transform .3s; }
      @media (min-width: 992px) {
        .area--textmedia .area--thumbnail img,
        .area--textmedia .area--thumbnail video {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover; } }
  .area--textmedia .teaser--offer {
    right: 0;
    margin-right: 60px; }
    @media (min-width: 992px) {
      .area--textmedia .teaser--offer {
        right: 50%; } }
  .area--textmedia .area--text {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: 300; }
    .area--textmedia .area--text--inner {
      width: 100%; }
      .area--textmedia .area--text--inner {
        padding-left: 50px; }
        @media screen and (min-width: 992px) {
          .area--textmedia .area--text--inner {
            padding-left: calc(50px + 100 * ((100vw - 992px) / 608)); } }
        @media screen and (min-width: 1600px) {
          .area--textmedia .area--text--inner {
            padding-left: 150px; } }
      .area--textmedia .area--text--inner {
        padding-top: 30px; }
        @media screen and (min-width: 992px) {
          .area--textmedia .area--text--inner {
            padding-top: calc(30px + 70 * ((100vw - 992px) / 608)); } }
        @media screen and (min-width: 1600px) {
          .area--textmedia .area--text--inner {
            padding-top: 100px; } }
      .area--textmedia .area--text--inner {
        padding-right: 30px; }
        @media screen and (min-width: 992px) {
          .area--textmedia .area--text--inner {
            padding-right: calc(30px + 70 * ((100vw - 992px) / 608)); } }
        @media screen and (min-width: 1600px) {
          .area--textmedia .area--text--inner {
            padding-right: 100px; } }
      .area--textmedia .area--text--inner {
        padding-bottom: 30px; }
        @media screen and (min-width: 992px) {
          .area--textmedia .area--text--inner {
            padding-bottom: calc(30px + 70 * ((100vw - 992px) / 608)); } }
        @media screen and (min-width: 1600px) {
          .area--textmedia .area--text--inner {
            padding-bottom: 100px; } }
      @media (max-width: 991.98px) {
        .area--textmedia .area--text--inner {
          padding: 30px 30px 30px 62px; } }
      .area--textmedia .area--text--inner > *:first-child {
        margin-top: 0; }
      .area--textmedia .area--text--inner > *:last-child {
        margin-bottom: 0; }
    .area--textmedia .area--text h1, .area--textmedia .area--text .h1,
    .area--textmedia .area--text h2, .area--textmedia .area--text .h2,
    .area--textmedia .area--text h3, .area--textmedia .area--text .h3 {
      position: relative; }
      .area--textmedia .area--text h1:before, .area--textmedia .area--text .h1:before,
      .area--textmedia .area--text h2:before, .area--textmedia .area--text .h2:before,
      .area--textmedia .area--text h3:before, .area--textmedia .area--text .h3:before {
        position: absolute;
        display: block;
        content: '';
        right: 100%;
        transform: translateY(-50%);
        border-left: 20px solid #FF9C59;
        border-top: 13px solid transparent;
        border-bottom: 13px solid transparent; }
        .area--textmedia .area--text h1:before, .area--textmedia .area--text .h1:before,
        .area--textmedia .area--text h2:before, .area--textmedia .area--text .h2:before,
        .area--textmedia .area--text h3:before, .area--textmedia .area--text .h3:before {
          margin-right: 15px; }
          @media screen and (min-width: 992px) {
            .area--textmedia .area--text h1:before, .area--textmedia .area--text .h1:before,
            .area--textmedia .area--text h2:before, .area--textmedia .area--text .h2:before,
            .area--textmedia .area--text h3:before, .area--textmedia .area--text .h3:before {
              margin-right: calc(15px + 15 * ((100vw - 992px) / 608)); } }
          @media screen and (min-width: 1600px) {
            .area--textmedia .area--text h1:before, .area--textmedia .area--text .h1:before,
            .area--textmedia .area--text h2:before, .area--textmedia .area--text .h2:before,
            .area--textmedia .area--text h3:before, .area--textmedia .area--text .h3:before {
              margin-right: 30px; } }
        @media (max-width: 991.98px) {
          .area--textmedia .area--text h1:before, .area--textmedia .area--text .h1:before,
          .area--textmedia .area--text h2:before, .area--textmedia .area--text .h2:before,
          .area--textmedia .area--text h3:before, .area--textmedia .area--text .h3:before {
            margin-right: 14px;
            border-left-width: 17px;
            border-top-width: 11px;
            border-bottom-width: 11px; } }
    .area--textmedia .area--text h1:before, .area--textmedia .area--text .h1:before {
      top: .54166em; }
    .area--textmedia .area--text h2:before, .area--textmedia .area--text .h2:before {
      top: .55em; }
    .area--textmedia .area--text h3:before, .area--textmedia .area--text .h3:before {
      top: .58333em; }
    .area--textmedia .area--text .teaser--date {
      margin: 30px 0; }
    @media (max-width: 767.98px) {
      .area--textmedia .area--text p, .area--textmedia .area--text ul {
        font-size: 15px; } }
    .area--textmedia .area--text .btn-primary:hover {
      background-color: #F2F6F7; }
    .area--textmedia .area--text .btn-outline {
      background-color: #F2F6F7; }
      .area--textmedia .area--text .btn-outline:hover {
        background-color: #0B5C7F; }
  .area--textmedia.highlight > .cols,
  .area--textmedia.highlight > a > .cols {
    background: #004563; }
  .area--textmedia.highlight > a:hover .btn-secondary {
    background-color: #004563;
    border-color: #fff;
    color: #fff; }
    .area--textmedia.highlight > a:hover .btn-secondary .icon {
      filter: saturate(0) brightness(0) invert(100%) opacity(1); }
  .area--textmedia.highlight > a:hover .btn-outline {
    background-color: #fff;
    color: #004563; }
    .area--textmedia.highlight > a:hover .btn-outline .icon {
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
  .area--textmedia.highlight .area--text {
    color: #fff; }
    .area--textmedia.highlight .area--text .btn-outline {
      background-color: #004563;
      color: #fff; }
      .area--textmedia.highlight .area--text .btn-outline .icon {
        filter: saturate(0) brightness(0) invert(100%) opacity(1); }
      .area--textmedia.highlight .area--text .btn-outline:hover {
        background-color: #fff;
        color: #004563; }
        .area--textmedia.highlight .area--text .btn-outline:hover .icon {
          filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
  .area--textmedia .thumbnail--right .teaser--offer {
    right: 0; }
  @media (min-width: 992px) {
    .area--textmedia .thumbnail--right [class*="col"], .area--textmedia-special .thumbnail--right [class*="col"] {
      order: 1; } }
  @media (min-width: 992px) {
    .area--textmedia .thumbnail--right [class*="col"]:first-child, .area--textmedia-special .thumbnail--right [class*="col"]:first-child {
      order: 2; } }
  .area--textmedia-special {
    position: relative;
    width: 100vw;
    width: calc(100vw - var(--scrollbarWidth));
    left: 50%;
    transform: translateX(-50%); }
    .area--textmedia-special > .cols {
      padding-top: 4.47917vw;
      grid-column-gap: 0;
      grid-row-gap: 0; }
      @media (max-width: 767.98px) {
        .area--textmedia-special > .cols {
          grid-template-columns: 100%;
          padding-top: 16vw; } }
    .area--textmedia-special .area--thumbnail {
      position: relative;
      align-self: start; }
      @media (max-width: 767.98px) {
        .area--textmedia-special .area--thumbnail {
          padding-bottom: 16vw; } }
      .area--textmedia-special .area--thumbnail:before, .area--textmedia-special .area--thumbnail:after,
      .area--textmedia-special .area--thumbnail .bottom-rhombus:before, .area--textmedia-special .area--thumbnail .bottom-rhombus:after {
        display: block;
        content: '';
        position: absolute;
        background-position: 0 0;
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none; }
      .area--textmedia-special .area--thumbnail:before,
      .area--textmedia-special .area--thumbnail .bottom-rhombus:before {
        top: 0;
        right: 0;
        width: 25vw;
        height: 32.03125vw;
        background-image: svg-load("../layout/svg/rhombus-overlay-img.svg", fill= "");
        z-index: 1; }
        @media (max-width: 767.98px) {
          .area--textmedia-special .area--thumbnail:before,
          .area--textmedia-special .area--thumbnail .bottom-rhombus:before {
            width: 48vw;
            height: 30.66667vw;
            background-image: svg-load("../layout/svg/rhombus-overlay-img_mobile.svg", fill= ""); } }
      .area--textmedia-special .area--thumbnail:after,
      .area--textmedia-special .area--thumbnail .bottom-rhombus:after {
        top: -4.47917vw;
        left: 100%;
        width: 50.3125vw;
        height: 50vw;
        transform: translateX(-0.3125vw);
        background-image: svg-load("../layout/svg/rhombus-overlay-text.svg", fill= "");
        z-index: 0; }
        @media (max-width: 767.98px) {
          .area--textmedia-special .area--thumbnail:after,
          .area--textmedia-special .area--thumbnail .bottom-rhombus:after {
            left: 0;
            top: 0;
            width: 100vw;
            height: 16vw;
            transform: translate(0, -100%);
            background-image: svg-load("../layout/svg/rhombus-overlay-text_mobile.svg", fill= ""); } }
      .area--textmedia-special .area--thumbnail > a:not(.btn) {
        display: inline;
        position: static; }
        .area--textmedia-special .area--thumbnail > a:not(.btn):after {
          display: none; }
      .area--textmedia-special .area--thumbnail .bottom-rhombus {
        display: none; }
        @media (max-width: 767.98px) {
          .area--textmedia-special .area--thumbnail .bottom-rhombus {
            display: block;
            position: relative; } }
        .area--textmedia-special .area--thumbnail .bottom-rhombus:before {
          left: 0;
          top: auto;
          right: auto;
          bottom: 0;
          transform: rotateX(180deg) rotateY(180deg); }
        @media (max-width: 767.98px) {
          .area--textmedia-special .area--thumbnail .bottom-rhombus:after {
            transform: translate(0) rotateX(180deg); } }
    .area--textmedia-special .area--text {
      position: relative;
      padding-top: 4.16667vw;
      z-index: 1; }
      .area--textmedia-special .area--text {
        padding-left: 15px; }
        @media screen and (min-width: 992px) {
          .area--textmedia-special .area--text {
            padding-left: calc(15px + 145 * ((100vw - 992px) / 608)); } }
        @media screen and (min-width: 1600px) {
          .area--textmedia-special .area--text {
            padding-left: 160px; } }
      .area--textmedia-special .area--text {
        padding-right: 15px; }
        @media screen and (min-width: 992px) {
          .area--textmedia-special .area--text {
            padding-right: calc(15px + 145 * ((100vw - 992px) / 608)); } }
        @media screen and (min-width: 1600px) {
          .area--textmedia-special .area--text {
            padding-right: 160px; } }
      @media (max-width: 767.98px) {
        .area--textmedia-special .area--text {
          padding: 40px; } }
      .area--textmedia-special .area--text .super {
        margin-bottom: 1em;
        padding: 15px;
        background: #fff;
        font-weight: 300; }
        @media (max-width: 767.98px) {
          .area--textmedia-special .area--text .super {
            padding: 0; } }
      .area--textmedia-special .area--text .lead {
        font-size: 25px; }
      .area--textmedia-special .area--text--inner {
        padding: 15px;
        background: #fff; }
        .area--textmedia-special .area--text--inner {
          margin-left: 30px; }
          @media screen and (min-width: 992px) {
            .area--textmedia-special .area--text--inner {
              margin-left: calc(30px + 145 * ((100vw - 992px) / 608)); } }
          @media screen and (min-width: 1600px) {
            .area--textmedia-special .area--text--inner {
              margin-left: 175px; } }
        @media (max-width: 767.98px) {
          .area--textmedia-special .area--text--inner {
            margin: 0;
            padding: 0; } }
      .area--textmedia-special .area--text--wrap {
        position: relative;
        z-index: 1; }
    @media (min-width: 768px) {
      .area--textmedia-special .thumbnail--right.cols {
        padding-top: 0;
        padding-bottom: 4.47917vw; } }
    @media (min-width: 768px) {
      .area--textmedia-special .thumbnail--right .area--thumbnail:before {
        left: 0;
        top: auto;
        right: auto;
        bottom: 0;
        transform: rotateX(180deg) rotateY(180deg); } }
    @media (min-width: 768px) {
      .area--textmedia-special .thumbnail--right .area--thumbnail:after {
        left: auto;
        top: auto;
        right: 100%;
        bottom: -4.47917vw;
        transform: translateX(0.3125vw) rotateX(180deg) rotateY(180deg); } }

.area--media .area--thumbnail {
  margin: 0; }
  .area--media .area--thumbnail--hint {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 50%;
    align-items: center;
    background: #fff;
    padding: 0 15px;
    max-width: 165px;
    height: 50px;
    transform: translate(-50%, 50%);
    border-radius: 25px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15); }
    .area--media .area--thumbnail--hint .icon {
      display: block;
      flex: 0 0 auto;
      margin-right: 5px;
      font-size: 30px;
      filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
      .area--media .area--thumbnail--hint .icon:only-child {
        margin-right: 0; }
    .area--media .area--thumbnail--hint span {
      display: block;
      flex: 1 1 auto;
      font-size: 12px;
      line-height: 1.16667; }

.area--media.has-text .area--thumbnail {
  display: flex;
  position: relative;
  place-items: center; }
  .area--media.has-text .area--thumbnail:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 1;
    opacity: .2;
    pointer-events: none; }
  .area--media.has-text .area--thumbnail > a:not(.btn) {
    display: inline;
    position: static; }
    .area--media.has-text .area--thumbnail > a:not(.btn):after {
      display: none; }
  .area--media.has-text .area--thumbnail img, .area--media.has-text .area--thumbnail video {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.area--media.has-text .area--media--text {
  margin: 0 auto;
  padding: 30px;
  position: relative;
  top: auto;
  left: auto;
  transform: translate(0, 0);
  width: 100%;
  max-width: 1200px; }

.area--media.has-text.ratio-1 .area--thumbnail {
  min-height: calc(100vw - var(--scrollbarWidth)); }

.area--media.has-text.ratio-0_6 .area--thumbnail {
  min-height: calc(150vw - var(--scrollbarWidth)); }

.area--media.has-text.ratio-1_5 .area--thumbnail {
  min-height: calc(66.66666vw - var(--scrollbarWidth)); }

.area--media.has-text.ratio-1_7 .area--thumbnail {
  min-height: calc(56.25vw - var(--scrollbarWidth)); }

.area--media.has-text.ratio-2_3 .area--thumbnail {
  min-height: calc(42.58714vw - var(--scrollbarWidth)); }

.area--media--text {
  position: absolute;
  padding: 0 30px;
  left: 50%;
  top: 50%;
  width: 1200px;
  max-width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 2; }
  .area--media--text .super {
    font-weight: 500; }
  .area--media--text p {
    margin: 0;
    font-size: 26px;
    line-height: 1.16;
    font-weight: 500;
    text-transform: uppercase; }
    .area--media--text p {
      font-size: 18px; }
      @media screen and (min-width: 768px) {
        .area--media--text p {
          font-size: calc(18px + 8 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .area--media--text p {
          font-size: 26px; } }
  .area--media--text .btn {
    margin-top: 30px; }
    .area--media--text .btn:after {
      opacity: .2; }

.area--media.premium-partner .area--thumbnail, .area--media.service-partner .area--thumbnail {
  position: relative; }
  .area--media.premium-partner .area--thumbnail:before, .area--media.service-partner .area--thumbnail:before {
    position: absolute;
    display: block;
    content: '';
    top: -20px;
    right: 30px;
    width: 160px;
    height: 160px;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    border-radius: 5px;
    overflow: hidden;
    z-index: 1; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .area--media.premium-partner .area--thumbnail:before, .area--media.service-partner .area--thumbnail:before {
        width: 120px;
        height: 120px; } }
    @media (max-width: 767.98px) {
      .area--media.premium-partner .area--thumbnail:before, .area--media.service-partner .area--thumbnail:before {
        top: -10px;
        right: 15px;
        width: 80px;
        height: 80px; } }
  .area--media.premium-partner .area--thumbnail > a, .area--media.service-partner .area--thumbnail > a {
    display: inline;
    position: static; }
    .area--media.premium-partner .area--thumbnail > a:after, .area--media.service-partner .area--thumbnail > a:after {
      display: none; }

.area--media.premium-partner .area--thumbnail:before {
  background-image: svg-load("../layout/svg/logo-premium-partner.svg", fill= ""); }

.area--media.service-partner .area--thumbnail:before {
  background-image: svg-load("../layout/svg/logo-service-partner.svg", fill= ""); }

@media (max-width: 767.98px) {
  .accommodation .area--media:not(.area--media--multiple) {
    position: relative;
    width: 100vw;
    width: calc(100vw - var(--scrollbarWidth));
    left: 50%;
    transform: translateX(-50%); } }

.area--teaser .cols {
  grid-column-gap: 30px;
  grid-row-gap: 30px; }
  @media (max-width: 767.98px) {
    .area--teaser .cols--2, .area--teaser .cols--3 {
      grid-template-columns: 100%; } }

.area--teaser.is-slider {
  position: relative;
  width: 100vw;
  width: calc(100vw - var(--scrollbarWidth));
  left: 50%;
  transform: translateX(-50%);
  padding-left: 25.2vw;
  padding-right: 25.2vw;
  background: #F2F6F7;
  overflow: visible;
  overflow-x: hidden; }
  .area--teaser.is-slider {
    padding-top: 80px; }
    @media screen and (min-width: 992px) {
      .area--teaser.is-slider {
        padding-top: calc(80px + 80 * ((100vw - 992px) / 608)); } }
    @media screen and (min-width: 1600px) {
      .area--teaser.is-slider {
        padding-top: 160px; } }
  .area--teaser.is-slider {
    padding-bottom: 80px; }
    @media screen and (min-width: 992px) {
      .area--teaser.is-slider {
        padding-bottom: calc(80px + 80 * ((100vw - 992px) / 608)); } }
    @media screen and (min-width: 1600px) {
      .area--teaser.is-slider {
        padding-bottom: 160px; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .area--teaser.is-slider {
      padding-left: 16vw;
      padding-right: 16vw; } }
  @media (max-width: 767.98px) {
    .area--teaser.is-slider {
      padding-left: 15px;
      padding-right: 15px; } }
  .area--teaser.is-slider .area--header {
    margin-bottom: 40px; }
    @media screen and (min-width: 768px) {
      .area--teaser.is-slider .area--header {
        margin-bottom: calc(40px + 40 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--teaser.is-slider .area--header {
        margin-bottom: 80px; } }
  .area--teaser.is-slider .splide__track {
    overflow: visible; }
  .area--teaser.is-slider .splide__list {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  .area--teaser.is-slider .splide__slide {
    margin: 0;
    padding: 0; }
    .area--teaser.is-slider .splide__slide.is-active .teaser {
      transform: scale(1);
      opacity: 1;
      filter: grayscale(0); }
      .area--teaser.is-slider .splide__slide.is-active .teaser--offer {
        opacity: 1;
        visibility: visible; }
    .area--teaser.is-slider .splide__slide:before {
      display: none; }
  .area--teaser.is-slider .splide__pagination {
    margin: 60px 0 0 0;
    padding: 0;
    width: 100%; }
    @media (max-width: 767.98px) {
      .area--teaser.is-slider .splide__pagination {
        margin-top: 40px; } }
    .area--teaser.is-slider .splide__pagination li {
      margin: 0 10px;
      padding: 0; }
      .area--teaser.is-slider .splide__pagination li:before {
        display: none; }
    .area--teaser.is-slider .splide__pagination__page {
      display: block;
      padding: 0;
      width: 14px;
      height: 14px;
      border: none;
      background: #004563;
      border-radius: 100%;
      opacity: .2;
      transform: scale(0.57143);
      transition: transform .3s, opacity .3s;
      cursor: pointer; }
      .area--teaser.is-slider .splide__pagination__page.is-active {
        transform: scale(1);
        opacity: 1; }
  .area--teaser.is-slider .teaser {
    transition: transform .3s, opacity .3s, filter .3s;
    opacity: .2;
    filter: grayscale(100%); }
    @media (min-width: 768px) {
      .area--teaser.is-slider .teaser {
        transform: scale(0.9); } }
    .area--teaser.is-slider .teaser--offer {
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s; }
  .area--teaser.is-slider .area--teaser--bottom {
    text-align: center; }
    .area--teaser.is-slider .area--teaser--bottom {
      margin-top: 20px; }
      @media screen and (min-width: 768px) {
        .area--teaser.is-slider .area--teaser--bottom {
          margin-top: calc(20px + 30 * ((100vw - 768px) / 432)); } }
      @media screen and (min-width: 1200px) {
        .area--teaser.is-slider .area--teaser--bottom {
          margin-top: 50px; } }
    .area--teaser.is-slider .area--teaser--bottom .btn-outline:not(:hover) {
      background: #F2F6F7; }

.area--teaser .area--textmedia > a {
  width: 100%; }

@media (min-width: 992px) {
  .area--teaser .area--textmedia:nth-child(even) [class*="col"] {
    order: 1; } }

@media (min-width: 992px) {
  .area--teaser .area--textmedia:nth-child(even) [class*="col"]:first-child {
    order: 2; } }

.area--teaser .area--textmedia:nth-child(even) .teaser--offer {
  right: 0; }

.area--teaser .area--textmedia .cols {
  grid-column-gap: 0;
  grid-row-gap: 0; }

.area--teaser .area--textmedia .teaser--date {
  display: block; }

.area--teaser .area--textmedia .teaser--buttons {
  margin-top: 10px; }
  .area--teaser .area--textmedia .teaser--buttons .btn {
    flex: 0 0 auto;
    margin: 20px 10px 0;
    text-align: center;
    white-space: nowrap; }

.area--teaser .teaser--horizontal {
  margin-bottom: 30px; }
  .area--teaser .teaser--horizontal:last-child {
    margin-bottom: 0; }

.area--content-container {
  padding-top: 60px; }
  @media screen and (min-width: 768px) {
    .area--content-container {
      padding-top: calc(60px + 40 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area--content-container {
      padding-top: 100px; } }

.area--content-container {
  padding-bottom: 60px; }
  @media screen and (min-width: 768px) {
    .area--content-container {
      padding-bottom: calc(60px + 40 * ((100vw - 768px) / 432)); } }
  @media screen and (min-width: 1200px) {
    .area--content-container {
      padding-bottom: 100px; } }

.area--content-container.highlight {
  background: #004563;
  color: #fff; }

.area--content-container--inner {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  z-index: 1; }
  .area--content-container--inner {
    padding-left: 30px; }
    @media screen and (min-width: 768px) {
      .area--content-container--inner {
        padding-left: calc(30px + 30 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--content-container--inner {
        padding-left: 60px; } }
  .area--content-container--inner {
    padding-right: 30px; }
    @media screen and (min-width: 768px) {
      .area--content-container--inner {
        padding-right: calc(30px + 30 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--content-container--inner {
        padding-right: 60px; } }
  .area--content-container--inner > *:first-child {
    margin-top: 0; }
  .area--content-container--inner > *:last-child {
    margin-bottom: 0; }

@media (max-width: 575.98px) {
  .area--tabs .tab-nav,
  .area--tabs .tab-content {
    display: none; } }

.area--tabs .area--accordion {
  display: none; }
  @media (max-width: 575.98px) {
    .area--tabs .area--accordion {
      display: block; } }

.area--blockquote {
  position: relative; }
  .area--blockquote .icon {
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 19px;
    height: 15px;
    transform: translateY(-50%);
    filter: invert(66%) sepia(62%) saturate(557%) hue-rotate(327deg) brightness(100%) contrast(107%);
    z-index: 2; }
  .area--blockquote blockquote {
    padding: 15px 0 15px 50px;
    font-weight: 300;
    font-style: italic;
    font-size: 25px;
    line-height: 1.4; }
    @media (max-width: 767.98px) {
      .area--blockquote blockquote {
        font-size: 20px; } }
    .area--blockquote blockquote:before, .area--blockquote blockquote:after {
      display: block;
      position: absolute;
      content: ''; }
    .area--blockquote blockquote:before {
      left: 8px;
      top: 0;
      bottom: 0;
      border-left: 3px solid #FF9C59; }
    .area--blockquote blockquote:after {
      top: 50%;
      left: 0;
      width: 19px;
      height: 35px;
      transform: translateY(-50%);
      background: #fff; }
    .area--blockquote blockquote p {
      margin-bottom: 1.2em; }
    .area--blockquote blockquote cite {
      display: block;
      font-size: 12px;
      line-height: 1.16667; }
      .area--blockquote blockquote cite:before {
        display: inline;
        content: '~ '; }
  .area--blockquote.highlight blockquote:after,
  .highlight .area--blockquote blockquote:after {
    background-color: #004563; }

.area--interactive-map {
  position: relative;
  width: 100vw;
  width: calc(100vw - var(--scrollbarWidth));
  left: 50%;
  transform: translateX(-50%);
  max-width: 1920px; }
  @media (min-width: 1920px) {
    .area--interactive-map .interactive-map {
      padding: 80px 0; } }
  @media (min-width: 1400px) and (max-width: 1919.98px) {
    .area--interactive-map .interactive-map {
      padding: calc(12.3vw - 171.7px) 0; } }
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .area--interactive-map .interactive-map {
      padding: calc(18vw - 160px) 0; } }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .area--interactive-map .interactive-map {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 20px 0 60px; } }
  .area--interactive-map .interactive-map--nav {
    position: relative;
    list-style-type: none;
    margin: 0;
    padding: 15px 15px 15px 0;
    pointer-events: none;
    z-index: 5; }
    @media (min-width: 1920px) {
      .area--interactive-map .interactive-map--nav {
        column-gap: 800px; } }
    @media (min-width: 1200px) {
      .area--interactive-map .interactive-map--nav {
        column-count: 2;
        column-gap: calc(40vw - 10px);
        padding: 25px; } }
    @media (max-width: 767.98px) {
      .area--interactive-map .interactive-map--nav {
        padding: 15px; } }
  .area--interactive-map .interactive-map--nav-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    margin: 0;
    padding-left: 20px;
    height: 120px;
    text-align: left;
    pointer-events: auto;
    cursor: pointer;
    transition: transform .3s, color .3s, background-color .3s; }
    @media (min-width: 1400px) {
      .area--interactive-map .interactive-map--nav-item {
        height: 180px; } }
    @media (max-width: 767.98px) {
      .area--interactive-map .interactive-map--nav-item {
        position: relative;
        margin-bottom: 15px;
        padding: 20px 0 20px 20px;
        height: auto;
        border: 1px solid #E5ECEF; } }
    @media (max-width: 767.98px) {
      .area--interactive-map .interactive-map--nav-item:last-child {
        margin-bottom: 0; } }
    @media (min-width: 768px) {
      .area--interactive-map .interactive-map--nav-item.is-active {
        background: #004563;
        color: #fff;
        transform: translateX(-25px); } }
    @media (min-width: 768px) {
      .area--interactive-map .interactive-map--nav-item.is-active .rhombus {
        color: #fff;
        filter: saturate(0) brightness(0) invert(100%) opacity(1); } }
    .area--interactive-map .interactive-map--nav-item:before {
      display: none; }
    @media (min-width: 1200px) {
      .area--interactive-map .interactive-map--nav-item:nth-child(-n+3) {
        padding: 0 30px 0 0;
        text-align: right; } }
    @media (min-width: 1200px) {
      .area--interactive-map .interactive-map--nav-item:nth-child(-n+3).is-active {
        transform: translateX(25px); } }
    @media (min-width: 1200px) {
      .area--interactive-map .interactive-map--nav-item:nth-child(-n+3) .rhombus {
        order: 2; } }
    @media (min-width: 1200px) {
      .area--interactive-map .interactive-map--nav-item:nth-child(-n+3) .description {
        order: 1; } }
    .area--interactive-map .interactive-map--nav-item .rhombus {
      width: 130px;
      flex: 0 0 auto;
      font-size: 12px;
      transition: color .3s, filter .3s; }
      @media (min-width: 1400px) {
        .area--interactive-map .interactive-map--nav-item .rhombus {
          width: 150px;
          font-size: 13px; } }
      @media (min-width: 1200px) and (max-width: 1399.98px) {
        .area--interactive-map .interactive-map--nav-item .rhombus {
          display: none; } }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .area--interactive-map .interactive-map--nav-item .rhombus {
          display: none; } }
      @media (max-width: 767.98px) {
        .area--interactive-map .interactive-map--nav-item .rhombus {
          width: 100px;
          font-size: 10px; } }
    .area--interactive-map .interactive-map--nav-item .description {
      padding: 0 20px; }
      .area--interactive-map .interactive-map--nav-item .description strong {
        display: block;
        margin-bottom: 5px;
        font-weight: 500;
        line-height: 1.15; }
        @media (min-width: 1400px) {
          .area--interactive-map .interactive-map--nav-item .description strong {
            margin-bottom: 10px;
            font-size: 20px; } }
      .area--interactive-map .interactive-map--nav-item .description p {
        margin: 0;
        font-size: 15px;
        line-height: 1.25;
        font-weight: 300; }
        @media (min-width: 1400px) {
          .area--interactive-map .interactive-map--nav-item .description p {
            line-height: 1.64;
            font-size: 17px; } }
        @media (max-width: 767.98px) {
          .area--interactive-map .interactive-map--nav-item .description p {
            line-height: 1.66667; } }
    .area--interactive-map .interactive-map--nav-item a:not(.btn) {
      display: none; }
      @media (max-width: 767.98px) {
        .area--interactive-map .interactive-map--nav-item a:not(.btn) {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0; }
          .area--interactive-map .interactive-map--nav-item a:not(.btn):after {
            display: none; } }
  .area--interactive-map .svg-map {
    display: flex;
    align-content: center;
    align-items: center;
    position: relative;
    flex: 0 0 calc(50% + 30px);
    margin: 0;
    padding: 80px;
    width: calc(50% + 30px);
    background: #F2F6F7;
    border-radius: 5px;
    text-align: center;
    z-index: 1; }
    .area--interactive-map .svg-map {
      padding-left: 40px; }
      @media screen and (min-width: 1400px) {
        .area--interactive-map .svg-map {
          padding-left: calc(40px + 40 * ((100vw - 1400px) / 520)); } }
      @media screen and (min-width: 1920px) {
        .area--interactive-map .svg-map {
          padding-left: 80px; } }
    .area--interactive-map .svg-map {
      padding-right: 40px; }
      @media screen and (min-width: 1400px) {
        .area--interactive-map .svg-map {
          padding-right: calc(40px + 40 * ((100vw - 1400px) / 520)); } }
      @media screen and (min-width: 1920px) {
        .area--interactive-map .svg-map {
          padding-right: 80px; } }
    @media (min-width: 1920px) {
      .area--interactive-map .svg-map {
        width: 810px; } }
    @media (min-width: 1200px) {
      .area--interactive-map .svg-map {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0 auto;
        width: 40vw;
        transform: translate(-50%, -50%); } }
    @media (max-width: 767.98px) {
      .area--interactive-map .svg-map {
        padding: 40px 15px;
        width: 100%; } }
    .area--interactive-map .svg-map--inner {
      position: relative;
      width: 100%; }
    .area--interactive-map .svg-map svg {
      display: inline-block;
      width: 100%;
      height: auto;
      overflow: visible; }
      .area--interactive-map .svg-map svg *[class*="lang-"] {
        display: none; }
        html[lang="de"] .area--interactive-map .svg-map svg *[class*="lang-"].lang-de {
          display: block; }
        html[lang="en"] .area--interactive-map .svg-map svg *[class*="lang-"].lang-en {
          display: block; }
        html[lang="it"] .area--interactive-map .svg-map svg *[class*="lang-"].lang-it {
          display: block; }
      .area--interactive-map .svg-map svg .svg-map--world {
        display: none;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility .3s;
        pointer-events: none; }
        @media (min-width: 768px) {
          .area--interactive-map .svg-map svg .svg-map--world {
            display: block; } }
        @media (min-width: 768px) {
          .area--interactive-map .svg-map svg .svg-map--world.is-active {
            opacity: 1;
            visibility: visible;
            pointer-events: auto; } }
    .area--interactive-map .svg-map--pano-links {
      display: none; }
      @media (min-width: 768px) {
        .area--interactive-map .svg-map--pano-links {
          display: block;
          pointer-events: none; } }
      .area--interactive-map .svg-map--pano-links > [class*="pano-links--"] {
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s, visibility .3s; }
        .area--interactive-map .svg-map--pano-links > [class*="pano-links--"].is-visible {
          opacity: 1;
          visibility: visible; }
      .area--interactive-map .svg-map--pano-links a {
        left: 0;
        top: 0;
        width: 1.33333em;
        height: 1.33333em;
        font-size: 30px;
        background: #FF9C59;
        border-radius: 100%;
        transform: translate(-50%, -50%);
        transform-origin: 0 0;
        pointer-events: auto;
        z-index: 2; }
        @media (max-width: 991.98px) {
          .area--interactive-map .svg-map--pano-links a {
            font-size: 24px; } }
        .area--interactive-map .svg-map--pano-links a, .area--interactive-map .svg-map--pano-links a:not(.btn)[target="_blank"] {
          display: block;
          position: absolute;
          opacity: 1;
          transition: background-color .3s, transform .3s, opacity .3s, visibility .3s; }
        .area--interactive-map .svg-map--pano-links a:hover {
          background: #004563;
          transform: scale(1.1) translate(-50%, -50%); }
        .area--interactive-map .svg-map--pano-links a:after {
          display: none; }
        .area--interactive-map .svg-map--pano-links a .icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          filter: saturate(0) brightness(0) invert(100%) opacity(1); }
        .area--interactive-map .svg-map--pano-links a.pano-link--relax--1 {
          left: 39.4%;
          top: 25.1%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--relax--2 {
          left: 25.1%;
          top: 58%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--family--1 {
          left: 73.5%;
          top: 54.8%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--family--2 {
          left: 62.5%;
          top: 67%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--family--3 {
          left: 85%;
          top: 60%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--family--4 {
          left: 67.4%;
          top: 77.5%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--sauna--1 {
          left: 49.1%;
          top: 14.5%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--sauna--2 {
          left: 39.4%;
          top: 29%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--sauna--3 {
          left: 59.2%;
          top: 32%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--sauna--4 {
          left: 47.4%;
          top: 51.5%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--sauna--5 {
          left: 46.8%;
          top: 71%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--ladies--1 {
          left: 36.5%;
          top: 78%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--sports--1 {
          left: 10%;
          top: 56%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--sports--2 {
          left: 12.8%;
          top: 66%; }
        .area--interactive-map .svg-map--pano-links a.pano-link--beauty--1 {
          left: 35%;
          top: 86%; }

.interactive-map--overlay {
  display: none; }
  @media (min-width: 768px) {
    .interactive-map--overlay {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.8);
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      transition: opacity .3s, visibility .3s;
      pointer-events: none;
      z-index: 999; }
      .interactive-map--overlay,
      .main-content .interactive-map--overlay {
        display: block; } }
  @media (min-width: 768px) {
    .interactive-map--overlay.is-active {
      opacity: 1;
      visibility: visible;
      pointer-events: auto; } }
  .interactive-map--overlay--inner {
    position: absolute;
    left: 50%;
    top: 50%;
    height: calc(100% - 120px);
    width: 90%;
    transform: translate(-50%, -50%);
    background: #000; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .interactive-map--overlay--inner {
        height: calc(100% - 60px); } }
  .interactive-map--overlay--close {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: #FF9C59;
    border: none;
    border-radius: 100%;
    transform: translate(50%, -50%);
    cursor: pointer;
    z-index: 5;
    transition: background-color .3s, transform .3s; }
    .interactive-map--overlay--close:hover {
      background: #004563;
      transform: translate(50%, -50%) scale(1.1); }
    .interactive-map--overlay--close:before, .interactive-map--overlay--close:after {
      position: absolute;
      display: block;
      content: '';
      left: 50%;
      top: 50%;
      width: 25px;
      height: 3px;
      background: #fff; }
    .interactive-map--overlay--close:before {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .interactive-map--overlay--close:after {
      transform: translate(-50%, -50%) rotate(45deg); }
  .interactive-map--overlay--container {
    position: relative;
    width: 100%;
    height: 100%; }
    .interactive-map--overlay--container:before {
      display: block;
      content: '';
      z-index: 1;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100px;
      height: 100px;
      transform: translate(-50%, -50%);
      transform-origin: 0 0;
      border: 10px solid rgba(255, 255, 255, 0.5);
      border-top-color: transparent;
      border-radius: 100%;
      animation: spin 1s linear infinite; }
    .interactive-map--overlay--container > * {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 2; }
    .interactive-map--overlay--container iframe {
      border: none; }

@keyframes spin {
  from {
    transform: rotate(0deg) translate(-50%, -50%); }
  to {
    transform: rotate(360deg) translate(-50%, -50%); } }

.area--services--inner {
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-column-gap: 30px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .area--services--inner {
      grid-template-columns: .8fr 1.2fr; } }
  @media (max-width: 767.98px) {
    .area--services--inner {
      display: block; } }

.area--services--col > .btn {
  margin-top: 30px;
  width: 100%;
  text-align: center;
  white-space: nowrap; }
  @media (max-width: 767.98px) {
    .area--services--col > .btn {
      margin-top: 15px;
      margin-bottom: 20px;
      white-space: normal; } }

.area--services .area--thumbnail a:not(.btn) {
  display: inline; }
  .area--services .area--thumbnail a:not(.btn):after {
    display: none; }

.area--services--data {
  border-top: 1px solid #E5ECEF; }
  @media (max-width: 991.98px) {
    .area--services--data {
      border-top: none; } }
  @media (max-width: 767.98px) {
    .area--services--data {
      font-size: 15px; } }

.area--services--data-row {
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: stretch;
  border-bottom: 1px solid #E5ECEF; }
  @media (max-width: 991.98px) {
    .area--services--data-row {
      flex-wrap: wrap;
      margin-bottom: 15px;
      border: 1px solid #E5ECEF; } }

.area--services--data-col {
  display: flex;
  flex-wrap: nowrap;
  align-content: stretch;
  align-items: stretch;
  flex: 0 0 auto; }
  @media (max-width: 991.98px) {
    .area--services--data-col {
      flex: 1 1 100%; } }
  @media (max-width: 991.98px) {
    .area--services--data-col:first-child {
      flex-basis: 100%;
      border-bottom: 1px solid #E5ECEF; } }
  .area--services--data-col:first-child, .area--services--data-col:first-child > *:first-child {
    flex-grow: 1;
    flex-shrink: 1; }

.area--services--data-cell {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  padding: 15px 20px; }
  @media (max-width: 767.98px) {
    .area--services--data-cell {
      padding: 10px 15px; } }
  @media (max-width: 991.98px) {
    .area--services--data-cell.text-cell strong {
      margin: 0; } }
  @media (max-width: 991.98px) {
    .area--services--data-cell.text-cell p {
      display: none; } }
  .area--services--data-cell.duration-cell {
    border-left: 1px solid #E5ECEF;
    border-right: 1px solid #E5ECEF; }
    @media (max-width: 991.98px) {
      .area--services--data-cell.duration-cell {
        border-right: none; } }
  .area--services--data-cell.mobile-only {
    display: none; }
    @media (max-width: 991.98px) {
      .area--services--data-cell.mobile-only {
        display: block;
        flex-grow: 1;
        flex-shrink: 1; } }
  .area--services--data-cell.price-cell {
    display: flex;
    flex-wrap: nowrap;
    min-width: 120px; }
    .area--services--data-cell.price-cell strong {
      flex-shrink: 1;
      text-align: right;
      white-space: nowrap; }
    .area--services--data-cell.price-cell .btn {
      flex-shrink: 1;
      margin-left: 20px;
      padding: 0.55em 0.7em; }
  .area--services--data-cell > * {
    flex: 0 0 100%; }
  .area--services--data-cell p, .area--services--data-cell strong {
    display: block;
    margin-bottom: 5px;
    line-height: 1.17647; }
    .area--services--data-cell p:last-child, .area--services--data-cell strong:last-child {
      margin-bottom: 0; }
  .area--services--data-cell p {
    font-weight: 300; }

.area--services details .area--services--table {
  border-top: none; }

@media (max-width: 991.98px) {
  .area--services details[open] summary {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; } }

.area--services details[open] summary:before {
  transform: rotate(180deg); }

.area--services details summary {
  margin-top: 10px;
  padding: 16px 0;
  background: #F2F6F7;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  line-height: 1.16667;
  list-style: none;
  -webkit-appearance: none; }
  .area--services details summary::-webkit-details-marker, .area--services details summary::marker {
    display: none; }
  .area--services details summary:before {
    display: inline-block;
    content: '';
    margin-right: 10px;
    border-top: 6px solid #004563;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent; }

.area--partner--categories .button-nav, .area--partner--categories .pagination, .area--partner--categories .button-nav ul, .area--partner--categories .pagination ul {
  margin-bottom: 0; }

@media (max-width: 767.98px) {
  .area--partner-box .cols--2 {
    grid-template-columns: 100%;
    grid-row-gap: 30px; } }

.area--icon-table.area--text table th:first-child,
.area--icon-table.area--text table td:first-child {
  padding-left: 20px; }

.area--icon-table.area--text table th:last-child,
.area--icon-table.area--text table td:last-child {
  padding-right: 20px; }

.area--icon-table.area--text table thead th:first-child {
  text-align: center; }

.area--icon-table.area--text table thead tr:not(:first-child) th {
  border-right: 1px solid #E5ECEF; }
  .area--icon-table.area--text table thead tr:not(:first-child) th:last-child {
    border-right: none; }

.area--icon-table.area--text table tbody td {
  padding-top: 15px;
  padding-bottom: 15px;
  border-right: 1px solid #E5ECEF; }
  .area--icon-table.area--text table tbody td:last-child {
    border-right: none; }

.area--icon-table.area--text table tbody tr td:first-child {
  width: 85px;
  text-align: center; }

.area--icon-table--icon {
  text-align: center; }
  .area--icon-table--icon .icon {
    margin: 0 auto 5px;
    display: block;
    font-size: 20px;
    filter: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%); }
  .area--icon-table--icon span {
    display: block;
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 400; }

.area--magazine {
  padding-left: 60px;
  padding-right: 60px; }
  .area--magazine, .area--magazine.is-narrow {
    position: relative;
    width: 100vw;
    width: calc(100vw - var(--scrollbarWidth));
    left: 50%;
    transform: translateX(-50%);
    max-width: none; }
  @media (max-width: 767.98px) {
    .area--magazine {
      padding-left: 0;
      padding-right: 0; } }
  .area--magazine .teaser .btn {
    flex-grow: 0;
    padding: 0.55em 1em; }
  @media (max-width: 767.98px) {
    .area--magazine .area--textmedia {
      margin-top: 0;
      margin-bottom: 0; } }
  @media (max-width: 767.98px) {
    .area--magazine .area--teaser {
      margin-top: 0; } }
  .area--magazine .area--teaser .cols {
    grid-column-gap: 30px; }
    @media screen and (min-width: 768px) {
      .area--magazine .area--teaser .cols {
        grid-column-gap: calc(30px + 30 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--magazine .area--teaser .cols {
        grid-column-gap: 60px; } }
  .area--magazine .area--teaser .cols {
    grid-row-gap: 30px; }
    @media screen and (min-width: 768px) {
      .area--magazine .area--teaser .cols {
        grid-row-gap: calc(30px + 30 * ((100vw - 768px) / 432)); } }
    @media screen and (min-width: 1200px) {
      .area--magazine .area--teaser .cols {
        grid-row-gap: 60px; } }
  @media (max-width: 767.98px) {
    .area--magazine .area--teaser .cols {
      grid-column-gap: 0;
      grid-row-gap: 0; } }
  .area--magazine .area--teaser .area--textmedia .cols {
    grid-column-gap: 0;
    grid-row-gap: 0; }

.area--map .map-container {
  position: relative;
  overflow: hidden; }
  .area--map .map-container:before {
    display: block;
    content: '';
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; }
    @media (max-width: 767.98px) {
      .area--map .map-container:before {
        padding-bottom: 100%; } }
  .area--map .map-container a, .area--map .map-container a:not(.btn), .area--map .map-container a:not(.btn)[target="_blank"] {
    text-decoration: none; }
    .area--map .map-container a:hover, .area--map .map-container a:not(.btn):hover, .area--map .map-container a:not(.btn)[target="_blank"]:hover {
      opacity: 1; }
    .area--map .map-container a:after, .area--map .map-container a:not(.btn):after, .area--map .map-container a:not(.btn)[target="_blank"]:after {
      display: none; }
  .area--map .map-container img {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1920px;
    height: auto; }
    @media (min-width: 1920px) {
      .area--map .map-container img {
        width: 100%; } }
    @media (min-width: 992px) and (max-width: 1199.98px) {
      .area--map .map-container img {
        width: 1200px; } }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .area--map .map-container img {
        width: 992px; } }
    @media (max-width: 767.98px) {
      .area--map .map-container img {
        width: 768px; } }

@media (max-width: 767.98px) {
  .area--author-box {
    position: relative;
    width: 100vw;
    width: calc(100vw - var(--scrollbarWidth));
    left: 50%;
    padding-right: 30px;
    transform: translateX(-50%); } }
