.author-box {
    position: relative;
    padding-top: 60px;

    @include media-breakpoint-down(md) {
        padding-top: 40px;
    }

    &:before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        right: -60px;
        width: 416px;
        aspect-ratio: 8 / 5;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 323 260'%3E%3Cpath stroke='%23FF9C59' stroke-width='3' d='M320.175 129.443 115.562 258.229-90.164 129.443 115.562 1.767l204.613 127.676Z'/%3E%3C/svg%3E") no-repeat 100% 0 transparent;
        background-size: contain;
        clip-path: polygon(0 0, 100% 0, 100% 75%, 85.576923% 75%, 85.576923% 48%, 60% 23.076923%, 0 23.076923%);
        pointer-events: none;
        z-index: 1;

        @include media-breakpoint-down(md) {
            top: 12px;
            right: -20px;
            width: 200px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 152 125'%3E%3Cpath stroke='%23FF9C59' stroke-width='3' d='m149.175 62.235-96.906 60.994-97.433-60.994L52.269 1.767l96.906 60.468Z'/%3E%3C/svg%3E");
            clip-path: polygon(0 0, 100% 0, 100% 75%, 90% 75%, 90% 48%, 60% 23.076923%, 0 23.076923%);
        }
    }

    a:not(.btn) {
        &, &:hover {
            &, .area & {
                color: inherit;
                text-decoration: none;

                &:after {
                    display: none;
                }
            }
        }
    }

    &--inner {
        display: flex;
        flex-wrap: nowrap;
        justify-content: stretch;
        @include fluid-property('column-gap', $viewport-width-min, $viewport-width-max, 30px, 60px);
        @include fluid-property('padding', $viewport-width-min, $viewport-width-max, 30px, 60px);
        background: $blue-superlight;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            padding: 0 30px 30px 40px;
        }
    }

        &--image {
            position: relative;
            flex-grow: 0;
            flex-shrink: 0;
            @include fluid-property('flex-basis', $viewport-width-min, $viewport-width-max, 180px, 260px);
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, -60px, -120px);
            z-index: 2;

            @include media-breakpoint-down(md) {
                flex-basis: 120px;
                margin: -40px 0 15px 0;
                width: 120px;
            }

            img {
                display: block;
                width: 100%;
                height: auto;
                clip-path: circle(50%);
            }
        }

        &--text {
            flex: 1 1 auto;

            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }

            h4, .h4 {
                margin-bottom: 1.15em;

                small {
                    font-size: 17px;
                    line-height: 1.64;
                    font-weight: $font-weight-normal;
                    font-style: italic;
                }
            }

            p, ul, ol {
                color: $black;
                font-weight: $font-weight-light;
                line-height: 1.64;

                @include media-breakpoint-down(md) {
                    font-size: 15px;
                }
            }
        }
}