
// Basic Variables
$font-size-root: 17px;
$line-height-base: 1.64;
$wrapper-max-width: (1800px / $font-size-root) * 1rem;

// Basic Colors
$white:    #fff !default;
$gray: #e0e0e0;
$gray-dark: #3F3F40;
$black:    #000 !default;

$blue: #004563;
$blue-lighter: #0B5C7F;
$blue-light: #E5ECEF;
$blue-superlight: #F2F6F7;
$orange: #FF9C59;
$orange-darker: #E28A4E;

$body-bg: $white;
$text-color: $blue;
$brand-primary: $orange;

// Icon color filters
$filter-white: saturate(0) brightness(0) invert(100%) opacity(1);
$filter-black: saturate(0) brightness(0) opacity(1);
$filter-blue: invert(19%) sepia(20%) saturate(4685%) hue-rotate(170deg) brightness(98%) contrast(101%);
$filter-orange: invert(66%) sepia(62%) saturate(557%) hue-rotate(327deg) brightness(100%) contrast(107%);

// Typography
$font-family-base: 'Work Sans', sans-serif;
$font-family-special: 'Lora', serif;
$font-size-base: $font-size-root;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$font-weight-bolder: 600;
$font-weight-boldest: 700;

$h1-font-size:                60px;
$h2-font-size:                50px;
$h3-font-size:                36px;
$h4-font-size:                26px;
$h5-font-size:                20px;
$h6-font-size:                $font-size-base !default;

$h1-min-font-size:            35px;
$h2-min-font-size:            30px;
$h3-min-font-size:            25px;
$h4-min-font-size:            18px;
$h5-min-font-size:            $h6-font-size;
$h6-min-font-size:            $h6-font-size;

$lead-font-size:			  25px;
$lead-min-font-size:		  16px;

$headings-margin-bottom: 1em;
$paragraph-margin-bottom: 1em;

// Fluid Typo Viewport
$viewport-width-min: 768px;
$viewport-width-max: 1200px;

// Containers
$container-padding: 60px;
$container-max-width: 1600px;
$container-max-width--narrow: 1140px;

// Grid
$grid-gutter-width: 30px;

// Links
$link-color: $blue;
$link-hover-color: $blue;
$link-decoration: none;
$link-hover-decoration: none;

// Tables
$table-padding-x: 20px;
$table-padding-y: 6px;
$table-border-color: $blue-light;
$table-head-color: $white;
$table-head-bg: $blue;
$table-head-secondary-color: $blue;
$table-head-secondary-bg: $blue-superlight;

// Buttons
$btn-font-family: $font-family-base;
$btn-font-size: 20px;
$btn-font-weight: $font-weight-normal;
$btn-line-height: 1;
$btn-padding: 0.55em 1em;
$btn-border-width: 1px;
$btn-border-radius: 5px;
$btn-disabled-opacity: 0.4;
$btn-primary-bg: $brand-primary;

// Forms
$form-gutter-width: 15px;
$formfield-color: $text-color;
$formfield-bg: $white;
$formfield-padding: 8px 12px;
$formfield-border-width: 1px;
$formfield-border-radius: 0;
$formfield-border-color: $black;
$formfield-focus-color: $text-color;
$formfield-focus-border-color: rgba($brand-primary, .5);
$formfield-focus-bg: $formfield-bg;

// Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px,
        outer: 1920px,
) !default;

$maxWidthDesktop: map-get($grid-breakpoints, xl);
$maxWidthDesktopPlus: map-get($grid-breakpoints, xxxl);
$outerLimit: map-get($grid-breakpoints, outer);