.area--services {
    &--inner {
        display: grid;
        grid-template-columns: 360px 1fr;
        grid-column-gap: 30px;

        @include media-breakpoint-between(md, lg) {
            grid-template-columns: .8fr 1.2fr;
        }

        @include media-breakpoint-down(md) {
            display: block;
        }
    }

        &--col {
            > .btn {
                margin-top: 30px;
                width: 100%;
                text-align: center;
                white-space: nowrap;

                @include media-breakpoint-down(md) {
                    margin-top: 15px;
                    margin-bottom: 20px;
                    white-space: normal;
                }
            }
        }

            .area--thumbnail {
                a:not(.btn) {
                    display: inline;

                    &:after {
                        display: none;
                    }
                }
            }

        &--data {
            border-top: 1px solid $blue-light;

            @include media-breakpoint-down(lg) {
                border-top: none;
            }

            @include media-breakpoint-down(md) {
                font-size: 15px;
            }
        }

            &--data-row {
                display: flex;
                flex-wrap: nowrap;
                align-content: stretch;
                align-items: stretch;
                border-bottom: 1px solid $blue-light;


                @include media-breakpoint-down(lg) {
                    flex-wrap: wrap;
                    margin-bottom: 15px;
                    border: 1px solid $blue-light;
                }
            }

                &--data-col {
                    display: flex;
                    flex-wrap: nowrap;
                    align-content: stretch;
                    align-items: stretch;
                    flex: 0 0 auto;

                    @include media-breakpoint-down(lg) {
                        flex: 1 1 100%;
                    }

                    &:first-child {
                        @include media-breakpoint-down(lg) {
                            flex-basis: 100%;
                            border-bottom: 1px solid $blue-light;
                        }

                        &, > *:first-child {
                            flex-grow: 1;
                            flex-shrink: 1;
                        }
                    }
                }

                    &--data-cell {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        align-content: center;
                        padding: 15px 20px;

                        @include media-breakpoint-down(md) {
                            padding: 10px 15px;
                        }

                        &.text-cell {
                            strong {
                                @include media-breakpoint-down(lg) {
                                    margin: 0;
                                }
                            }

                            p {
                                @include media-breakpoint-down(lg) {
                                    display: none;
                                }
                            }
                        }

                        &.duration-cell {
                            border-left: 1px solid $blue-light;
                            border-right: 1px solid $blue-light;

                            @include media-breakpoint-down(lg) {
                                border-right: none;
                            }
                        }

                        &.mobile-only {
                            display: none;

                            @include media-breakpoint-down(lg) {
                                display: block;
                                flex-grow: 1;
                                flex-shrink: 1;
                            }
                        }

                        &.price-cell {
                            display: flex;
                            flex-wrap: nowrap;
                            min-width: 120px;

                            strong {
                                flex-shrink: 1;
                                text-align: right;
                                white-space: nowrap;
                            }

                            .btn {
                                flex-shrink: 1;
                                margin-left: 20px;
                                padding: .55em to-em(14px, 20px);
                            }
                        }

                        > * {
                            flex: 0 0 100%;
                        }

                        p, strong {
                            display: block;
                            margin-bottom: 5px;
                            line-height: 1.17647;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        p {
                            font-weight: $font-weight-light;
                        }
                    }

        details {
            .area--services--table {
                border-top: none;
            }

            &[open] {
                summary {
                    @include media-breakpoint-down(lg) {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &:before {
                        transform: rotate(180deg);
                    }
                }
            }

            summary {
                margin-top: 10px;
                padding: 16px 0;
                background: $blue-superlight;
                border-radius: 5px;
                text-align: center;
                font-size: 12px;
                line-height: 1.16667;

                list-style: none;
                -webkit-appearance: none;

                &::-webkit-details-marker,
                &::marker {
                    display: none;
                }

                &:before {
                    display: inline-block;
                    content: '';
                    margin-right: 10px;
                    border-top: 6px solid $blue;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                }
            }
        }
}