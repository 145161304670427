.iconbox {
    a:not(.btn) {
        .area & {
            font-weight: inherit;
            position: static;

            &, &:hover, &:focus {
                color: inherit;
                text-decoration: none;
            }

            &:after {
                display: none;
            }
        }
    }

    &--inner {
        text-align: center;
    }

        &--icon {
            position: relative;
            margin-bottom: 40px;

            &:after {
                display: block;
                content: '';
                position: absolute;
                left: 50%;
                top: 100%;
                width: 15px;
                height: 0;
                border-top: 3px solid $orange;
                border-radius: 3px;
                transform: translate(-50%, 19px);
            }

            .icon {
                font-size: 60px;
                filter: $filter-blue;
            }
        }

        &--title {
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }

        &--text {
            font-weight: $font-weight-light;
        }
}