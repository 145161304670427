.area--teaser {
    .cols {
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        &--2,
        &--3 {
            @include media-breakpoint-down(md) {
                grid-template-columns: 100%;
            }
        }
    }

    &.is-slider {
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);
        @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 80px, 160px);
        @include fluid-property('padding-bottom', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 80px, 160px);
        padding-left: 25.2vw;
        padding-right: 25.2vw;
        background: $blue-superlight;
        overflow: visible;
        overflow-x: hidden;

        @include media-breakpoint-between(md, lg) {
            padding-left: 16vw;
            padding-right: 16vw;
        }

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
        }

        .area--header {
            @include fluid-property('margin-bottom', $viewport-width-min, $viewport-width-max, 40px, 80px);
        }

        .splide {
            &__track {
                overflow: visible;
            }

                &__list {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                }

                    &__slide {
                        margin: 0;
                        padding: 0;

                        &.is-active {
                            .teaser {
                                transform: scale(1);
                                opacity: 1;
                                filter: grayscale(0);

                                &--offer {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }

                        &:before {
                            display: none;
                        }
                    }

            &__pagination {
                margin: 60px 0 0 0;
                padding: 0;
                width: 100%;

                @include media-breakpoint-down(md) {
                    margin-top: 40px;
                }

                li {
                    margin: 0 10px;
                    padding: 0;

                    &:before {
                        display: none;
                    }
                }
                
                &__page {
                    display: block;
                    padding: 0;
                    width: 14px;
                    height: 14px;
                    border: none;
                    background: $blue;
                    border-radius: 100%;
                    opacity: .2;
                    transform: scale(.57143);
                    transition: transform .3s, opacity .3s;
                    cursor: pointer;

                    &.is-active {
                        transform: scale(1);
                        opacity: 1;
                    }
                }
            }
        }

        .teaser {
            transition: transform .3s, opacity .3s, filter .3s;
            opacity: .2;
            filter: grayscale(100%);

            @include media-breakpoint-up(md) {
                transform: scale(.9);
            }

            &--offer {
                opacity: 0;
                visibility: hidden;
                transition: opacity .3s, visibility .3s;
            }
        }

        .area--teaser--bottom {
            @include fluid-property('margin-top', $viewport-width-min, $viewport-width-max, 20px, 50px);
            text-align: center;

            .btn {
                &-outline {
                    &:not(:hover) {
                        background: $blue-superlight;
                    }
                }
            }
        }
    }

    .area--textmedia {

        > a {
            width: 100%;
        }

        &:nth-child(even) {
            [class*="col"] {
                @include media-breakpoint-up(lg) {
                    order: 1;
                }
            }

            [class*="col"]:first-child {
                @include media-breakpoint-up(lg) {
                    order: 2;
                }
            }

            .teaser--offer {
                right: 0;
            }
        }

        .cols {
            grid-column-gap: 0;
            grid-row-gap: 0;
        }

        .teaser {
            &--date {
                display: block;
            }

            &--buttons {
                margin-top: 10px;

                .btn {
                    flex: 0 0 auto;
                    margin: 20px 10px 0;
                    text-align: center;
                    white-space: nowrap;
                }
            }
        }
    }

    .teaser--horizontal {
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}