.accordion {
    margin-bottom: 10px;
    border: 1px solid $blue-superlight;

    &--header {
        position: relative;
        display: block;
        padding: 22px 20px 22px 60px;
        background: $blue-superlight none;
        color: $blue;
        font-size: 20px;
        line-height: 1.2;
        font-weight: $font-weight-bold;
        list-style: none;
        -webkit-appearance: none;
        transition: background-color .3s, color .3s;
        word-break: break-word;

        &::-webkit-details-marker,
        &::marker {
            display: none;
        }

        &:before {
            display: block;
            content: '';
            position: absolute;
            left: 15px;
            top: 50%;
            width: 30px;
            height: 30px;
            transform: translateY(-50%) rotate(-90deg);
            background-image: svg-load('arrow-down.svg', fill='');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            filter: $filter-blue;
            transition: transform .3s;

            .area--faq & {
                transform: translateY(-50%) rotate(0deg);
                background-image: svg-load('question.svg', fill='');
            }
        }

        &:hover {
            background: transparent;

            &:before {

            }
        }
    }

    &--content {
        padding: 30px 60px 60px;


        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    &[open] {
        .accordion--header {
            background-color: transparent;

            &:before {
                transform: translateY(-50%) rotate(0);

                .area--faq & {
                    transform: translateY(-50%) rotate(0deg);
                }
            }
        }
    }

}