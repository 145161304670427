[data-tooltip] {
    position: relative;

    &:before,
    &:after {
        display: block;
        position: absolute;
        bottom: 100%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity .3s, visibility .3s;
    }

    &:before {
        content: '';
        left: 20px;
        margin-bottom: 5px;
        border-top: 5px solid $white;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        z-index: 2;
    }

    &:after {
        content: attr(data-tooltip);
        left: 0;
        margin-bottom: 10px;
        padding: 5px;
        background: $white;
        border-radius: 5px;
        font-size: 12px;
        line-height: 14px;
        box-shadow: 0 0 30px rgba(0, 0, 0, .15);
        white-space: nowrap;
        font-weight: $font-weight-normal;
        z-index: 1;
    }

    &:hover {
        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }
}