.main-content {
    padding-top: 60px;
    padding-bottom: 80px;

    @include media-breakpoint-up(lg) {
        padding-top: 100px;
        padding-bottom: 130px;
    }

    @include media-breakpoint-down(md) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .frontpage & {
        padding-top: 80px;

        @include media-breakpoint-up(lg) {
            padding-top: 160px;
        }

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }
    }

    > .container {
        max-width: $container-max-width--narrow + ($container-padding * 2);

        @include media-breakpoint-down(md) {
            padding-left: 30px;
            padding-right: 30px;
        }

        > *:first-child {
            margin-top: 0;
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }

    @at-root  {
        :root {
            --scrollbarWidth: 0px;
        }
    }
}