.area--media {
    .area--thumbnail {
        margin: 0;

        &--hint {
            display: flex;
            position: absolute;
            bottom: 0;
            left: 50%;
            align-items: center;
            background: $white;
            padding: 0 15px;
            max-width: 165px;
            height: 50px;
            transform: translate(-50%, 50%);
            border-radius: 25px;
            box-shadow: 0 0 30px rgba(0, 0, 0, .15);

            .icon {
                display: block;
                flex: 0 0 auto;
                margin-right: 5px;
                font-size: 30px;
                filter: $filter-blue;

                &:only-child {
                    margin-right: 0;
                }
            }

            span {
                display: block;
                flex: 1 1 auto;
                font-size: 12px;
                line-height: 1.16667;
            }
        }
    }

    &.has-text {
        .area--thumbnail {
            display: flex;
            position: relative;
            place-items: center;

            &:before {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background: $black;
                z-index: 1;
                opacity: .2;
                pointer-events: none;
            }

            > a:not(.btn) {
                display: inline;
                position: static;

                &:after {
                    display: none;
                }
            }

            img, video {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .area--media--text {
            margin: 0 auto;
            padding: $grid-gutter-width;
            position: relative;
            top: auto;
            left: auto;
            transform: translate(0, 0);
            width: 100%;
            max-width: $container-max-width--narrow + (2 * $grid-gutter-width);
        }

        // square
        &.ratio-1 .area--thumbnail {
            min-height: calc(100vw - var(--scrollbarWidth));
        }
        // portrait
        &.ratio-0_6 .area--thumbnail {
            min-height: calc(150vw - var(--scrollbarWidth));
        }
        // landscape
        &.ratio-1_5 .area--thumbnail {
            min-height: calc(66.66666vw - var(--scrollbarWidth));
        }
        // wide
        &.ratio-1_7 .area--thumbnail {
            min-height: calc(56.25vw - var(--scrollbarWidth));
        }
        // pano
        &.ratio-2_3 .area--thumbnail {
            min-height: calc(42.58714vw - var(--scrollbarWidth));
        }
    }

        &--text {
            position: absolute;
            padding: 0 $grid-gutter-width;
            left: 50%;
            top: 50%;
            width: $container-max-width--narrow + (2 * $grid-gutter-width);
            max-width: 100%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: $white;
            z-index: 2;

            .super {
                font-weight: $font-weight-bold;
            }

            p {
                margin: 0;
                font-size: 26px;
                @include fluid-type($viewport-width-min, $viewport-width-max, 18px, 26px);
                line-height: 1.16;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
            }

            .btn {
                margin-top: 30px;

                &:after {
                    opacity: .2;
                }
            }
        }

    // Spezial für Hotel-Detail
    &.premium-partner,
    &.service-partner {
        .area--thumbnail {
            position: relative;

            &:before {
                position: absolute;
                display: block;
                content: '';
                top: -20px;
                right: 30px;
                width: 160px;
                height: 160px;
                background-repeat: no-repeat;
                background-position: 0 0;
                background-size: contain;
                border-radius: 5px;
                overflow: hidden;
                z-index: 1;

                @include media-breakpoint-between(md, lg) {
                    width: 120px;
                    height: 120px;
                }

                @include media-breakpoint-down(md) {
                    top: -10px;
                    right: 15px;
                    width: 80px;
                    height: 80px;
                }
            }

            > a {
                display: inline;
                position: static;

                &:after {
                    display: none;
                }
            }
        }
    }

    &.premium-partner {
        .area--thumbnail {
            &:before {
                background-image: svg-load('../layout/svg/logo-premium-partner.svg', fill='');
            }
        }
    }

    &.service-partner {
        .area--thumbnail {
            &:before {
                background-image: svg-load('../layout/svg/logo-service-partner.svg', fill='');
            }
        }
    }

    .accommodation & {
        &:not(.area--media--multiple) {
            @include media-breakpoint-down(md) {
                position: relative;
                @include fullWidthViewport;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}