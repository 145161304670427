.area--icon-table {
    &.area--text {
        table {
            th,
            td {
                &:first-child {
                    padding-left: $table-padding-x;
                }

                &:last-child {
                    padding-right: $table-padding-x;
                }
            }

            thead {
                th {
                    &:first-child {
                        text-align: center;
                    }
                }

                tr:not(:first-child) th {
                    border-right: 1px solid $table-border-color;

                    &:last-child {
                        border-right: none;
                    }
                }
            }

            tbody {
                td {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    border-right: 1px solid $table-border-color;

                    &:last-child {
                        border-right: none;
                    }
                }

                tr {
                    td {
                        &:first-child {
                            width: 85px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    &--icon {
        text-align: center;

        .icon {
            margin: 0 auto 5px;
            display: block;
            font-size: 20px;
            filter: $filter-blue;
        }

        span {
            display: block;
            font-size: 12px;
            line-height: 1.66667;
            font-weight: $font-weight-normal;
        }
    }
}