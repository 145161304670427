.area--textmedia {

    position: relative;
    @include fullWidthViewport;
    left: 50%;
    padding-left: $container-padding;
    padding-right: $container-padding;
    max-width: 1800px + ($container-padding * 2);
    transform: translateX(-50%);

    @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
    }

    &.is-narrow {
        left: auto;
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        transform: translateX(0);
    }

    > .cols,
    > a > .cols {
        position: relative;
        background: $blue-superlight;
        z-index: 1;
        grid-column-gap: 0;
        grid-row-gap: 0;

        @include media-breakpoint-down(lg) {
            grid-template-columns: 100%;
        }
    }

    > a:not(.btn) {
        display: inline;
        font-weight: inherit;
        position: static;
        transition: none;

        &:after {
            display: none;
        }

        &:hover {
            color: $blue;

            .area--thumbnail {
                img,
                video {
                    transform: scale(1.1);
                }
            }

            .btn {
                left: 2px;
                top: 2px;

                &:after {
                    left: 0;
                    top: 0;
                }

                &-primary {
                    border-color: $blue-light;
                    background-color: $white;
                    color: $orange;

                    .icon {
                        filter: $filter-orange;
                    }
                }

                &-secondary {
                    background-color: $white;
                    border-color: $blue-light;
                    color: $blue;

                    .icon {
                        filter: $filter-blue;
                    }
                }

                &-outline {
                    background-color: $blue-lighter;
                    border-color: $white;
                    color: $white;

                    .icon {
                        filter: $filter-white;
                    }
                }
            }
        }
    }

    .area--thumbnail {
        position: relative;
        margin: 0;
        overflow: hidden;

        &:before {
            @include media-breakpoint-up(lg) {
                display: block;
                content: '';
                width: 100%;
                height: 0;
                padding-bottom: 50%;
            }
        }

        a:not(.btn) {
            display: inline;
            position: static;

            &:after {
                display: none;
            }
        }
        @include media-breakpoint-up(lg) {
            iframe{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @include media-breakpoint-down(md) {
            iframe {
                height: 25vh;
                aspect-ratio: 4 / 3;
            }
        }

        img,
        video {
            transition: transform .3s;

            @include media-breakpoint-up(lg) {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

        .teaser--offer {
            right: 0;
            margin-right: 60px;

            @include media-breakpoint-up(lg) {
                right: 50%;
            }
        }

    .area--text {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        font-weight: 300;

        &--inner {
            width: 100%;
            @include fluid-property('padding-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 50px, 150px);
            @include fluid-property('padding-top', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 30px, 100px);
            @include fluid-property('padding-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 30px, 100px);
            @include fluid-property('padding-bottom', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 30px, 100px);

            @include media-breakpoint-down(lg) {
                padding: 30px 30px 30px 62px;
            }

            > *:first-child {
                margin-top: 0;
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }

        h1, .h1,
        h2, .h2,
        h3, .h3 {
            position: relative;

            &:before {
                position: absolute;
                display: block;
                content: '';
                right: 100%;
                @include fluid-property('margin-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 15px, 30px);
                transform: translateY(-50%);
                border-left: 20px solid $orange;
                border-top: 13px solid transparent;
                border-bottom: 13px solid transparent;

                @include media-breakpoint-down(lg) {
                    margin-right: 14px;
                    border-left-width: 17px;
                    border-top-width: 11px;
                    border-bottom-width: 11px;
                }
            }
        }

        h1, .h1 {
            &:before {
                top: .54166em;
            }
        }

        h2, .h2 {
            &:before {
                top: .55em;
            }
        }

        h3, .h3 {
            &:before {
                top: .58333em;
            }
        }

        .teaser--date {
            margin: 30px 0;
        }

        p, ul {
            @include media-breakpoint-down(md) {
                font-size: 15px;
            }
        }

        .btn {
            &-primary {
                &:hover {
                    background-color: $blue-superlight;
                }
            }

            &-secondary {

            }

            &-outline {
                background-color: $blue-superlight;

                &:hover {
                    background-color: $blue-lighter;
                }
            }
        }
    }

    // farbig hinterlegt
    &.highlight {
        > .cols,
        > a > .cols {
            background: $blue;
        }

        > a {
            &:hover {
                .btn {
                    &-secondary {
                        background-color: $blue;
                        border-color: $white;
                        color: $white;

                        .icon {
                            filter: $filter-white;
                        }
                    }

                    &-outline {
                        background-color: $white;
                        color: $blue;

                        .icon {
                            filter: $filter-blue;
                        }
                    }
                }
            }
        }

        .area--text {
            color: $white;

            .btn {
                &-outline {
                    background-color: $blue;
                    color: $white;

                    .icon {
                        filter: $filter-white;
                    }

                    &:hover {
                        background-color: $white;
                        color: $blue;

                        .icon {
                            filter: $filter-blue;
                        }
                    }
                }
            }
        }
    }

    .thumbnail--right {

        .teaser--offer {
            right: 0;
        }
    }

    &,
    &-special {
        .thumbnail--right {
            [class*="col"] {
                @include media-breakpoint-up(lg) {
                    order: 1;
                }
            }

            [class*="col"]:first-child {
                @include media-breakpoint-up(lg) {
                    order: 2;
                }
            }
        }
    }

    /*** Special Text + Media ***/
    &-special {
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);

        > .cols {
            padding-top: 4.47917vw; // 86px
            grid-column-gap: 0;
            grid-row-gap: 0;

            @include media-breakpoint-down(md) {
                grid-template-columns: 100%;
                padding-top: 16vw;
            }
        }

        .area--thumbnail {
            position: relative;
            align-self: start;

            @include media-breakpoint-down(md) {
                padding-bottom: 16vw;
            }

            &:before, &:after,
            .bottom-rhombus:before, .bottom-rhombus:after {
                display: block;
                content: '';
                position: absolute;
                background-position: 0 0;
                background-size: contain;
                background-repeat: no-repeat;
                pointer-events: none;
            }

            &:before,
            .bottom-rhombus:before {
                top: 0;
                right: 0;
                width: 25vw; // 480px
                height: 32.03125vw; // 615px
                background-image: svg-load('../layout/svg/rhombus-overlay-img.svg', fill='');
                z-index: 1;

                @include media-breakpoint-down(md) {
                    width: 48vw;
                    height: 30.66667vw;
                    background-image: svg-load('../layout/svg/rhombus-overlay-img_mobile.svg', fill='');
                }
            }

            &:after,
            .bottom-rhombus:after {
                top: -4.47917vw; // 86px;
                left: 100%;
                width: 50.3125vw; // 966px
                height: 50vw; // 960px
                transform: translateX(-.3125vw); // 6px
                background-image: svg-load('../layout/svg/rhombus-overlay-text.svg', fill='');
                z-index: 0;

                @include media-breakpoint-down(md) {
                    left: 0;
                    top: 0;
                    width: 100vw;
                    height: 16vw;
                    transform: translate(0, -100%);
                    background-image: svg-load('../layout/svg/rhombus-overlay-text_mobile.svg', fill='');
                }
            }

            > a:not(.btn) {
                display: inline;
                position: static;

                &:after {
                    display: none;
                }
            }

            .bottom-rhombus {
                display: none;

                @include media-breakpoint-down(md) {
                    display: block;
                    position: relative;
                }

                &:before {
                    left: 0;
                    top: auto;
                    right: auto;
                    bottom: 0;
                    transform: rotateX(180deg) rotateY(180deg);
                }

                &:after {
                    @include media-breakpoint-down(md) {
                        transform: translate(0) rotateX(180deg);
                    }
                }
            }
        }

        .area--text {
            position: relative;
            padding-top: 4.16667vw; // 80px
            @include fluid-property('padding-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 15px, 160px);
            @include fluid-property('padding-right', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 15px, 160px);
            z-index: 1;

            @include media-breakpoint-down(md) {
                padding: 40px;
            }

            .super {
                margin-bottom: 1em;
                padding: 15px;
                background: $white;
                font-weight: $font-weight-light;

                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }

            .lead {
                font-size: $lead-font-size;
            }

            &--inner {
                padding: 15px;
                @include fluid-property('margin-left', map-get($grid-breakpoints, lg), map-get($grid-breakpoints, xxxl), 30px, 175px);
                background: $white;

                @include media-breakpoint-down(md) {
                    margin: 0;
                    padding: 0;
                }
            }

                &--wrap {
                    position: relative;
                    z-index: 1;
                }
        }

        .thumbnail--right {
            &.cols {
                @include media-breakpoint-up(md) {
                    padding-top: 0;
                    padding-bottom: 4.47917vw; // 86px
                }
            }

            .area--thumbnail {
                &:before {
                    @include media-breakpoint-up(md) {
                        left: 0;
                        top: auto;
                        right: auto;
                        bottom: 0;
                        transform: rotateX(180deg) rotateY(180deg);
                    }
                }

                &:after {
                    @include media-breakpoint-up(md) {
                        left: auto;
                        top: auto;
                        right: 100%;
                        bottom: -4.47917vw; // 86px
                        transform: translateX(.3125vw) rotateX(180deg) rotateY(180deg);
                    }
                }
            }
        }
    }
}