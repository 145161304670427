.rhombus {
    display: block;
    position: relative;
    background: svg-load('../layout/svg/rhombus.svg', fill='') no-repeat 0 0 transparent;
    background-size: contain;
    width: 100%;
    color: $blue;
    text-align: center;
    font-size: 15px;
    font-weight: $font-weight-bolder;
    line-height: 1.2;

    &:before {
        display: block;
        content: '';
        width: 100%;
        height: 0;
        padding-bottom: 63.55218%;
    }

    span {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}