.area--map {
    .map-container {
        position: relative;
        overflow: hidden;

        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            @include media-breakpoint-down(md) {
                padding-bottom: 100%;
            }
        }

        a, a:not(.btn), a:not(.btn)[target="_blank"] {
            text-decoration: none;

            &:hover {
                opacity: 1;
            }

            &:after {
                display: none;
            }
        }

        img {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 1920px;
            height: auto;

            @include media-breakpoint-up(outer) {
                width: 100%;
            }

            @include media-breakpoint-only(lg) {
                width: 1200px;
            }

            @include media-breakpoint-only(md) {
                width: 992px;
            }

            @include media-breakpoint-down(md) {
                width: 768px;
            }
        }
    }
}