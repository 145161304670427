.page-footer {
    background: url(../images/layout/bg-footer.png) center 238px no-repeat $white;
    background-size: cover;

    @include media-breakpoint-down(md) {
        padding-bottom: 70px;
    }

    .container {
        max-width: $container-max-width--narrow + ($container-padding * 2);

        @include media-breakpoint-down(md) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    &--social {
        margin-bottom: 30px;
        padding: 30px 0;
        overflow: hidden;
        text-align: center;

        ul {
            display: inline-block;
            position: relative;
            margin: 0;
            padding: 0;
            list-style-type: none;

            &:before, &:after {
                display: block;
                content: '';
                position: absolute;
                top: 50%;
                width: 50vw;
                border-bottom: 1px solid $blue-light;
            }

            &:before {
                right: 100%;
                transform: translateX(-20px);

                @include media-breakpoint-down(md) {
                    transform: translateX(-15px);
                }
            }

            &:after {
                left: 100%;
                transform: translateX(20px);


                @include media-breakpoint-down(md) {
                    transform: translateX(15px);
                }
            }

            > li {
                display: inline-block;
                padding: 0 12px;

                @include media-breakpoint-down(md) {
                    padding: 0 5px;
                }

                > a {
                    position: relative;
                    display: block;
                    width: 60px;
                    height: 60px;
                    background: $white;
                    border-radius: 100%;
                    box-shadow: 0 0 30px rgba(0, 0, 0, .15);
                    transition: background-color .3s;

                    &:hover {
                        background: $blue;

                        .icon {
                            filter: $filter-white;
                        }
                    }

                    .icon {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 30px;
                        height: 30px;
                        transform: translate(-50%, -50%);
                        transition: filter .3s;
                    }
                }
            }
        }
    }

    &--teaser {
        .teaser {
            overflow: hidden;

            &--content {
                @include media-breakpoint-down(md) {
                    text-align: center;
                }

                h4, .h4 {
                    margin-bottom: 0;
                }
            }

            &--buttons {
                .btn {
                    @include media-breakpoint-down(md) {
                        white-space: normal;
                    }
                }
            }
        }
    }

    &--main {
        padding: 160px 0 60px;

        @include media-breakpoint-between(md, lg) {
            padding-top: 100px;
        }

        @include media-breakpoint-down(md) {
            padding: 60px 0 40px;
        }

        > .container {
            width: 100%;

            @include media-breakpoint-between(md, lg) {
                flex-wrap: wrap;
            }

            @include media-breakpoint-up(md) {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
        }

        h5, .h5 {
            margin-bottom: 1em;

            @include media-breakpoint-down(md) {
                text-align: center;
            }
        }

        address {
            @include media-breakpoint-down(lg) {
                text-align: center;
            }
        }

        .btn {
            display: block;
            margin-bottom: 20px;

            &-outline {
                background-color: #f2fafe;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

        &--col {
            position: relative;
            z-index: 1;

            @include media-breakpoint-up(md) {
                padding: 0 15px;
                min-width: 205px;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: 60px;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }
            }
        }

    &--address {
        @include media-breakpoint-between(md, lg) {
            flex: 0 0 100%;
            margin-bottom: 60px;
        }
    }

    &--buttons {
        flex: 0 1 380px;

        @include media-breakpoint-between(md, lg) {
            flex: 0 0 50%;
        }
    }

    &--links {
        flex: 0 1 350px;

        @include media-breakpoint-between(md, lg) {
            flex: 0 0 50%;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            column-count: 2;

            li {
                position: relative;
                margin-bottom: 15px;
                padding: 0 0 0 22px;
                font-size: 17px;
                line-height: 1.2;
                font-weight: $font-weight-light;

                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 5px;
                    border-left: 9px solid $blue;
                    border-top: 5px solid transparent;
                    border-bottom: 5px solid transparent;
                }

                a {
                    display: inline-block;
                    transition: color .3s;

                    &:hover {
                        color: $orange;
                    }
                }
            }
        }
    }

    &--info {
        position: relative;
        margin-top: 65px;
        padding: 8px 15px;
        flex: 0 0 100%;
        text-align: center;
        border: 1px solid $blue-light;

        @include media-breakpoint-down(md) {
            margin-top: 40px;
            padding-left: 20px;
            text-align: left;
        }

        p {
            margin: 0;
            font-size: 12px;
            line-height: 1.16667;
        }

        .icon {
            vertical-align: baseline;
            font-size: 11px;
            filter: $filter-blue;

            @include media-breakpoint-down(md) {
                position: absolute;
                left: 6px;
                top: 8px;
            }
        }
    }

    &--copyright {
        padding: 50px 0;
        font-size: 17px;
        line-height: 28px;
        white-space: nowrap;
        font-weight: $font-weight-bold;
        background: $white;

        @include media-breakpoint-down(md) {
            text-align: center;
            font-size: 15px;
            line-height: 25px;
        }

        > .container {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
        }

        p {
            margin: 0;

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }
    }
}

.conecto-copyright {
    width: 140px;
    height: 28px;
    background: svg-load('../layout/svg/site_by_CONECTO.svg', fill='') no-repeat center center transparent;
    background-size: contain;
    overflow: hidden;
    font-size: 0;
    text-indent: -9999em;

    @include media-breakpoint-down(md) {
        margin: 0 auto;
    }
}