@keyframes spin {
    from { transform: rotateZ(0deg) }
    to { transform: rotateZ(360deg) }
}

@keyframes bounce {
    0% { transform: translateY(0); }
    50% { transform: translateY(10px); }
    100% { transform: translateY(0); }
}

@keyframes fade-in-left {
    0%, 33% {
        opacity: 0;
        transform: translate3d(-50%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fade-in-left-fw {
    0%, 33% {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}

@keyframes fade-in-right {
    0%, 33% {
        opacity: 0;
        transform: translate3d(50%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fade-in-right-fw {
    0%, 33% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}

@keyframes fade-in-top {
    0%, 33% {
        opacity: 0;
        transform: translate3d(0, -15vh, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fade-in-top-fw {
    0%, 33% {
        opacity: 0;
        transform: translate3d(-50%, -15vh, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}

@keyframes fade-in-bottom {
    0%, 33% {
        opacity: 0;
        transform: translate3d(0, 15vh, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fade-in-bottom-fw {
    0%, 33% {
        opacity: 0;
        transform: translate3d(-50%, 15vh, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(-50%, 0, 0);
    }
}

@keyframes fade-in-bottom-special {
    0%, 33% {
        opacity: 0;
        transform: translate3d(-50%, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(-50%, -50%, 0);
    }
}