.breadcrumbs {
    padding: 20px 0;
    background: $blue-superlight;

    @include media-breakpoint-down(md) {
        padding: 23px 0;
    }

    .landingpage & {
        @include media-breakpoint-down(md) {
            padding: 13px 0;
        }
    }

    > .container {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;
        max-width: $container-max-width--narrow + ($container-padding  * 2);

        @include media-breakpoint-down(md) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    &--nav {
        margin-right: 20px;

        @include media-breakpoint-down(md) {
            margin-right: 0;
            width: 100%;
        }
    }

        &--list {
            display: flex;
            flex-wrap: nowrap;
            margin: 0;
            padding: 0;
            list-style-type: none;

            &:before {
                @include media-breakpoint-down(md) {
                    display: block;
                    content: '...';
                    padding-right: 10px;
                    font-size: 12px;
                    line-height: 1.16667;
                    color: rgba($blue, .5);
                    white-space: nowrap;
                }
            }
        }

            &--item {
                position: relative;
                padding: 0 13px 0 10px;

                @include media-breakpoint-down(md) {
                    display: none;
                }

                &:nth-last-child(2),
                &:last-child {
                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                }

                &:nth-last-child(2) {
                    @include media-breakpoint-down(md) {
                        padding-left: 14px;
                    }

                    &:before {
                        @include media-breakpoint-down(md) {
                            display: block;
                            left: 0;
                        }
                    }
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }

                &.is-active {
                    > a {
                        color: $blue;
                    }
                }

                &:nth-last-child(2):before,
                &:after {
                    display: none;
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 5px;
                    height: 100%;
                    background: svg-load('angle-right.svg', fill='') no-repeat center center transparent;
                    background-size: contain;
                    filter: $filter-blue;
                    opacity: .5;
                }

                &:after {
                    display: block;
                    right: 0;
                }

                > a {
                    display: block;
                    font-size: 12px;
                    line-height: 1.16667;
                    color: rgba($blue, .5);
                    white-space: nowrap;
                    transition: color .3s;

                    &:hover {
                        color: $blue;
                    }
                }
            }

    .lang-nav {
        justify-self: flex-end;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .landingpage & {
            @include media-breakpoint-down(md) {
                display: flex;
            }
        }

        > ul {
            > li {
                a {
                    padding: 0 5px;
                    min-width: 34px;
                    height: 34px;
                    line-height: 32px;
                }
            }
        }
    }

    .page-header--meta {
        position: relative;
        left: auto;
        right: auto;
        bottom: auto;

        a {
            color: $blue;
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(md) {
                font-size: 14px;
                line-height: 20px;
            }

            .icon {
                filter: $filter-blue;

                @include media-breakpoint-down(md) {
                    font-size: 16px;
                    margin-right: 3px;
                }
            }

            span {
                @include media-breakpoint-down(md) {
                    display: inline-block;
                }
            }
        }
    }
}