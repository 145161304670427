.area--text {
    font-weight: $font-weight-light;

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: .5em;
    }

    p {
        small {
            font-size: 12px;
            line-height: 14px;
            font-weight: $font-weight-normal;
        }
    }
}