.btn {
    position: relative;
    display: inline-block;
    left: 0;
    top: 0;
    margin: 0 5px 5px 0;
    padding: $btn-padding;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    color: $text-color;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    border-radius: $btn-border-radius;
    box-shadow: none;
    transition: background-color .3s, color .3s, border .3s, left .3s, top .3s,;

    &:after {
        position: absolute;
        display: block;
        content: '';
        left: 4px;
        top: 4px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        border-radius: $btn-border-radius;
        background: $blue-light;
        transition: left .3s, top .3s;
        z-index: -1;

        .highlight & {
            background: rgba($white, .15);
        }
    }

    &:hover {
        left: 2px;
        top: 2px;
        color: $text-color;
        text-decoration: none;

        &:after {
            top: 0;
            left: 0;
        }
    }

    &:focus {
        outline: 0;
        box-shadow: none;
    }

    &:active,
    &.active {

    }

    &:disabled,
    &.disabled {
        pointer-events: none;
        opacity: $btn-disabled-opacity;

        &:after {
            display: none;
        }
    }

    &.btn-block {
        display: block;
    }

    // Primary Button
    &-primary {
        @include custom-button-variant($brand-primary, $brand-primary, $white, $white, $blue-light, $orange);

        .icon {
            filter: $filter-white;
        }

        &:hover {
            .icon {
                filter: $filter-orange;
            }
        }
    }

    // Secondary Button
    &-secondary {
        @include custom-button-variant($blue, $white, $white, $white, $blue-light, $blue);

        .highlight & {
            background-color: $white;
            color: $blue;
        }

        .icon {
            filter: $filter-white;

            .highlight & {
                filter: $filter-blue;
            }
        }

        &:hover {
            .highlight & {
                background: $blue;
                color: $white;
            }

            .icon {
                filter: $filter-blue;

                .highlight & {
                    filter: $filter-white;
                }
            }
        }
    }

    // Outline Button
    &-outline {
        @include custom-button-variant($white, $blue, $blue, $blue-lighter, $white, $white);

        .highlight & {
            background-color: $blue;
            color: $white;
            border-color: $white;
        }

        .icon {
            filter: $filter-blue;

            .highlight & {
                filter: $filter-white;
            }
        }

        &:hover {
            .highlight & {
                background-color: $white;
                color: $blue;
            }

            .icon {
                filter: $filter-white;

                .highlight & {
                    filter: $filter-blue;
                }
            }
        }
    }

    // Large Button
    &.btn-lg {
        padding: .64em 1.2em;
        font-size: $btn-font-size * 1.25;

        &.icon-only {
            padding: .64em;
        }

        &.btn:not(.icon-only) .icon--angle-right {
            width: 10px;
            transform: translateY(-2px);
        }
    }

    // Small Button
    &.btn-sm {
        font-size: $btn-font-size * 0.9;
    }

    &.icon-only {
        padding: .55em;

        .icon {
            display: block;
        }
    }

    &:not(.icon-only) {
        .icon {
            transform: translateY(-1px);
        }
    }

    .icon {
        transition: filter .3s;

        &--angle-right {
            width: 7px;
        }
    }
}