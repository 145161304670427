body.magazin {
    .main-content {
        .area--text {
            h2 {
                @include fluid-type($viewport-width-min, $viewport-width-max, $h3-min-font-size, $h3-font-size);
                line-height: 1.16666;
                font-weight: $font-weight-bold;

                &.super {
                    @include fluid-type($viewport-width-min, $viewport-width-max, 30px, 80px);
                }

                @include media-breakpoint-down(md) {
                    font-weight: $font-weight-bolder;
                }
            }

            h3 {
                @include fluid-type($viewport-width-min, $viewport-width-max, $h4-min-font-size, $h4-font-size);
                line-height: 1.17308;
                font-weight: $font-weight-bolder;
            }

            h4 {
                @include fluid-type($viewport-width-min, $viewport-width-max, $h5-min-font-size, $h5-font-size);
                line-height: 1.173;
                font-weight: $font-weight-bold;
            }

            h5 {
                @include fluid-type($viewport-width-min, $viewport-width-max, $h6-min-font-size, $h6-font-size);
                font-weight: $font-weight-bold;
            }
        }
    }

    h1 {
        small.author {
            font-size: 16px;
            font-weight: $font-weight-normal;
        }
    }
}