.area--content-container {
    @include fluid-property('padding-top', $viewport-width-min, $viewport-width-max, 60px, 100px);
    @include fluid-property('padding-bottom', $viewport-width-min, $viewport-width-max, 60px, 100px);

    &.highlight {
        background: $blue;
        color: $white;
    }
}

.area--content-container--inner {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    z-index: 1;

    @include fluid-property('padding-left', $viewport-width-min, $viewport-width-max, 30px, 60px);
    @include fluid-property('padding-right', $viewport-width-min, $viewport-width-max, 30px, 60px);

    > *:first-child {
        margin-top: 0;
    }
    > *:last-child {
        margin-bottom: 0;
    }
}
