.main-nav {
    @include media-breakpoint-between(xl,xxxl) {
        padding-right: 40px;
    }

    @include media-breakpoint-down(xl) {
        position: fixed;
        top: 0;
        bottom: 0;
        padding: 55px 30px 60px;
        max-height: 100vh;
        background: $white;
        transition: transform .5s;
        overflow: hidden;
        overflow-y: auto;
        z-index: 9999;
    }

    @include media-breakpoint-between(md,xl) {
        right: 0;
        width: 370px;
        transform: translateX(100%);
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
    }

    @include media-breakpoint-down(md) {
        left: 0;
        width: 100%;
        transform: translateX(-100%);
    }

    input[name="toggleMobileNav"]:checked ~ .page-wrap & {
        @include media-breakpoint-between(md,xl) {
            transform: translateX(0);
        }

        @include media-breakpoint-down(md) {
            transform: translateX(0);
        }
    }

    html:not(.header-top--fixed) .frontpage & {
        @include media-breakpoint-up(xl) {
            width: 100%;
            padding-right: 40px;
        }
    }

    a {
        display: block;
        width: 100%;
        font-size: 16px;
        line-height: 1.125;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    label {
        display: none;
    }

    // 1. Ebene
    > ul {
        @include media-breakpoint-up(xl) {
            display: flex;
            flex-wrap: nowrap;
        }

        @include media-breakpoint-down(xl) {
            margin: 75px 0 90px 0;
        }

        html:not(.header-top--fixed) .frontpage & {
            @include media-breakpoint-up(xl) {
                display: block;
            }
        }

        > li {
            position: relative;

            @include media-breakpoint-down(xl) {
                margin-bottom: 20px;
            }

            &:last-child {
                @include media-breakpoint-down(xl) {
                    margin-bottom: 0;
                }

                > ul {
                    @include media-breakpoint-up(xl) {
                        left: auto;
                        right: 0;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 0;
                    }
                }
            }

            html:not(.header-top--fixed) .frontpage & {
                @include media-breakpoint-up(xl) {
                    position: static;
                }
            }

            &.is-active > a {
                @include media-breakpoint-up(xl) {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            &.is-active > a:hover,
            &.is-active:hover > a {
                &:not(:only-child) {
                    @include media-breakpoint-up(xl) {
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }
                }
            }

            &.is-active > a,
            &:hover > a,
            > a:hover {
                @include media-breakpoint-up(xl) {
                    color: $white;
                    background-color: $blue;
                }
            }

            > a {
                padding: 11px 55px 11px 15px;
                color: $blue;
                font-weight: $font-weight-bolder;
                text-transform: uppercase;
                white-space: nowrap;
                transition: background-color .3s, color .3s;

                @include media-breakpoint-up(xl) {
                    padding: 20px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    overflow: hidden;
                }

                &:only-child {
                    @include media-breakpoint-up(xl) {
                        border-radius: 5px;
                    }
                }

                html:not(.header-top--fixed) .frontpage & {
                    @include media-breakpoint-up(xl) {
                        padding: 20px 0 20px 105px;
                        border-radius: 0;
                    }
                }
            }

            > label {
                @include media-breakpoint-down(xl) {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    border: 1px solid rgba($blue, .2);
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color .3s;
                }

                &:before {
                    @include media-breakpoint-down(xl) {
                        display: block;
                        position: absolute;
                        content: '';
                        left: 50%;
                        top: 50%;
                        width: 8px;
                        height: 15px;
                        transform: translate(-50%, -50%);
                        background-image: svg-load('angle-right.svg', fill="");
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-size: contain;
                        transition: transform .3s, filter .3s;
                    }
                }
            }

            > input[name=toggleSubNav]:checked ~ {
                label {
                    @include media-breakpoint-down(xl) {
                        background: $blue;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &:before {
                        @include media-breakpoint-down(xl) {
                            filter: $filter-white;
                            transform: translate(-50%, -50%) rotateZ(90deg);
                        }
                    }
                }

                ul {
                    @include media-breakpoint-down(xl) {
                        padding: 15px 0;
                        max-height: 1500px;
                    }
                }
            }

            &:hover {
                > ul {
                    @include media-breakpoint-up(xl) {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }

            // 2. Ebene
            > ul {
                overflow: hidden;

                @include media-breakpoint-up(xl) {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    padding: 25px 0;
                    min-width: 300px;
                    max-height: calc(100vh - 100px);
                    background: $blue;
                    opacity: 0;
                    overflow-y: auto;
                    visibility: hidden;
                    transition: opacity .3s, visibility .3s;
                    border-radius: 5px;
                    border-top-left-radius: 0;
                }

                @include media-breakpoint-down(xl) {
                    padding: 0;
                    max-height: 0;
                    background: $blue;
                    border-radius: 5px;
                    border-top-right-radius: 0;
                    transition: padding .3s, max-height .5s;
                }

                html:not(.header-top--fixed) .frontpage & {
                    @include media-breakpoint-up(xl) {
                        display: flex;
                        align-items: center;
                        align-content: center;
                        flex-wrap: wrap;
                        left: auto;
                        top: 0;
                        right: 100%;
                        padding: 0;
                        width: 100%;
                        min-width: 0;
                        height: 100%;
                        max-height: none;
                        border-radius: 0;
                    }
                }

                > li {

                    html:not(.header-top--fixed) .frontpage & {
                        @include media-breakpoint-up(xl) {
                            flex: 0 0 100%;
                            margin-bottom: 0;
                        }
                    }


                    &.is-active > a,
                    &:hover > a,
                    > a:hover {
                        @include media-breakpoint-up(xl) {
                            background: $white;
                            color: $blue;
                        }
                    }

                    &.is-active > a {
                        @include media-breakpoint-down(xl) {
                            background: $blue-lighter;
                        }
                    }

                    > a {
                        position: relative;
                        color: $white;
                        transition: color .3s, background-color .3s;
                        font-weight: $font-weight-bold;

                        @include media-breakpoint-up(xl) {
                            padding: 15px 0 15px 56px;
                        }

                        @include media-breakpoint-down(xl) {
                            padding: 15px 30px 15px 46px;
                        }

                        html:not(.header-top--fixed) .frontpage & {
                            @include media-breakpoint-up(xl) {
                                padding-left: 76px;
                            }
                        }

                        &:before {
                            display: block;
                            content: '';
                            position: absolute;
                            left: 30px;
                            top: 50%;
                            transform: translateY(-50%);
                            border-left: 6px solid $orange;
                            border-top: 4px solid transparent;
                            border-bottom: 4px solid transparent;

                            @include media-breakpoint-up(xl) {
                                left: 40px;
                            }

                            html:not(.header-top--fixed) .frontpage & {
                                @include media-breakpoint-up(xl) {
                                    left: 60px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--toggle {
        display: none;

        @include media-breakpoint-down(xl) {
            display: block;
            position: relative;
            padding: 7px 0;
            cursor: pointer;
        }

        @include media-breakpoint-between(md, xl) {
            width: 35px;
        }

        @include media-breakpoint-down(md) {
            width: 25px;
        }

        span {
            @include media-breakpoint-down(xl) {
                display: block;
                width: 100%;
                background: $blue;
                transition: background-color .3s;
            }

            &, &:before, &:after {
                @include media-breakpoint-down(xl) {
                    height: 2px;
                }
            }

            &:before, &:after {
                @include media-breakpoint-down(xl) {
                    display: block;
                    position: absolute;
                    content: '';
                    left: 0;
                    width: 100%;
                    background: $blue;
                    transition: top .3s, bottom .3s, transform .3s;
                }
            }

            &:before {
                @include media-breakpoint-down(xl) {
                    top: 0;
                }
            }

            &:after {
                @include media-breakpoint-down(xl) {
                    bottom: 0;
                }
            }
        }

        &.main-nav--toggle--close {
            @include media-breakpoint-down(xl) {
                padding: 11px 0;
            }

            span {
                @include media-breakpoint-down(xl) {
                    background-color: transparent;
                }

                &:before {
                    @include media-breakpoint-down(xl) {
                        top: auto;
                        transform: rotateZ(-45deg);
                    }
                }

                &:after {
                    @include media-breakpoint-down(xl) {
                        bottom: auto;
                        transform: rotateZ(45deg);
                    }
                }
            }
        }
    }

    > .main-nav--toggle {
        display: none;

        .page-header--top & {
            @include media-breakpoint-down(xl) {
                display: block;
                position: absolute;
                top: 30px;
                right: 30px;
            }
        }
    }

    .logo {
        display: none;

        @include media-breakpoint-down(md) {
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 128px;
        }
    }

    .lang-nav {
        display: none;

        @include media-breakpoint-down(xl) {
            display: block;
            position: relative;
            left: auto;
            top: auto;
            z-index: 1;
        }

        > ul {
            @include media-breakpoint-down(xl) {
                justify-content: center;
            }

            > li {
                &.is-active {
                    a {
                        &, &:hover {
                            @include media-breakpoint-down(xl) {
                                background: $blue-superlight;
                                border-color: transparent;
                                color: $blue;
                            }

                            .flag {
                                @include media-breakpoint-down(md) {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }

                a {
                    @include media-breakpoint-down(xl) {
                        border-color: rgba($blue, .2);
                        color: $blue;
                        background: transparent;
                    }

                    &:hover {
                        @include media-breakpoint-down(xl) {
                            color: $white;
                            background: $blue;
                        }

                        .flag {
                            @include media-breakpoint-down(md) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}