.duration {
    display: block;
    text-align: center;

    .icon {
        margin-bottom: 5px;
        font-size: 20px;
    }

    span {
        display: block;
        font-size: 12px;
        line-height: 1.16667;
        white-space: nowrap;
    }
}