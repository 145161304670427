.header-media {
    position: relative;
    height: 60vh;
    min-height: 250px;
    overflow: hidden;

    @include media-breakpoint-down(md) {
        height: 52.5vh;
    }

    .frontpage & {
        height: 100vh;
        min-height: 700px;

        @include media-breakpoint-down(xl) {
            min-height: 500px;
        }

        @include media-breakpoint-down(md) {
            height: calc(100vh - 70px);
        }
    }

    .landingpage & {
        height: calc(100vh - 174px);
        min-height: 500px;

        @include media-breakpoint-down(md) {
            height: calc(100vh - 140px);
            min-height: 350px;
        }
    }

    &:before {
        display: block;
        content: '';
        position: absolute;
        left: 60px;
        right: 60px;
        bottom: 0;
        height: calc(50% - 60px);
        background: linear-gradient(to bottom, rgba($blue, 0), rgba($blue, 100%));
        opacity: .8;
        pointer-events: none;
        z-index: 1;

        @include media-breakpoint-down(md) {
            left: 0;
            right: 0;
            height: 50%;
        }

        .frontpage &,
        .landingpage & {
            left: 0;
            right: 0;
            bottom: 0;
            height: 50%;
        }
    }

    > img,
    > picture > img,
    > video,
    > iframe {
        position: absolute;
        top: 60px;
        left: 60px;
        width: calc(100% - 120px);
        height: calc(100% - 60px);

        @include media-breakpoint-down(md) {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .frontpage &,
        .landingpage & {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    > img,
    > picture > img,
    > video {
        object-fit: cover;
    }

    &--content {
        position: absolute;
        left: 120px;
        right: 120px;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        color: $white;
        z-index: 2;

        @include media-breakpoint-down(md) {
            left: 15px;
            right: 15px;
        }

        .frontpage & {
            @include fluid-property('left', map-get($grid-breakpoints, xl), map-get($grid-breakpoints, xxxl), 200px, 300px);
            right: 530px;
            top: auto;
            bottom: 160px;
            transform: none;
            text-align: left;

            @include media-breakpoint-between(md, xl) {
                left: 60px;
                right: 60px;
                bottom: 210px;
            }

            @include media-breakpoint-down(md) {
                left: 15px;
                right: 15px;
                bottom: 110px;
            }
        }

        .landingpage & {
            left: 60px;
            right: 60px;

            @include media-breakpoint-down(md) {
                left: 15px;
                right: 15px;
            }
        }

        > p {
            font-weight: $font-weight-bold;

            .landingpage & {
                @include fluid-type($viewport-width-min, $viewport-width-max, 35px, 80px);
                line-height: 1.1875;
            }
        }

        > span {
            display: block;
            text-transform: uppercase;
            font-weight: $font-weight-bold;

            .landingpage & {
                @include fluid-type($viewport-width-min, $viewport-width-max, 20px, 26px);
                line-height: 1.15;
            }
        }

        > .btn {
            margin-top: 30px;
            white-space: nowrap;

            @include media-breakpoint-down(md) {
                font-size: 18px;
            }

            .landingpage & {
                font-size: 25px;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                }
            }

            &:after {
                opacity: .2;
            }
        }

        &--item {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            @include fluid-type($viewport-width-min, $viewport-width-max, $h3-min-font-size, $h3-font-size);
            line-height: 1.16666;
            font-weight: $font-weight-bold;
            color: $white;
            overflow: hidden;
            z-index: 1;

            @include media-breakpoint-down(md) {
                font-size: 18px;
                line-height: 1.27778;
                font-weight: $font-weight-bolder;
            }

            &.is-active {
                z-index: 5;
            }

            a {
                &, &:hover, &:focus {
                    text-decoration: none;
                    color: $white;
                }
            }

            &--inner {
                position: relative;
                padding-left: to-em(50px, 36px);
                opacity: 0;
                transform: translateY(100%);
                transition: transform .5s, opacity .5s;
                transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);

                @include media-breakpoint-down(md) {
                    padding-left: 32px;
                }

                .is-active & {
                    opacity: 1;
                    transform: translateY(0);
                }

                .is-active.transition-out & {
                    opacity: 0;
                    transform: translateY(-100%);
                }

                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: to-em(8px, 36px);
                    border-left: to-em(20px, 36px) solid $orange;
                    border-top: to-em(13px, 36px) solid transparent;
                    border-bottom: to-em(13px, 36px) solid transparent;

                    @include media-breakpoint-down(md) {
                        top: 1px;
                        border-left-width: 17px;
                        border-top-width: 11px;
                        border-bottom-width: 11px;
                    }
                }

                > * {
                    &:last-child {
                        margin-bottom: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }

        &.bottom-left {
            text-align: left;
            @include fluid-property('left', map-get($grid-breakpoints, md), map-get($grid-breakpoints, xl), 100px, 140px);
            top: auto;
            @include fluid-property('bottom', map-get($grid-breakpoints, md), map-get($grid-breakpoints, xl), 40px, 80px);
            transform: translate(0, 0);

            @include media-breakpoint-down(md) {
                left: 15px;
                bottom: 30px;
            }

            h1, .h1,
            h2, .h2,
            h3, .h3 {
                position: relative;

                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                }
            }

            h1, .h1 {
                padding-left: to-em(50px, $h1-font-size);

                &:before {
                    top: to-em(20px, $h1-font-size);
                    border-left: to-em(20px, $h1-font-size) solid $orange;
                    border-top: to-em(13px, $h1-font-size) solid transparent;
                    border-bottom: to-em(13px, $h1-font-size) solid transparent;
                }
            }

            h2, .h2 {
                padding-left: to-em(50px, $h2-font-size);

                &:before {
                    top: to-em(16px, $h2-font-size);
                    border-left: to-em(20px, $h2-font-size) solid $orange;
                    border-top: to-em(13px, $h2-font-size) solid transparent;
                    border-bottom: to-em(13px, $h2-font-size) solid transparent;
                }
            }

            h3, .h3 {
                padding-left: to-em(50px, $h3-font-size);

                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }

                &:before {
                    top: to-em(8px, $h3-font-size);
                    border-left: to-em(20px, $h3-font-size) solid $orange;
                    border-top: to-em(13px, $h3-font-size) solid transparent;
                    border-bottom: to-em(13px, $h3-font-size) solid transparent;
                }
            }
        }
    }
}