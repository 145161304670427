@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	font-stretch: normal;
	src: url(../fonts/googlefonts/Work_Sans-normal-300.woff2) format('woff2'), url(../fonts/googlefonts/Work_Sans-normal-300.woff) format('woff'), url(../fonts/googlefonts/Work_Sans-normal-300.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	font-stretch: normal;
	src: url(../fonts/googlefonts/Work_Sans-normal-400.woff2) format('woff2'), url(../fonts/googlefonts/Work_Sans-normal-400.woff) format('woff'), url(../fonts/googlefonts/Work_Sans-normal-400.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	font-stretch: normal;
	src: url(../fonts/googlefonts/Work_Sans-normal-500.woff2) format('woff2'), url(../fonts/googlefonts/Work_Sans-normal-500.woff) format('woff'), url(../fonts/googlefonts/Work_Sans-normal-500.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	font-stretch: normal;
	src: url(../fonts/googlefonts/Work_Sans-normal-600.woff2) format('woff2'), url(../fonts/googlefonts/Work_Sans-normal-600.woff) format('woff'), url(../fonts/googlefonts/Work_Sans-normal-600.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Work Sans';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	font-stretch: normal;
	src: url(../fonts/googlefonts/Work_Sans-normal-700.woff2) format('woff2'), url(../fonts/googlefonts/Work_Sans-normal-700.woff) format('woff'), url(../fonts/googlefonts/Work_Sans-normal-700.ttf) format('truetype');
	unicode-range: U+0-10FFFF;
}

