.author {
    &--content {
        grid-template-columns: minmax(0, .4fr) minmax(0, .6fr);
        margin-left: auto;
        margin-right: auto;
        max-width: to-em(800px);

        @include media-breakpoint-down(md) {
            grid-template-columns: 100%;
        }
    }

        &--image {
            img {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: auto;
                height: auto;
                max-width: 100%;

                @include media-breakpoint-up(md) {
                    width: 100%;
                    height: auto;
                }
            }
        }
}