/*** IMPORT CONFIG ***/
@import "config/variables";

/*** IMPORT BASIC STUFF ***/
@import "basic/functions";
@import "basic/mixins";
@import "basic/helpers";

$layer-banner--bg-color: $blue-superlight;
$layer-banner--txt-color: $text-color;
$layer-banner--txt-color--light: #ffffff;
$layer-banner--txt-color--dark: $layer-banner--txt-color;
$layer-banner--closeButton-bg: $white;
$layer-banner--border-radius: 5px;

.layer-banner {
    display: none;
    max-width: calc(100vw - 60px);
    max-height: calc(100vh - 60px);
    width: 750px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    opacity: 0;
    transform: translate(-50%, -35%);
    transition: opacity .3s, transform .3s;
    pointer-events: none;

    &.is-transitioning {
        display: block;
    }

    &.is-visible {
        opacity: 1;
        transform: translate(-50%, -50%);
        display: block;
        pointer-events: auto;
    }

    &--inner {
        max-height: calc(100vh - 60px);
        border-radius: $layer-banner--border-radius;
        background-color: $layer-banner--bg-color;
        box-shadow: 0 0 50px rgba(0, 0, 0, .3);
        overflow: hidden;
        overflow-y: auto;

        @include media-breakpoint-up(md) {
            display: grid;
            grid-template-columns: 50% 50%;
        }
    }

    &--media {
        position: relative;

        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            @include media-breakpoint-up(md) {
                padding-bottom: 75%;
            }
        }

        img,
        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--badge {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-items: center;
        justify-content: center;
        left: 50%;
        top: 25px;
        padding: 15px;
        width: 140px;
        height: 140px;
        transform: translateX(-50%) rotate(10deg);
        font-size: 13px;
        line-height: 1.25;
        font-weight: $font-weight-bold;
        background: $orange;
        color: $white;
        border-radius: 100%;
        text-align: center;
        overflow: hidden;
        z-index: 2;

        @include media-breakpoint-down(md) {
            top: 10px;
            left: 10px;
            padding: 5px;
            width: 100px;
            height: 100px;
            transform: translateX(0) rotate(-10deg);
            font-size: 12px;
        }

        strong {
            margin-bottom: 5px;
            font-weight: $font-weight-bolder;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    &--content {
        padding: 60px;
        position: relative;
        color: $layer-banner--txt-color;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-items: flex-start;
            justify-content: flex-start;
        }

        @include media-breakpoint-down(sm) {
            padding: 30px;
        }

        > * {
            margin-bottom: 1em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.has-badge {
        .layer-banner--content {
            @include media-breakpoint-up(md) {
                padding-left: 100px;
            }
        }
    }

    &--close {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        padding: 0;
        width: 47px;
        height: 47px;
        transform: translate(50%, -50%);
        background: rgba(0, 0, 0, .8);
        border: none;
        border-radius: 100%;
        cursor: pointer;
        transition: transform .3s, background-color .3s;
        z-index: 1;

        &:hover {
            background-color: $black;
            transform: translate(50%, -50%) scale(1.1);
        }

        i {
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            height: 25px;
            width: 25px;
            transform: translate(-50%, -50%);

            &:before, &:after {
                display: block;
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                height: 2px;
                width: 100%;
                background-color: $layer-banner--closeButton-bg;
            }

            &:before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &:after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &.media-only,
    &.text-only {
        .layer-banner {
            &--inner {
                display: block;
            }

            &--badge {
                left: 20px;
                top: 20px;
                transform: translateX(0) rotate(-10deg);

                @include media-breakpoint-down(md) {
                    top: 10px;
                    left: 10px;
                }
            }
        }
    }

    &.text-only {
        .layer-banner {
            &--content {
                display: block;
            }
        }

        &.has-badge {
            .layer-banner {
                &--content {
                    padding-left: 200px;

                    @include media-breakpoint-down(md) {
                        padding-left: 130px;
                    }
                }
            }
        }
    }

    &.media-only {
        .layer-banner {
            &--inner {
                background-color: transparent;
            }

            &--media {
                &:before {
                    padding-bottom: 56.25%;
                }
            }

            &--content {
                position: absolute;
                justify-content: center;
                justify-items: center;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }
    }
}
