.area--magazine {
    &, &.is-narrow {
        position: relative;
        @include fullWidthViewport;
        left: 50%;
        transform: translateX(-50%);
        max-width: none;
    }

    padding-left: $container-padding;
    padding-right: $container-padding;

    @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
    }

    .teaser {
        .btn {
            flex-grow: 0;
            padding: $btn-padding;
        }
    }

    .area {
        &--textmedia {
            @include media-breakpoint-down(md) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &--teaser {
            @include media-breakpoint-down(md) {
                margin-top: 0;
            }

            .cols {
                @include fluid-property('grid-column-gap', $viewport-width-min, $viewport-width-max, 30px, 60px);
                @include fluid-property('grid-row-gap', $viewport-width-min, $viewport-width-max, 30px, 60px);

                @include media-breakpoint-down(md) {
                    grid-column-gap: 0;
                    grid-row-gap: 0;
                }
            }

            .area--textmedia {
                .cols {
                    grid-column-gap: 0;
                    grid-row-gap: 0;
                }
            }
        }
    }
}