.tab-nav {
    @include reset-list;
    border-bottom: 1px solid $blue;

    .tab-item {
        display: inline-block;
        margin: 0;
        padding: 0;

        &:before {
            display: none;
        }
    }

    a.tab-link:not(.btn) {
        padding: 15px 25px;
        font-size: 20px;
        line-height: 1.2;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: background-color .3s, color .3s, box-shadow .3s;
        border: 1px solid $blue;
        border-bottom: none;
        box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, .2);

        &:after {
            display: none;
        }

        &:hover {
            background: $blue-superlight;
            color: $blue;
        }

        &.active {
            color: $white;
            background-color: $blue;
            box-shadow: none;
        }
    }
}

.tab-content {
    > .tab-pane {
        display: none;

        &.active {
            display: block;
        }

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}