.embed-responsive {
    position: relative;
    width: 100%;
    height: 0;

    &-1by1 {
        padding-bottom: 100%;
    }

    &-3by2 {
        padding-bottom: 66.66666%;
    }

    &-16by9 {
        padding-bottom: 56.25%;
    }

    > * {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    > iframe {
        border: none;
    }
}

.is-hidden {
    display: none !important;
}