.lang-nav {
    .frontpage & {
        position: absolute;
        left: 60px;
        top: 60px;
        z-index: 998;
    }

    > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: nowrap;

        > li {
            margin-right: 10px;

            &:last-child {
                margin: 0;
            }

            a {
                display: block;
                padding: 0 10px;
                min-width: 40px;
                font-size: 12px;
                line-height: 38px;
                font-weight: $font-weight-boldest;
                text-align: center;
                color: rgba($blue, .5);
                border: 1px solid rgba($blue, .15);
                border-radius: 5px;
                transition: color .3s, background-color .3s, border .3s;

                .frontpage & {
                    border-color: transparent;
                    background: rgba($blue, .15);
                    color: $white;
                }

                &:hover {
                    background: $white;
                    border-color: transparent;
                    color: $blue;

                    .frontpage & {
                        background: $blue;
                        color: $white;
                    }
                }
            }

            &.is-active {
                a {
                    background-color: $white;
                    color: $blue;
                    border-color: transparent;

                    .frontpage & {
                        background-color: rgba($white, .6);
                        color: $blue;
                    }
                }
            }

            &.is-active,
            a:hover {
                .flag {
                    margin-right: 10px;
                    width: 30px;
                    opacity: 1;
                }
            }
        }
    }

    .flag {
        display: none;
        margin-right: 0;
        width: 0;
        height: 20px;
        vertical-align: middle;
        border-radius: 3px;
        overflow: hidden;
        opacity: 0;
        background-size: auto 100%;
        background-position: center center;
        background-repeat: no-repeat;
        transition: width .3s, margin .3s, opacity .3s;

        .frontpage & {
            display: inline-block;
        }

        &--at {
            background-image: svg-load('../layout/svg/flag_at.svg', fill='');
        }

        &--gb {
            background-image: svg-load('../layout/svg/flag_uk.svg', fill='');
        }

        &--it {
            background-image: svg-load('../layout/svg/flag_it.svg', fill='');
        }
    }
}