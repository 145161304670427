.button-nav {
    margin-bottom: 60px;

    @include media-breakpoint-down(md) {
        margin-bottom: 40px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        text-align: center;

        li {
            display: inline-block;

            &, .area & {
                padding: 0;
                margin: 0 2px 10px 2px;
            }

            &:before {
                .area & {
                    display: none;
                }
            }

            a:not(.btn) {
                display: block;
                position: static;
                padding: 8px 10px;
                font-weight: $font-weight-light;
                line-height: 1.2;
                border: 1px solid $blue-light;
                border-radius: 5px;
                transition: color .3s, background-color .3s, border-color .3s;

                &:hover {
                    background: $blue-light;
                    color: $blue;
                }

                &.is-active {
                    background: $blue;
                    color: $white;
                    border-color: $blue;
                }

                &:after {
                    .area & {
                        display: none;
                    }
                }
            }
        }
    }

    &.button-nav--secondary {
        ul {
            li {
                a:not(.btn) {
                    border-color: transparent;
                    background-color: $blue-superlight;

                    &:hover {
                        background: $blue-light;
                        color: $blue;
                    }

                    &.is-active {
                        background: $blue;
                        color: $white;
                        border-color: $blue;
                    }
                }
            }
        }
    }
}