.teaser {

    a {
        text-decoration: none;
    }

    .area & > a:not(.btn),
    .area & > a:not(.btn)[target="_blank"],
    & > a:not(.btn),
    & > a:not(.btn)[target="_blank"] {
        display: inline;
        height: 100%;
        font-weight: inherit;
        color: inherit;
        text-decoration: none;

        &:hover {
            opacity: 1;

            button.btn {
                left: 2px;
                top: 2px;

                &:after {
                    top: 0;
                    left: 0;
                }

                &-primary {
                    background-color: $white;
                    border-color: $white;
                    color: $orange;

                    .icon {
                        filter: $filter-orange;
                    }
                }

                &-secondary {
                    background-color: $blue;
                    color: $white;

                    .icon {
                        filter: $filter-white;
                    }
                }

                &-outline {
                    background-color: $blue-lighter;
                }
            }
        }

        &:after {
            display: none;
        }
    }

    &:hover {
        .teaser--media {
            > img,
            > picture > img,
            > video {
                transform: scale(1.1);
            }
        }
    }

    &--inner {
        position: relative;
        width: 100%;
        height: 100%;
        background: $blue;
        color: $white;
    }

        &--offer {
            display: flex;
            position: absolute;
            top: 0;
            right: 30px;
            transform: translateY(-50%);
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
            z-index: 2;

            label {
                display: flex;
                align-items: center;
                padding: 0 15px;
                background: $white;
                color: $blue;
                font-size: 12px;
                line-height: 14px;
                font-weight: $font-weight-boldest;
                text-align: center;
                text-transform: uppercase;
            }

            span {
                display: block;
                padding: 0 15px;
                height: 56px;
                line-height: 56px;
                white-space: nowrap;
                background: $orange;
                color: $white;
                font-weight: $font-weight-bolder;
                font-size: 26px;

                @include media-breakpoint-down(md) {
                    height: 45px;
                    line-height: 45px;
                    font-size: 20px;
                }

                small {
                    font-size: 15px;
                    font-weight: $font-weight-normal;
                }
            }
        }

        &--partner {
            position: absolute;
            top: -20px;
            right: 30px;
            width: 100px;
            height: 100px;
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: contain;
            overflow: hidden;
            white-space: nowrap;
            text-indent: -999em;
            border-radius: 5px;
            z-index: 2;

            @include media-breakpoint-down(md) {
                top: -10px;
                width: 60px;
                height: 60px;
            }

            &.service {
                background-image: svg-load('../layout/svg/logo-service-partner.svg', fill='');
            }

            &.premium {
                background-image: svg-load('../layout/svg/logo-premium-partner.svg', fill='');
            }
        }

        &--media {
            overflow: hidden;

            > img,
            > picture > img,
            > video,
            > .pimcore_editable_video > video {
                display: block;
                width: 100%;
                height: auto;
                transition: transform .3s;
            }
        }

        &--content {
            position: relative;
            padding: 60px 60px 40px;
            font-weight: 300;
            z-index: 1;

            @include media-breakpoint-down(md) {
                padding: 30px 30px 25px;
            }

            .cols--3 & {
                @include media-breakpoint-between(lg, xl) {
                    padding: 30px 30px 25px;
                }
            }

            h4, .h4 {
                margin-bottom: 15px;
                /*hyphens: none;
                word-break: normal;*/
            }

            p, ul {
                @include media-breakpoint-down(md) {
                    font-size: 15px;
                }
            }

            ul {
                li {
                    &:before {
                        &, .area & {
                            border-left-color: $white;
                        }
                    }
                }
            }
        }

    &:not(.teaser--light) {
        .teaser--content {
            a:not(.btn) {
                .area & {
                    color: $white;

                    &:hover {
                        color: $blue;
                    }

                    &:after {
                        background: $white;
                    }

                    &[target="_blank"] {
                        &:hover {
                            color: rgba($white, .8);
                        }
                    }
                }
            }
        }
    }

            &--date {
                position: relative;
                display: inline-block;
                margin-bottom: 15px;
                padding-left: 35px;
                font-weight: $font-weight-bold;
                white-space: nowrap;
                line-height: 1.64706;

                &:before {
                    position: absolute;
                    display: block;
                    content: '';
                    left: 0;
                    top: 0;
                    width: 25px;
                    height: 25px;
                    @include updateIcon('calendar', '');
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                    filter: $filter-white;

                    @include media-breakpoint-down(lg) {
                        top: -1px;
                    }

                    .teaser--light & {
                        filter: $filter-blue;
                    }

                    .area--textmedia & {
                        filter: $filter-blue;

                    }

                    .area--textmedia.highlight & {
                        filter: $filter-white;
                    }
                }

                .icon {
                    margin-right: 5px;
                    font-size: 25px;
                    filter: $filter-blue;
                }

                > * {
                    vertical-align: middle;
                }
            }

            &--icon {
                left: 60px;
                top: 0;
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                transform: translateY(-50%);
                background: $white;
                border-radius: 100%;

                &, &[data-tooltip] {
                    position: absolute;
                }

                @include media-breakpoint-down(md) {
                    left: 30px;
                }

                .icon {
                    filter: $filter-blue;
                    font-size: 30px;
                }
            }

            &--price {
                margin-top: 22px;
                font-size: 26px;
                line-height: 1;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    line-height: 25px;
                }

                b {
                    font-weight: $font-weight-bolder;
                }

                small {
                    font-size: 15px;
                    line-height: 18px;
                    font-weight: $font-weight-normal;

                    @include media-breakpoint-down(md) {
                        font-size: 13px;
                        line-height: 19px;
                    }
                }

                span {
                    display: block;
                    font-weight: $font-weight-normal;
                    font-style: italic;
                    font-size: 12px;
                    line-height: 1.33333;
                }
            }

            &--buttons {
                display: flex;
                flex-wrap: wrap;
                margin: 30px -10px 0 -10px;
            }

                .btn {
                    flex: 1 0 auto;
                    margin: 0 10px 20px;
                    padding-left: 10px;
                    padding-right: 10px;
                    white-space: nowrap;
                    text-align: center;

                    &:after {
                        opacity: .2;
                    }

                    &-secondary {
                        background-color: $white;
                        color: $blue;

                        &:hover {
                            background-color: $blue;
                            color: $white;

                            .icon {
                                filter: $filter-white;
                            }
                        }

                        .icon {
                            filter: $filter-blue;
                        }
                    }

                    &-outline {
                        background-color: $blue;
                        border-color: $white;
                        color: $white;

                        &:hover {
                            background-color: $blue-lighter;
                        }

                        .icon {
                            filter: $filter-white;
                        }
                    }
                }

    &--horizontal {
        .teaser {
            &--inner {
                @include media-breakpoint-up(md) {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: stretch;
                }
            }

                &--media {
                    position: relative;

                    @include media-breakpoint-up(xl) {
                        min-width: 360px;
                    }

                    @include media-breakpoint-up(md) {
                        flex: 0 0 31.66667%;
                        max-width: 360px;
                        min-height: 160px;
                        min-width: 160px;
                    }

                    &:after {
                        @include media-breakpoint-up(md) {
                            display: block;
                            content: '';
                            width: 100%;
                            height: 0;
                            padding-bottom: 44.44444%;
                        }
                    }

                    > img,
                    > picture > img,
                    > video,
                    > .pimcore_editable_video > video {
                        @include media-breakpoint-up(md) {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }

                &--content {
                    @include media-breakpoint-up(md) {
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: space-between;
                        flex: 1 1 auto;
                        padding: 50px 60px;
                    }
                }

                &--buttons {
                    display: block;

                    @include media-breakpoint-up(md) {
                        margin: 0 0 0 30px;
                    }
                }
        }

            .btn {
                display: block;
                padding: $btn-padding;
                margin: 0 auto 20px;

                @include media-breakpoint-up(md) {
                    margin: 0 auto;
                }
            }
    }

    &.teaser--light {

        > a {
            &:hover {
                button.btn {
                    &-outline {
                        border-color: $white;
                        color: $white;

                        .icon {
                            filter: $filter-white;
                        }
                    }
                }
            }
        }

        .teaser {
            &--inner {
                background: $blue-superlight;
                color: $blue;
            }

                &--date {
                    &:before {
                        filter: $filter-blue;
                    }
                }

                &--content {
                    ul {
                        li {
                            &:before {
                                border-left-color: $blue;
                            }
                        }
                    }
                }
        }

            .btn {
                &:after {
                    opacity: 1;
                    background: $blue-light;
                }

                &-outline {
                    background-color: $blue-superlight;
                    border-color: $blue;
                    color: $blue;

                    &:hover {
                        background-color: $blue-lighter;
                        border-color: $white;
                        color: $white;

                        .icon {
                            filter: $filter-white;
                        }
                    }

                    .icon {
                        filter: $filter-blue;
                    }
                }
            }
    }

    &.media-only,
    &.headline-arrow {
        .teaser {
            &--content {
                h4, .h4 {
                    position: relative;
                    padding-left: to-em(35px, $h4-font-size);

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        left: 0;
                        top: to-em(3px, $h4-font-size);
                        border-left: to-em(20px, $h4-font-size) solid $orange;
                        border-top: to-em(13px, $h4-font-size) solid transparent;
                        border-bottom: to-em(13px, $h4-font-size) solid transparent;
                    }
                }
            }
        }
    }

    &.media-only {
        .teaser {
            overflow: hidden;

            &--media {
                position: relative;

                @include media-breakpoint-down(md) {
                    &:after {
                        display: block;
                        content: '';
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 50%;
                        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                        opacity: .8;
                        pointer-events: none;
                    }
                }
            }

            &--content {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;

                > * {
                    position: relative;
                    z-index: 1;
                }

                &:after {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    opacity: .7;
                    pointer-events: none;
                    z-index: 0;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }

                &--date {
                    position: relative;
                    display: block;
                    margin-bottom: 0;
                    font-weight: $font-weight-bold;
                    line-height: 1.64706;
                    @include fluid-property('padding-left', $viewport-width-min, $viewport-width-max, 60px, 70px);

                    @include media-breakpoint-down(lg) {
                        font-size: 15px;
                        line-height: 1.66667;
                    }

                    &:before {
                        left: 35px;
                        @include fluid-property('left', $viewport-width-min, $viewport-width-max, 24px, 35px);
                    }

                    + .teaser--text {
                        margin-top: 15px;
                    }
                }

                &--text {
                    @include fluid-property('padding-left', $viewport-width-min, $viewport-width-max, 24px, 35px);

                    > *:last-child {
                        margin-bottom: 0;
                    }

                    @include media-breakpoint-down(md) {
                        display: none;
                    }

                    ul, ol {
                        padding: 0;

                        li {
                            margin: 0;
                            padding: 0;
                            display: inline;

                            &:before {
                                display: none;
                            }

                            &:after {
                                display: inline;
                                content: ',';
                            }

                            &:last-child {
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
        }
    }

    &--accommodation {
        .teaser--content {
            &, .cols--3 & {
                padding: 50px 30px 35px;
            }

            h4, .h4 {

            }
        }

        .teaser--location {
            position: absolute;
            top: 20px;
            right: 30px;
            display: flex;
            column-gap: 3px;
            align-items: center;
            font-size: 14px;
            font-weight: $font-weight-bold;

            @include media-breakpoint-down(md) {
                top: 15px;
            }

            .icon {
                filter: $filter-blue;
            }
        }

        .teaser--highlights {
            font-weight: $font-weight-normal;

            li {
                padding-left: 25px;

                &:before {
                    top: 6px;
                    border: none;
                    @include setIcon('check', '');
                    filter: $filter-blue;
                }
            }
        }

        .teaser--buttons {
            row-gap: 15px;
            column-gap: 15px;
            margin-left: 0;
            margin-right: 0;

            .btn {
                margin: 0;
            }
        }
    }

    &.test-teaser {
        display: none;
    }
}