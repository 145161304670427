table.contenttable,
.area--text table {
    width: 100%;
    border: none;

    th,
    td {
        padding: $table-padding-y $table-padding-x;
        font-weight: $font-weight-light;

        &:first-child {
            padding-left: $table-padding-x * 2;
        }
        &:last-child {
            padding-right: $table-padding-x * 2;
        }

        min-width: 25vw;

        @include media-breakpoint-up(md) {
            min-width: 0;
        }
    }

    thead {
        tr:first-child th {
            padding: 15px 20px;
            background-color: $table-head-bg;
            color: $table-head-color;
            text-transform: uppercase;
            font-size: 20px;
            line-height: 1.15;
            font-weight: $font-weight-bold;
        }
    }

    thead tr:not(:first-child) th,
    tbody tr th {
        background-color: $table-head-secondary-bg;
        color: $table-head-secondary-color;
        font-weight: $font-weight-bold;
    }


    td {
        border-bottom: 1px solid $table-border-color;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// normale responsive table (horizontal scroll)
.table-responsive {
    @include media-breakpoint-down(sm) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}